import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { ProjectService } from '../project.service';
import { PagerService } from '../pager.service';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Album } from '../album';
import { AlbumService } from '../album.service';
import { I18nGlvService } from '../i18n-glv.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { SearchService } from '../search.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { NewprojectmodalComponent } from '../newprojectmodal/newprojectmodal.component'
import { DeleteProjectComponent } from '../delete-project/delete-project.component';
import { DeleteTrackFromProjectComponent } from '../delete-track-from-project/delete-track-from-project.component';
import { TrackOperationsModalComponent } from '../track-operations-modal/track-operations-modal.component';
import { SendProjectEmailComponent } from '../send-project-email/send-project-email.component';
import { ShareProjectsComponent } from '../share-projects/share-projects.component';
import { UserService } from '../user.service';
import { GenreService } from '../genre.service';
import { MusicService } from '../music.service';
import { NavbarComponent } from '../navbar/navbar.component';
import { DownloadZipMailComponent } from '../download-zip-mail/download-zip-mail.component';
import { Location } from '@angular/common';
import { ProjectListExistanceModalComponent } from '../project-list-existance-modal/project-list-existance-modal.component';
import { MessageDialogService } from "../message-dialog.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DownloadFilesService } from "../download-files.service";
import { PlayerHistoryService } from '../player-history.service';
import { SaveStateService } from '../save-state.service';


import * as moment from 'moment';

declare var $: any;
const WaveSurfer = window['WaveSurfer'];

@Component({
  providers: [NavbarComponent],
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})

export class ProjectsComponent implements OnInit {

  @ViewChild('pageInput') pageInput: ElementRef;

  tonos = [];
  tracks: any[];
  projects: any[];
  isCatalogSelected: any;
  value: number;
  projectID: any;
  pager: any = {};
  loadingTracks = false;
  loadingProjects = true;
  loadingProjects_2 = false;
  loadingTracksSecondary = false;
  isAdd = false;
  albums: Album[];
  isSpeechBubbleOpen = true;
  isSelectAllTracks = false;
  countTracksSelected = 0;
  isTrackHover = [];
  isPlayerExtras = [];
  showTrackInfo = [];
  wavesurfer = [];
  productiontypes = [];
  searchText = '';
  volume = 1;
  isLoop = false;
  isSetIntervalDefined = null;
  // search
  chips = [];
  isChipPlus = true;
  moreChips: any[];
  secondMoreChips: any[];
  orderBy = null;
  pagedItems: any[];
  fromInsade = false;
  // paged items
  isProjectsFilter: any;
  isAddRemoveLine: any;
  searchProjects: any;
  closeTab: any;
  sortType: string;
  sortReverse = false;
  dialogRef: MatDialogRef<NewprojectmodalComponent>;
  deleteDialogRef: MatDialogRef<DeleteProjectComponent>;
  deleteTrackDialog: MatDialogRef<DeleteTrackFromProjectComponent>;
  trackOperationsDialog: MatDialogRef<TrackOperationsModalComponent>;
  sendProjectEmailComponent: MatDialogRef<SendProjectEmailComponent>;
  shareProjectsComponent: MatDialogRef<ShareProjectsComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;
  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;
  selectedProject: any;
  pagedTracks = [];
  trackPager: any = {};
  trackPage = 1;
  pageValue: number;
  itensPerPage = 1000;
  indexGlobal: number;
  isWavesurferReady = false;
  oredenaTracksCancel = null;
  modalOpen = false;
  showTrackRelatedOrSimilar = [];
  showFromAlbum = [];
  showTrackSimilar = [];
  pagedProjects = [];
  projectPager: any = {};
  projectPage = 1;
  projectPageValue: number;
  projectsPerPage = null;
  searchProjectState = 1;
  projectStates = [
    {
      name: this.i18n.getTranslationsBD('open'),
      key: 1
    },
    {
      name: this.i18n.getTranslationsBD('closeddeclared'),
      key: 5
    },
    {
      name: this.i18n.getTranslationsBD('cancelled'),
      key: 9
    },
    {
      name: this.i18n.getTranslationsBD('all'),
      key: 0
    }
  ];
  downlaodOneTrackAsZip: any;
  resultsNr = 0;
  oredenation = 'Seq';
  anyInputSelected = null;
  downloadFormats = [];
  SFXdownloadFormats = [];
  prefDownloadLabel = "";
  changing = false;
  previousUrl = this.router.url;
  secondaryInputs = [];

  countSearchs = 0;
  showMenuMobile = [];
  clickTimeOut = null;
  loopOptions = null;
  url: string;

  showProjectOnCopySearch = false;

  prevXhrRequest = null;
  wasDBL = false;
  isAnyOpen = true;

  findMuscisSubscribeCancel = null; 
  routerSubscribe = null;

  isSecondAdd =false;

  constructor(public saveState: SaveStateService, public router: Router, private route: ActivatedRoute, private projectService: ProjectService,
    private pagerService: PagerService, private dataService: DataService, private albumService: AlbumService,
    public authService: AuthService, private searchService: SearchService,
    public i18n: I18nGlvService, public NewprojectmodalComponent: MatDialog,
    public DeleteProjectComponent: MatDialog, public DeleteTrackFromProjectComponent: MatDialog,
    public TrackOperationsModalComponent: MatDialog, private userService: UserService, private navbar: NavbarComponent,
    private genreService: GenreService, private musicService: MusicService, public SendProjectEmailComponent: MatDialog,
    public ShareProjectsComponent: MatDialog, public DownloadZipMailComponent: MatDialog,
    public location: Location, public ProjectListExistanceModalComponent: MatDialog,
    public messageDialog: MessageDialogService,
    private http: HttpClient, public playerHistory: PlayerHistoryService,
    public downloadService: DownloadFilesService) {

  if (this.authService.isLoggedIn && this.authService.user.username=='spainsh') {
	  // PG:
	  this.router.navigateByUrl("/");
  }
		
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWaveformHeight();
    this.checkOpenDDLs();
  }

  logout() {
    this.authService.logout();
    this.router.navigate([""]);
    sessionStorage.clear();
  }


  fisProject2024b(project)
  {
	if (!this.authService.isLoggedIn) {return false;}
	if (this.authService.user.clientCountry.toLowerCase() != 'es') {return false;}
	if (project==null) {return true;} // new project
	if (project.StartDate instanceof Date) {
		let year=project.StartDate.substring(0,4);
		if (parseInt(year)<2024) {return false;}
	}
	return true;
  }
  
  fCanChangeOrDeleteProject(project) {
	// se opção de delete to project fica enable
	// se não poder fazer delete tb nao pode mudar os tempos de utilização das obras
	if (project == null) {return false;}
	if (!this.fisProject2024b(project)) {return true;}
	if (project.Status!='1') {return false;}
	return true;
}


  checkOpenDDLs(){
    if (window.matchMedia('(min-width: 320px)').matches && !window.matchMedia('(min-width: 640px)').matches) {
      $('.show').removeClass('show');
    } if (window.matchMedia('(min-width: 640px)').matches) {
      this.showMenuMobile = [];
    }
  }

  setWaveformHeight() {
	  if (!this.tracks) {return;}
    for (let i = 0, len = this.tracks.length; i < len; i++) {
      if (this.wavesurfer[i]) {
        const windowWidth = $(window).width() + 17;
        if (windowWidth < 1440) {
          this.wavesurfer[i].setHeight(29);
        } else {
          this.wavesurfer[i].setHeight(56);
        }
      }
    }
  }

  globalKeys(event) {
    event.stopPropagation();

    if (event.code == 'Space') {
      const focused = document.activeElement;
      if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
        event.preventDefault();
      }
    }


    if (!event.ctrlKey && !event.shiftKey && !event.altKey) {
      if ($("#row-one") !== null && this.modalOpen === false && $(".percentage-used-input").is(":focus") === false && this.router.url.includes('/projects')) {
        const focused = document.activeElement;
        if (!$("input").is(":focus") && !$("textarea").is(":focus") && !$(focused).is('[contenteditable="true"]')) {
          const lockedKeys = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Enter", " "];
          if (!lockedKeys.includes(event.key)) {
            event.stopPropagation();
            const input: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
            input.focus();
          }
        }
      }
    }
  }

  // OPERATIONS ON PROJECTS
  // novo projecto
  newProject() {
    this.modalOpen = true;
    this.dialogRef = this.NewprojectmodalComponent.open(NewprojectmodalComponent, { disableClose: true });
    let instance = this.dialogRef.componentInstance;
    instance.project = null;
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result.action != "cancel") {
        this.modalOpen = false;
        this.search(1, 1);
        this.getAllProjectsAndUpdateDataService();
      }
    });
  }

  // editar project
  editProject() {
	if (
	  this.selectedProject.tbUtilizadoresID == this.authService.user.id ||
	  this.selectedProject.ShareRW.includes(this.authService.user.id) ||
	  this.selectedProject.ShareRW == null || 
	  this.selectedProject.ShareRW == '' 
	) {
      this.modalOpen = true;
      this.dialogRef = this.NewprojectmodalComponent.open(NewprojectmodalComponent, { disableClose: true });
      let instance = this.dialogRef.componentInstance;
      instance.project = this.selectedProject;
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result.message == "success") {
          this.modalOpen = false;
          this.search(1, 1);
          this.getAllProjectsAndUpdateDataService();
        }
      });
    }
  }

  // delete project
  deleteProject() {
    if (this.selectedProject.ShareRO == null
      || !this.selectedProject.ShareRO.includes(this.authService.user.id)) {
      this.modalOpen = true;
      this.deleteDialogRef = this.DeleteProjectComponent.open(DeleteProjectComponent, { disableClose: true });
      let instance = this.deleteDialogRef.componentInstance;
      instance.projectID = this.selectedProject.ProjectsID;
      instance.userID = this.authService.user.id;
      this.deleteDialogRef.afterClosed().subscribe((result) => {
        if (result.message == "success") {
          this.modalOpen = false;
          this.search(1, 1);
          this.getAllProjectsAndUpdateDataService();
        }
      });
    }
  }

  // popular production types
  checkProductionTypes() {
    let projectIndex = null;
    this.projects.forEach((project, index) => {
      let productionTypeCode = "";
      if (project.ProductionType1 != null) {
        productionTypeCode += project.ProductionType1;
      }
      if (project.ProductionType2 != null) {
        productionTypeCode += project.ProductionType2;
      }
      if (project.ProductionType3 != null) {
        productionTypeCode += project.ProductionType3;
      }
      if (productionTypeCode != '0' && productionTypeCode.trim() != '') {
        let productionTypeLabel = '';
        this.productiontypes.forEach(productionType => {
          if (productionTypeCode == productionType.Code) {
            const language = this.i18n.language.toUpperCase();
            productionTypeLabel = productionType[language];
            project.productionTypeLabel = productionTypeLabel;
            return;
          }
        });
      }
      if (project.ProjectsID == this.projectID) {
        this.selectedProject = project;
        // this.selectedProject['isOpen'] = true;
        projectIndex = index;
      }
    });
  }

  // popular partilhas
  checkShares() {
    this.projects.forEach(project_group => {
      project_group.Projects.forEach(project => {
        project.ShareLabel = '';
        if (project.ShareRW != null || project.ShareRO != null) {
          if (project.SharedUsersNames.length > 0) {
            project.SharedUsersNames.forEach(user => {
              project.ShareLabel += user + ', ';
            });
            project.ShareLabel = project.ShareLabel.slice(0, -2);
          }
        }
      }); 
    });
  }

  // atualizar projectos no dataservice
  getAllProjectsAndUpdateDataService() {
  
    this.loadingProjects_2 = true;
    this.projectService.getProjects().subscribe(data => {
      this.loadingProjects_2 = false;
      this.projectID = data['Data'][0].ProjectsID;
      this.selectedProject = data['Data'][0]; 
      if (this.router.url.includes('projects')) {
       this.location.go('./projects/' + data['Data'][0].ProjectsID);
      }
      this.dataService.updateProjectsSERVICE({projects: data['Data'], from:'projects'});
    });
  }

  // escolher projecto
  setProject(project, index, gi, page) {
    // if (project.ProjectsID != this.projectID) {
      this.fromInsade = true;
      this.projectID = project.ProjectsID;
      this.selectedProject = project;
      this.selectedProject['isOpen'] = true;
      let group = this.projects[gi];
      this.projects.splice(gi, 1);
      this.projects.unshift(group);
      setTimeout(() => {
        if($("#project-group-body1").is(':visible')){
          $("#project-group-body1").slideToggle();
        }
      }, 0);
      this.getTracks(this.projectID);
      this.location.go('./projects/' + this.projectID);
      this.countTracksSelected = 0;
      this.goToSelectedProject();
      this.dataService.updateSelectedProject(this.selectedProject);

      //PROJECTO SELECIONADO
      let project_selected_obj = {project: this.selectedProject, from: 'projects'};
      this.dataService.updateProjectsServiceSERVICE(project_selected_obj);

    // }
  }

  // partilhar project
  shareProject() {

    this.modalOpen = true;
    this.shareProjectsComponent = this.ShareProjectsComponent.open(ShareProjectsComponent, { disableClose: true });
    let instance = this.shareProjectsComponent.componentInstance;
    instance.project = this.selectedProject;
    this.shareProjectsComponent.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      this.search(1);
      this.getAllProjectsAndUpdateDataService();
    });
  }

  // enviar projecto por e-mail
  sendProjectEmail() {
    this.modalOpen = true;
    this.sendProjectEmailComponent = this.SendProjectEmailComponent.open(SendProjectEmailComponent, { disableClose: true });
    let instance = this.sendProjectEmailComponent.componentInstance;
    instance.project = this.selectedProject;
    instance.user = this.authService.user;
    this.sendProjectEmailComponent.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      if (result.action == "result") {
        const nome = result.nome;
        alert(this.i18n.getTranslationsBD('your-email-has-been-sent-to') + ' ' + nome);
      }
    });
  }

  // copy/move projects tracks to project, opens Modal
  operateAllMusicsFromProject(event, project) {
    if (event != null) {
      event.stopPropagation();
    }
    this.projectService.getProjectTracks(project.ProjectsID).subscribe(tks => {
      if (tks.length > 0) {
        let tracksToOperate = [];
        let musicList = "";
        for (let a = 0; a < tks.length; a++) {
          const normalizedData = {
            'ProjectsID': project.ProjectsID,
            'ProjectsIID': tks[a].ProjectsIID,
            'Context1J': tks[a].Context1J,
            'Context1K': tks[a].Context1K,
            'Track': tks[a].Track,
            'TrackTitle': tks[a].TrackTitle,
            'TrackTime': tks[a].TrackTime,
            'Description': tks[a].Description
          };
          tracksToOperate.push(normalizedData);
          musicList += tks[a].MetaDataID + ',';
        }
        this.modalOpen = true;
        musicList = musicList.substring(0, musicList.length - 1)
        const type = this.authService.user.Pref_DownloadFormat;
        const projectId = project.ProjectsID;

        const projectOwner = this.selectedProject.tbUtilizadoresID;
        const shareRW = this.selectedProject.shareRW;
        const userId = this.authService.user.id;

        if (this.prevXhrRequest != null) {
          this.prevXhrRequest.unsubscribe();
          this.prevXhrRequest = null;
        }

        this.prevXhrRequest =
          this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
            const errorList = data.ErrorList;
            const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
            if (errorList != undefined) {
              if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
                this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
                let instance = this.projectListExistanceModalComponent.componentInstance;
                instance.projectList = data['MusicInOtherProjectsList'];
                this.dataService.updateModalOpen(true);
                this.modalOpen = true;
                this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
                  this.dataService.updateModalOpen(false);
                  this.modalOpen = false;
                  if (result['action'] == 'continue') {
                    // this.loadingProjects_2 = true;
                    this.projectService.getProjects().subscribe(data => {
                      // this.loadingProjects_2 = false;
                      this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
                      let instance = this.trackOperationsDialog.componentInstance;

                      if (this.showProjectOnCopySearch) {
                        instance.showSelectedProject = true;
                      } else {
                        instance.showSelectedProject = false;
                      }

                      if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                        instance.showMoveButton = true;
                      }

                      instance.tracksToOperate = tracksToOperate;
                      instance.projects = data['Data'];
                      instance.projectID = project.ProjectsID;
                      instance.mode = "projects";
                      this.trackOperationsDialog.afterClosed().subscribe((result) => {
                        if (result.action != "cancel") {
                          this.modalOpen = false;
                          this.countTracksSelected = 0;
                          this.search(1,1);
                          this.getAllProjectsAndUpdateDataService();
                        }
                      });
                    });
                  }
                });
              } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject')
              || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1)
              || Object.entries(errorList).length === 0
              || (errorList.hasOwnProperty('ProjectOwner') && Object.entries(errorList).length === 1)) {
                // this.loadingProjects_2 = true;
                this.projectService.getProjects().subscribe(data => {
                  // this.loadingProjects_2 = false;
                  this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
                  let instance = this.trackOperationsDialog.componentInstance;

                  if (this.showProjectOnCopySearch) {
                    instance.showSelectedProject = true;
                  } else {
                    instance.showSelectedProject = false;
                  }

                  if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                    instance.showMoveButton = true;
                  }

                  instance.tracksToOperate = tracksToOperate;
                  instance.projects = data['Data'];
                  instance.projectID = project.ProjectsID;
                  instance.mode = "projects";
                  this.trackOperationsDialog.afterClosed().subscribe((result) => {
                    if (result.action != "cancel") {
                      this.modalOpen = false;
                      this.countTracksSelected = 0;
                      this.search(1, 1);
                      this.getAllProjectsAndUpdateDataService();
                    }
                  });
                });
              } else {
                if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
              }
            } else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }

            this.prevXhrRequest = null;
          });
      } else {
        alert("O projecto está vazio");
      }
    });
  }

  getDateInRightFormat(date) {
    let string = moment(date).format('YYYY-MM-DD');
    return string.replace(/-/g, '.');
  }

  // copy/move multiple tracks to project, opens Modal
  trackOperations(event) {
    if (event != null) {
      event.stopPropagation();
    }

    this.modalOpen = true;
    let tracksToOperate = [];
    let musicList = "";
    this.tracks = this.pagedTracks;
    for (let a = 0; a < this.tracks.length; a++) {
      if (this.tracks[a].isTrackSelected) {
        const normalizedData = {
          'ProjectsID': this.selectedProject.ProjectsID,
          'ProjectsIID': this.tracks[a].ProjectsIID,
          'Context1J': this.tracks[a].Context1J,
          'Context1K': this.tracks[a].Context1K,
          'Track': this.tracks[a].track,
          'TrackTitle': this.tracks[a].name,
          'TrackTime': this.tracks[a].duration,
          'Description': ''
        };
        tracksToOperate.push(normalizedData);
        musicList += this.tracks[a].id + ',';
      }
    }
    musicList = musicList.substring(0, musicList.length - 1)
    const type = this.authService.user.Pref_DownloadFormat;
    const projectId = this.selectedProject.ProjectsID;

    const projectOwner = this.selectedProject.tbUtilizadoresID;
    const shareRW = this.selectedProject.shareRW;
    const userId = this.authService.user.id;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
        const errorList = data.ErrorList;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                // this.loadingProjects_2 = true;
                this.projectService.getProjects().subscribe(data => {
                  // this.loadingProjects_2 = false;
                  this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
                  let instance = this.trackOperationsDialog.componentInstance;

                  if (this.showProjectOnCopySearch) {
                    instance.showSelectedProject = true;
                  } else {
                    instance.showSelectedProject = false;
                  }

                  if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                    instance.showMoveButton = true;
                  }

                  instance.tracksToOperate = tracksToOperate;
                  instance.projects = data['Data'];
                  instance.projectID = this.projectID;
                  instance.mode = "projects";
                  this.trackOperationsDialog.afterClosed().subscribe((result) => {
                    if (result.action != "cancel") {
                      this.modalOpen = false;
                      this.countTracksSelected = 0;
                      this.search(1, 1);
                      this.getAllProjectsAndUpdateDataService();
                    }
                  });
                });
              }
            });
          } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject')
          || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1)
          || Object.entries(errorList).length === 0
          || (errorList.hasOwnProperty('ProjectOwner') && Object.entries(errorList).length === 1)) {
            // this.loadingProjects_2 = true;
            this.projectService.getProjects().subscribe(data => {
              // this.loadingProjects_2 = false;
              this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
              let instance = this.trackOperationsDialog.componentInstance;

              if (this.showProjectOnCopySearch) {
                instance.showSelectedProject = true;
              } else {
                instance.showSelectedProject = false;
              }

              if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                instance.showMoveButton = true;
              }

              instance.tracksToOperate = tracksToOperate;
              instance.projects = data['Data'];
              instance.projectID = this.projectID;
              instance.mode = "projects";
              this.trackOperationsDialog.afterClosed().subscribe((result) => {
                if (result.action != "cancel") {
                  this.modalOpen = false;
                  this.countTracksSelected = 0;
                  this.search(1, 1);
                  this.getAllProjectsAndUpdateDataService();
                }
              });
            });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });
  }

  // copy track to Project, opens Modal
  moveOrCopyTrack(event, track, i) {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    let tracksToOperate = [];
    const normalizedData = {
      'ProjectsID': this.selectedProject.ProjectsID,
      'ProjectsIID': track.ProjectsIID,
      'Context1J': track.Context1J,
      'Context1K': track.Context1K,
      'Track': track.track,
      'TrackTitle': track.name,
      'TrackTime': track.duration,
      'Description': ''
    };
    this.modalOpen = true;
    tracksToOperate.push(normalizedData);

    const projectOwner = this.selectedProject.tbUtilizadoresID;
    const shareRW = this.selectedProject.shareRW;
    const userId = this.authService.user.id;

    const musicList = track.id;
    const type = this.authService.user.Pref_DownloadFormat;
    const projectId = this.selectedProject.ProjectsID;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownload(projectId, musicList, type).subscribe(data => {
        const errorList = data.ErrorList;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty('MusicExistsInOtherProjects')) {
            this.projectListExistanceModalComponent = this.ProjectListExistanceModalComponent.open(ProjectListExistanceModalComponent, { disableClose: true });
            let instance = this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data['MusicInOtherProjectsList'];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent.afterClosed().subscribe((result) => {
              this.dataService.updateModalOpen(false);
              this.modalOpen = false;
              if (result['action'] == 'continue') {
                // this.loadingProjects_2 = true;
                this.projectService.getProjects().subscribe(data => {
                  this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
                  let instance = this.trackOperationsDialog.componentInstance;
                  // this.loadingProjects_2 = false;
                  if (track.isSimilar || track.isRelated || track.isFromAlbum) {
                    instance.showSelectedProject = true;
                  } else {
                    instance.showSelectedProject = false;
                  }

                  if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                    instance.showMoveButton = true;
                  }

                  instance.tracksToOperate = tracksToOperate;
                  instance.projects = data['Data'];
                  instance.projectID = this.projectID;
                  instance.mode = "projects";
                  this.trackOperationsDialog.afterClosed().subscribe((result) => {
                    if (result.action != "cancel") {
                      this.modalOpen = false;
                      this.countTracksSelected = 0;
                      this.search(1, 1);
                      this.getAllProjectsAndUpdateDataService();
                      document.body.scrollTop = 0; // For Safari
                      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    }
                  });
                });

              }
            });
          } else if (errorList.hasOwnProperty('MusicNotExistsInThisProject')
          || (errorList.hasOwnProperty('UserNotAllowed') && Object.entries(errorList).length === 1)
          || Object.entries(errorList).length === 0
          || (errorList.hasOwnProperty('ProjectOwner') && Object.entries(errorList).length === 1)) {
            // this.loadingProjects_2 = true;
            this.projectService.getProjects().subscribe(data => {
              // this.loadingProjects_2 = false;
              this.trackOperationsDialog = this.TrackOperationsModalComponent.open(TrackOperationsModalComponent, { disableClose: true });
              let instance = this.trackOperationsDialog.componentInstance;

              if (track.isSimilar || track.isRelated || track.isFromAlbum) {
                instance.showSelectedProject = true;
              } else {
                instance.showSelectedProject = false;
              }

              if (projectOwner == userId || (shareRW != null && shareRW.includes(userId.toString()))) {
                instance.showMoveButton = true;
              }

              instance.tracksToOperate = tracksToOperate;
              instance.projects = data['Data'];
              instance.projectID = this.projectID;
              instance.mode = "projects";
              this.trackOperationsDialog.afterClosed().subscribe((result) => {
                if (result.action != "cancel") {
                  this.modalOpen = false;
                  this.countTracksSelected = 0;
                  this.search(1, 1);
                  this.getAllProjectsAndUpdateDataService();
                  document.body.scrollTop = 0; // For Safari
                  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                }
              });
            });

          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });
  }

  // apaga tracks do projecto
  apagaTrack() {
    let tracksToDelete = [];
    for (let a = 0; a < this.tracks.length; a++) {
      if (this.tracks[a].isTrackSelected) {
        const obj = {
          ProjectsIID: this.tracks[a].ProjectsIID
        };
        tracksToDelete.push(obj);
      }
    }
    this.modalOpen = true;
    this.deleteTrackDialog = this.DeleteTrackFromProjectComponent.open(DeleteTrackFromProjectComponent, { disableClose: true });
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = this.projectID;
    instance.tracksToDelete = tracksToDelete;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      if (result.message == 'success') {
        this.modalOpen = false;
        this.countTracksSelected = 0;
        for (let a = 0; a < this.tracks.length; a++) {
          if (this.tracks[a].isTrackSelected) {
            const obj = {
              ProjectsIID: this.tracks[a].ProjectsIID
            };
            tracksToDelete.push(obj);
          }
        }
        this.search(1,1);
        this.getAllProjectsAndUpdateDataService();
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    });
  }

  ngOnInit() {
    let before_search_obj = this.saveState.getLastSearchChipsProjects();
    if(before_search_obj != undefined && before_search_obj != null){
      if(before_search_obj.text.trim() != ""){
        let temp_chips = before_search_obj.text.split(",");
        temp_chips.forEach(temp_chip => {
          this.addChip(temp_chip, null);
        });
      }
      this.projectPage = before_search_obj.pageNumber;
      this.searchProjectState = before_search_obj.status;
      let last_results = this.saveState.getLastFindProjectsTab();
      if(last_results != null){
        if(before_search_obj.keywords.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#keywords_keywords").val(before_search_obj.keywords);  
          }, 0);
        }
        if(before_search_obj.modifiedDateEND.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#date_modification_end").val(before_search_obj.modifiedDateEND);
          }, 0);
        }
        if(before_search_obj.modifiedDateINI.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#date_modification_begin").val(before_search_obj.modifiedDateINI);
          }, 0);
        }
        if(before_search_obj.projectTitle.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#keywords_title").val(before_search_obj.projectTitle);
          }, 0);
        }
        if(before_search_obj.startDateEND.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#date_creation_end").val(before_search_obj.startDateEND);
          }, 0);
        }
        if(before_search_obj.startDateINI.trim() != ''){
          this.isAddRemoveLine = true;
          setTimeout(() => {
            $("#date_creation_begin").val(before_search_obj.startDateINI);
          }, 0);
        }
      }
    }
    this.dataService.getModalOpen().subscribe(value => {
      this.modalOpen = value as boolean;
    });

    if(this.dataService.findProjectsSubscribeCancelService !== null){
      this.dataService.findProjectsSubscribeCancelService.unsubscribe();
    }
    this.dataService.findProjectsSubscribeCancelService  = this.dataService.getProjectsSERVICE().subscribe(value => {
     
       this.search(this.projectPage, 1);
    });


    if(this.dataService.projectProjectSubscriber != null){
      this.dataService.projectProjectSubscriber.unsubscribe();
    }
    this.dataService.projectProjectSubscriber = this.dataService.getProjectsSelectedSERVICE().subscribe(value => {
        if(value['from'] != 'projects'){
          this.selectedProject = value['project'];
          this.selectedProject['isOpen'] = true;
          if (value.hasOwnProperty('new') && value['new'] != null) {
            this.search(1, 1);
            this.getAllProjectsAndUpdateDataService();
            // this.selectedProject['new'] = null;
          } else {
            if (this.router.url.includes('projects')) {
              this.location.go('./projects/' + this.projectID);
              
              let isInGroup = true;
              for (let it = 0; it < this.projects.length; it++) {
                const project = this.projects[it];
                if(project.GroupName == this.selectedProject.ProjectTitle){
                  isInGroup = false;
                  break;
                }
              }
              if(!isInGroup){
                $(".projectGroup-body").each(function( index ) {
                  if($("#"+this.id).is(':visible'))
                  {
                    $("#"+this.id).slideToggle();
                  }
                });
              } else {
                let groupIndex = null;
                for (let it = 0; it < this.projects.length; it++) {
                  const projectGroup = this.projects[it];
                  if(projectGroup.IsGroup){
                    for (let it2 = 0; it2 < projectGroup.Projects.length; it2++) {
                      const project = projectGroup.Projects[it2];
                      if(project.ProjectsID == this.selectedProject.ProjectsID){
                        groupIndex = it;
                        break;
                      }
                    }
                  }
                  if(groupIndex != null){
                    break;
                  }
                }
                if(groupIndex != null){
                  $(".projectGroup-body").each(function( index ) {
                    if($("#"+this.id).is(':visible'))
                    {
                      if(this.id != "project-group-body"+groupIndex){
                        $("#"+this.id).slideToggle();
                      }
                    }
                  });
                }
              }
              setTimeout(() => {
                this.readProjectId(true);
              }, 401);

            }
          }
        }
    });

    if(this.dataService.projectLoadingSubscriber != null){
      this.dataService.projectLoadingSubscriber.unsubscribe();
    }
    this.dataService.projectLoadingSubscriber = this.dataService.getProjectLoading().subscribe(value=> {
      this.loadingProjects_2 = value['val'];
    });

    this.downlaodOneTrackAsZip = this.authService.user.Pref_DownloadSingleTrackZip;
    this.projectsPerPage = this.authService.user.Pref_PageResults;
    this.itensPerPage = 1000;
    this.getGenres();
    this.getAlbums();
    this.getProductionTypes();

    this.downloadFormats = this.dataService.getDownloadFormats();
    if (this.downloadFormats == undefined) {
      this.userService.getPreferedDownload(this.authService.user.id).subscribe(downloads => {
        this.dataService.updateDownloadFormats(downloads);
        this.downloadFormats = this.dataService.getDownloadFormats();
        this.downloadFormats.forEach(format => {
          if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
            this.prefDownloadLabel = format.DownloadFormatEN;
            return;
          }
        });
      });
    } else {
      this.downloadFormats.forEach(format => {
        if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
          this.prefDownloadLabel = format.DownloadFormatEN;
          return;
        }
      });
    }

    this.getUserSFXDownlaodFormats();
    

    this.dataService.getIsCatalogSelected().subscribe(data => {
      this.isCatalogSelected = data;
    });
  }

  getUserSFXDownlaodFormats() {
    if(this.dataService.getSFXDownloadFormats() == undefined){
      this.userService.getSFXPreferedDownload(this.authService.user.id).subscribe(downloads => {
        this.dataService.updateSFXDownloadFormats(downloads);
        this.SFXdownloadFormats = this.dataService.getSFXDownloadFormats();
        this.SFXdownloadFormats.forEach(format => {
          if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
            this.prefDownloadLabel = format.DownloadFormatEN;
            return;
          }
        });
      });
    } else {
      this.SFXdownloadFormats = this.dataService.getSFXDownloadFormats();
      this.SFXdownloadFormats.forEach(format => {
        if (format.DownloadFormat == this.authService.user.Pref_DownloadFormat) {
          this.prefDownloadLabel = format.DownloadFormatEN;
          return;
        }
      });
    }
  }

  getGenres(): void {
    let already_genres = this.saveState.getInitialGenres();
    if(already_genres != null){
      const tempGenres = already_genres;
      this.tonos = this.getGenresByLetter(tempGenres['Q']);
    } else {
      this.genreService.getGenres().subscribe(genres => {
        const tempGenres = this.processGeneres(genres);
        this.tonos = this.getGenresByLetter(tempGenres['Q']);
      });
    }
  }

  processGeneres(genres) {
    const temp_genres = {
      A: [],
      C: [],
      D: [],
      E: [],
      F: [],
      J: [],
      Q: []
    };
    genres.forEach(genre => {
      const obj = {
        name: genre.Ctg,
        context: genre.Ctx,
        subCategory: []
      };
      genre.SCtg.forEach(subCat => {
        obj.subCategory.push({ name: subCat.Ctg, contextName: '', context: subCat.SCtx });
      });

      if (genre.Tp == 'A') {
        temp_genres.A.push(obj);
      } else if (genre.Tp == 'C') {
        temp_genres.C.push(obj);
      } else if (genre.Tp == 'D') {
        temp_genres.D.push(obj);
      } else if (genre.Tp == 'E') {
        temp_genres.E.push(obj);
      } else if (genre.Tp == 'F') {
        temp_genres.F.push(obj);
      } else if (genre.Tp == 'J') {
        temp_genres.J.push(obj);
      } else if (genre.Tp == 'Q') {
        temp_genres.Q.push(obj);
      }
    });
    return temp_genres;
  }

  getGenresByLetter(_genres): any[] {
    const lista = [];
    for (const key in _genres) {
      if (_genres.hasOwnProperty(key)) {
        const element = _genres[key];
        const subgenres = [];
        for (const key2 in element.subCategory) {
          if (element.subCategory.hasOwnProperty(key2)) {
            const subelement = element.subCategory[key2];
            subgenres.push({ 'name': subelement.name, 'context': subelement.context });
          }
        }
        if (subgenres.length === 0) {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0 });
        } else {
          lista.push({ 'name': element.name, 'context': element.context, 'containerId': 0, 'subgenres': subgenres });
        }
      }
    }
    // console.table(lista);
    return lista;
  }

  addTempos($event, tempos) {
    $event.stopPropagation();
    if (tempos[this.i18n.language.toUpperCase()] !== '') {
      this.addChip(tempos[this.i18n.language.toLocaleUpperCase()], null);
      this.search(1);
    }
  }

  downloadAllTracksOfPlaylist($event, playlist, format) {
    if ($event != null) {
      $event.stopPropagation();
    }
    this.projectService.getProjectTracks(playlist.ProjectsID).subscribe(tks => {
      let musicList = "";
      for (let i = 0; i < tks.length; i++) {
        musicList += tks[i].MetaDataID + ",";

      }
      musicList = musicList.substring(0, musicList.length - 1);
      let type;

      if (format == null) {
        type = this.authService.user.Pref_DownloadFormat;
      } else {
        type = format.DownloadFormat;
      }
      this.messageDialog.toggleMessageDialog("creating-zip-file-please-wait");
      if (type == "WAV_44_ZIP_EMAIL" || type == "WAV_48_ZIP_EMAIL" || type == "MP3_320_ZIP_EMAIL") {
        this.modalOpen = true;
        const projectId = this.selectedProject.ProjectsID;
        this.downloadZipMailComponent = this.DownloadZipMailComponent.open(DownloadZipMailComponent, { disableClose: true });
        let instance = this.downloadZipMailComponent.componentInstance;
        instance.emails = this.authService.user.email + ";";
        instance.type = type;
        instance.projectId = projectId;
        instance.musicList = musicList;
        this.downloadZipMailComponent.afterClosed().subscribe((result) => {
          this.messageDialog.toggleMessageDialog("creating-zip-file-please-wait");
          this.modalOpen = false;
          let emails = result.emails;
          if (emails != undefined) {
            if (emails.trim() != "") {
              const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token + '&emails=' + emails.trim();
              return this.http.request<any>('GET', url)
                .subscribe(data => {
                  this.messageDialog.toggleMessageDialog();
                  alert(this.i18n.getTranslationsBD('an-email-as-been-sent-with-a-link-to-download'));
                }, error => {
                  this.messageDialog.toggleMessageDialog();
				  alert(this.i18n.getTranslationsBD('impossibletoadd'));
                });
            }
          }

        });

      } else {
        const projectId = this.selectedProject.ProjectsID;
        const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
        let a = document.createElement("a");
        a.target = '_blank';
        a.href = url;
        a.setAttribute("type", "hidden");
        document.body.appendChild(a);
        a.click();
        a.remove();
        this.messageDialog.toggleMessageDialog();

        /*const httpOptions = {};

        httpOptions['observe'] = 'response';
        httpOptions['responseType'] = 'blob';

        return this.http.request<any>('GET', url, httpOptions )
        .subscribe(data => {

            // const name =  data.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
            a.href = window.URL.createObjectURL(data.body);
            a.setAttribute("type", "hidden"); // make it hidden if needed
            // a.download = name;

        }, error => {
            this.messageDialog.toggleMessageDialog();
            alert("impossible");
        });*/
      }


    });
  }

  oneDownloadToRuleThemAll(event, track, selectedType, i) {
    if (this.authService.isLoggedIn && this.authService.user.Accs_MaxDownloads === 0) {
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
      if(event != undefined && event != null && event.ctrlKey){
        if(i != null){
          this.selectTrack(null, i, this.pagedTracks);
          return;
        }
      }
      if(event != undefined && event != null && event.shiftKey){ 
        if(i != null){
          this.checkTracksToSelect(i);
          return;
        }
      }
    }
    let musicList = this.downloadService.prepareDownloadList(track, this.tracks);
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(this.dataService.getSelectedProject());

    this.downloadService.DownloadFile(projectId, type, musicList, this.navbar);
    // this.deselectAllTracks(this.pagedTracks);
  }

  getAllTracksFromOneProjectAndDownload(event, project, selectedType, gi, i) {
    if (this.authService.user.Accs_MaxDownloads === 0) {
      this.toggleOptions('projects-download-dropdown_' + gi + '-' +i, event, 'projects-download-dropdown_options');
      alert(this.i18n.getTranslationsBD('impossible-to-download'));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
    }
    this.projectService.getProjectTracks(project.ProjectsID).subscribe(trackList => {
      let musicList = this.downloadService.prepareDownloadList(null, trackList, true);
      let type = this.downloadService.prepareType(selectedType);
      let projectId = project.ProjectsID;

      this.downloadService.DownloadFile(projectId, type, musicList, this.navbar);
    });
  }

  downloadFiles($event, track, format) {
    if ($event != null) {
      $event.stopPropagation();
    }

    let musicList = "";
    if (track != null) {
      musicList = track.id;
    } else {
      for (let i = 0; i < this.tracks.length; i++) {
        if (this.tracks[i].isTrackSelected) {
          musicList += this.tracks[i].id + ",";
        }
      }
      musicList = musicList.substring(0, musicList.length - 1);
    }
    let type;
    if (format == null) {
      type = this.authService.user.Pref_DownloadFormat;
    } else {
      type = format.DownloadFormat;
    }
    this.selectedProject = this.dataService.getSelectedProject();
    // this.selectedProject['isOpen'] = true;
    const projectId = this.selectedProject.ProjectsID;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest =
      this.searchService.validateDownloadErrors(projectId, musicList, type).subscribe((data) => {
        let isAbleToDownload = [];
        isAbleToDownload = data.ErrorList;
        if (isAbleToDownload.hasOwnProperty('UserNotAllowed')) {
          alert(this.i18n.getTranslationsBD('impossible-to-download'));
          return;
        }

        this.messageDialog.toggleMessageDialog("creating-zip-file-please-wait");

        if (type == "WAV_44_ZIP_EMAIL" || type == "WAV_48_ZIP_EMAIL" || type == "MP3_320_ZIP_EMAIL") {
          this.modalOpen = true;
          const projectId = this.selectedProject.ProjectsID;
          this.downloadZipMailComponent = this.DownloadZipMailComponent.open(DownloadZipMailComponent, { disableClose: true });
          let instance = this.downloadZipMailComponent.componentInstance;
          instance.emails = this.authService.user.email + ";";
          instance.type = type;
          instance.projectId = projectId;
          instance.musicList = musicList;
          this.downloadZipMailComponent.afterClosed().subscribe((result) => {
            this.modalOpen = false;
            let emails = result.emails;
            if (emails != undefined) {
              if (emails.trim() != "") {
                const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token + '&emails=' + emails.trim();
                return this.http.request<any>('GET', url)
                  .subscribe(data => {
                    this.messageDialog.toggleMessageDialog();
                    alert(this.i18n.getTranslationsBD('an-email-as-been-sent-with-a-link-to-download'));
                  }, error => {
                    this.messageDialog.toggleMessageDialog();
                    alert(this.i18n.getTranslationsBD('impossible-to-download'));
                  });
              }
            }
          });
        } else {
          const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          const httpOptions = {};

          httpOptions['observe'] = 'response';
          httpOptions['responseType'] = 'blob';

          return this.http.request<any>('GET', url, httpOptions)
            .subscribe(data => {
              // this.messageDialog.toggleMessageDialog();
              // const name = data.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
              // a.href = window.URL.createObjectURL(data.body);
              // a.setAttribute("type", "hidden"); // make it hidden if needed
              // a.download = name;
              // document.body.appendChild(a);
              // a.click();
              // a.remove();
              let a = document.createElement('a');
              a.setAttribute('download', data['linkToDownload']);
              a.href = data['linkToDownload'];
              document.body.appendChild(a);
              a.click();
              a.remove();
            }, error => {
              this.messageDialog.toggleMessageDialog();
              alert(this.i18n.getTranslationsBD('impossible-to-download'));
            });
        }
        this.prevXhrRequest = null;
      });
  }

  setProjectState(projectState, project) {
	  
	  // PG: Mudança do estado do projeto pela ddl que está perto de cada projeto:
	  project.StatusNew=projectState.key;
	  project.Status=project.StatusNew;
	  this.selectedProject = project;
	let projectObj = {
		ProjectsID: project.ProjectsID,
		tbUtilizadoresID: this.authService.user.id,
		ProjectTitle: project.ProjectTitle,
		ProjectSubTitle: project.ProjectSubTitle,
		Client: project.Client,
		ProjectComments: project.Comments,
		ProductionType1: project.ProductionType1,
		ProductionType2: project.ProductionType2,
		ProductionType3: project.ProductionType3,
		ProductionType4: '',
		ProductionType5: '',
		Status: project.StatusNew,
		GroupName: project.GroupName,
		Versions: project.Versions,
		PromoCode: project.PromoCode,
		Territory: project.Territory,
		LicensePeriod: project.LicensePeriod,
		Media: project.Media,
		ProdTypeV2: project.ProdTypeV2,
		Valid: project.Valid,
		URL:project.URL,
		StartDate: project.StartDate
    };

this.projectService.editProject(projectObj, project.ProjectsID).subscribe(
  (data) => {
    // Successful edit
    this.search(this.projectPage, 1);
  },
  (error) => {
	  // Se ao tentar fechar o projecto a API devolver erro é porque o projeto tinha erros ou nao tinhas obras com duração:
	  if (error.error=="Error5")
	  {
		project.Status="5";
		alert(this.i18n.getTranslationsBD('ProjectAlreadyLicensed'));
	  }
	  // project.Valid != "S":
	  if (error.error=="Error10")
	  {
		project.Status="1";
		project.Valid="N";
		this.editProject();
	  }
	  if (error.error=="Error15")
	  {
		project.Status="1";
		alert(this.i18n.getTranslationsBD('titles-without-duration-used'));
	  }
  }
);



	}

  setTracksPage(page: number, action = null) {
    if (page < 1 || page > this.trackPager.totalPages || isNaN(page)) {
      return;
    }

    this.indexGlobal = null;
    this.tracks.map(function (x) {
      if (x !== undefined) {
        x.isloadToWave = false;
        // x.isPlaying = false;
      }
    });

    if (this.tracks) {
      this.tracks = this.musicService.checkAllAlreadyPlayed(this.tracks);
      this.trackPager = this.pagerService.getPager(this.tracks.length, page, this.itensPerPage);
      this.pagedTracks = this.tracks.slice(this.trackPager.startIndex, this.trackPager.endIndex + 1);
    }

    this.pageValue = +this.trackPager.currentPage;
    if (action != null && action == 'next') {
      this.goToTrack(1);
      const obj = {
        track: this.pagedTracks[0],
        i: 0,
        tracks: this.pagedTracks
      };
      this.indexGlobal = 0;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[0].isPlaying = true;
    } else if (action != null && action == 'previous') {
      this.goToTrack(this.pagedTracks.length);
      const obj = {
        track: this.pagedTracks[this.pagedTracks.length - 1],
        i: this.pagedTracks.length - 1,
        tracks: this.pagedTracks
      };
      this.indexGlobal = this.pagedTracks.length - 1;
      this.dataService.updateTrackSelected(obj);
      this.pagedTracks[this.indexGlobal].isPlaying = true;
    }

    this.updateMusics();
  }

  goToTrack(index) {
    setTimeout(() => {
      const offset = 110 * (index - 1);
      if (offset === undefined) {
        return;
      }
      const positionTop = offset;
      $('html, body').scrollTop(positionTop);
    }, 200);
  }

  // ALBUNS
  getAlbums(): void {
    this.albumService.getAlbums().subscribe(albums => {
      const idioma = this.i18n.language;
      let path = '';
      let albumslist = [];
      switch (idioma) {
        case 'pt':
        case 'es':
        case 'en':
          path = albums[idioma].path + '/';
          albumslist = albums[idioma].albuns;
          break;
        default:
          path = albums['en'].path + '/';
          albumslist = albums['en'].albuns;
          break;
      }
      const listalbuns = [];
      for (let index = 0; index < albumslist.length; index++) {
        const element = albumslist[index];

        listalbuns[index] = {
          'id': index,
          'name': element.name,
          'description': element.description,
          'reference': element.code,
          'image': element.file,
          'thumb': element.thumb,
          'showHome': true
        };
      }
      this.albums = listalbuns;
      this.setPage(1);

    });
  }

  setProjectsPage(page) {

    this.wavesurfer.map(function (x) {
      if (x !== undefined) {
        x.pause();
        x.destroy();
      }
      return x;
    });

    if (this.tracks != undefined) {
      this.tracks.map(function (x) {
        if (x !== undefined) {
          x.isloadToWave = false;
          x.isPlaying = false;
        }
      });
    }

    this.wavesurfer.length = 0; // Serve para zerar a lista.
    // get pager object from service
    if (this.projects) {
      this.projectPager = this.pagerService.getPager(this.projects.length, page, this.projectsPerPage);
    }

    // get current page of items
    if (this.projects) {
      this.projects = this.projects.slice(this.projectPager.startIndex, this.projectPager.endIndex + 1);
    }

    this.projectPage = page; // + Cast String to Number
    this.projectPager.currentPage = page;

  }

  goToSelectedProject() {

    setTimeout(() => {
      let idToGo;
      let index = 0;
      let pageNumber;
      for (let index = 0; index < this.projects.length; index++) {
        const project = this.projects[index];
        if (parseInt(project.ProjectsID, 10) == this.projectID) {
          pageNumber = index;
        }
      }
      pageNumber = Math.floor(pageNumber / this.projectsPerPage) + 1;
      // this.setProjectsPage(pageNumber);
      for (let project of this.projects) {
        if (parseInt(project.ProjectsID, 10) == this.projectID) {
          idToGo = 'heading-project-' + index;
          break;
        }
        index++;
      }
      const offset = $('#' + idToGo).offset();
      if (offset === undefined) {
        return;
      }
      const positionTop = $('#' + idToGo).offset().top - 310;
      $('html, body').scrollTop(positionTop);
    }, 100);
  }

  goToFirstProject() {
    setTimeout(() => {
      let idToGo;
      let index = 0;
      idToGo = 'heading-project-0';
      const offset = $('#' + idToGo).offset();
      if (offset === undefined) {
        return;
      }
      const positionTop = $('#' + idToGo).offset().top - 310;
      $('html, body').scrollTop(positionTop);
    }, 100);
  }

  getProductionTypes() {
    this.projectService.getProductionTypes().subscribe(data => {
      this.productiontypes = data;
      this.search(this.projectPage);
    });
  }

  readProjectId(find) {
    const url = window.location.href;
    this.projectID = url.split("/")[url.split("/").length - 1];
    if (this.projectID == "playSingle" || this.projectID == 'playAll' || this.projectID == 'playShuffle') {
      this.projectID = url.split("/")[url.split("/").length - 2];
    } else {
      this.projectID = parseInt(this.projectID, 10);
    }
    let selectedIndex = null;
    let selectedGroup = null;
    for (let index = 0; index < this.projects.length; index++) {
      const projectGroup = this.projects[index];
      let isFound = false;
      let itPP;
      for (let itP = 0; itP < projectGroup.Projects.length; itP++) {
        const project = projectGroup.Projects[itP];
        if (project.ProjectsID == this.projectID) {
          this.selectedProject = project;
          this.selectedProject['isOpen'] = true;
          isFound= true;
          itPP = itP;
          break;
        }
      }
      if(isFound){
        selectedGroup = projectGroup;
        selectedIndex = index;
        let tempProj = selectedGroup;
        this.projects.splice(selectedIndex, 1);
        this.projects.unshift(tempProj);
        let temp_myProj = this.projects[0].Projects[itPP]; 
        this.projects[0].Projects.splice(itPP, 1);
        this.projects[0].Projects.unshift(temp_myProj);
        selectedIndex = 0;
        if(selectedGroup.IsGroup){
          if(!$("#project-group-body"+selectedIndex).is(':visible')){
            this.toggleProject_group(selectedIndex, this.projects[0]);
          }
        }
        break;
      }
    }
    
    if (this.selectedProject != undefined && selectedIndex != null) {
      if (this.projectID !== undefined) {
        if (this.route.snapshot.url[2]) {
          if (this.route.snapshot.url[2].path == 'playSingle') {
            this.loopOptions = 'all_manual';
          } else if (this.route.snapshot.url[2].path == 'playAll') {
            this.loopOptions = 'all_automatic';
          } else if (this.route.snapshot.url[2].path == 'playShuffle') {
            this.loopOptions = 'all_random';
          }
        } else {
        }
        this.projects.splice(selectedIndex, 1);
        this.projects.unshift(selectedGroup);
        let project_selected_obj = {project: this.selectedProject, from: 'projects'};
        this.dataService.updateProjectsServiceSERVICE(project_selected_obj);
        this.getTracks(this.projectID);
      }
    } else {
      if (this.projectID !== undefined && find) {
      }
    }

  }

  clickOpenInfo($event, i, track) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    let pos = i;
    this.showTrackInfo[i] = !this.showTrackInfo[i];
    if (this.showTrackInfo[pos]) {
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
        this.updateContainer();
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      } else {
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService.getRatingAndComments(track.metadataID).subscribe(data => {
          if (data != null) {
            track.MyComments = data['MyComments'];
            track.MyRating = data['MyRating'];
          }
        });
      }

    }
  }

  addCommentAndRating(track, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    const rating = track.MyRating != undefined ? track.MyRating : null;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };

    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  removeRating(track,  event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i,this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    track.MyRating = "";
    const rating = track.MyRating;
    const textarea = document.getElementById('comments-' + track.guid) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      "userId": this.authService.user.id,
      "contextJ": track.Context1J,
      "contextK": track.Context1K,
      "track": track.track,
      "comment": comments,
      "rate": rating
    };
    this.searchService.setRatingAndComments(obj).subscribe(data => { });
  }

  getTracks(projectID): void {
    this.tracks = [];
    this.pagedTracks = [];
    this.loadingTracks = true;
    if(this.findMuscisSubscribeCancel != null){
      this.findMuscisSubscribeCancel.unsubscribe();  
    }
    var keywords = "";
    if($("#keywords_keywords").length != 0){
      keywords = $("#keywords_keywords").val().split(" ").join(",");
    }
    this.findMuscisSubscribeCancel = this.projectService.getProjectTracks(projectID, keywords).subscribe(tks => {
      this.findMuscisSubscribeCancel = null;
      const listMusica = this.musicService.mountMusicList(tks, this.tonos, false, false, false, true);
      this.showTrackInfo.length = 0;
      this.tracks = listMusica;
      this.tracks.map(function (x) {
        x.isTrackSelected = false;
        return x;
      });
      this.loadingTracks = false;
      if (this.loopOptions == 'all_manual') {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      } else if (this.loopOptions == 'all_automatic') {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      } else if (this.loopOptions == 'all_random') {
        const event = {
          clientX: 0
        };
        setTimeout(() => {
          this.newPlay(this.tracks[0], 0, event);
        }, 1000);
      }
      this.resultsNr = tks.length;
      this.selectedProject.titles = this.selectedProject.titles != this.resultsNr ? this.resultsNr : this.selectedProject.titles;
      this.trackPager.totalPages = undefined;
      this.isWavesurferReady = true;
      this.setTracksPage(1);
      this.loadingTracks = false;
    });
  }

  getMusicTono(musica) {
    if (musica.Context1Q != null && musica.Context1Q != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (musica.Context1Q == this.tonos[i].context) {
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.Contexts != null && musica.Contexts != '') {
      let contextArray = musica.Contexts.split(" ");
      for (let i = 0; i < this.tonos.length; i++) {
        if (contextArray.includes(this.tonos[i].context)) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else if (musica.KeyCode != null && musica.KeyCode != '') {
      for (let i = 0; i < this.tonos.length; i++) {
        if (this.tonos[i].name.charAt(0) == musica.KeyCode) {
          musica.Context1Q = this.tonos[i].context;
          musica.tono = this.tonos[i].name;
          return musica;
        }
      }
    } else {
      musica.Context1Q = null;
      musica.tono = null;
      return musica;
    }
    return musica;
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages || isNaN(page)) {
      return;
    }
    // get pager object from service
    this.pager = this.pagerService.getPager(this.albums.length, page);

    // get current page of items
    this.pagedItems = this.albums.slice(this.pager.startIndex, this.pager.endIndex + 1);

    this.value = +this.pager.currentPage; // + Cast String to Number
  }

  selectAllTracks(tracks) {
    this.isSelectAllTracks = !this.isSelectAllTracks;
    if (!this.isSelectAllTracks) {
      this.deselectAllTracks(tracks);
      return;
    }
    let count = 0;
    tracks.map(function (x) {
      x.isTrackSelected = true;
      count++;
      return x;
    });
    this.countTracksSelected = count;
  }

  deselectAllTracks(tracks) {
    tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.countTracksSelected = 0.000;
    this.isSelectAllTracks = false;
  }

  openPlayerExtras(index) {
    for (let i = 0, len = this.tracks.length; i < len; i++) {
      this.isPlayerExtras[i] = false;
    }
    this.isPlayerExtras[index] = true;
  }

  listArrayStringToString(object: string[]): string {
    return object.join(', ') + '.';
  }

  onFocus(i) {
    this.anyInputSelected = i;
  }

  onFocusPage() {
    const inputElem = <HTMLInputElement>this.pageInput.nativeElement;
    inputElem.select();
  }

  changeInputPercentageUsed(event, i, track) {
    if (this.anyInputSelected != null) {
      if (!this.changing) {
        if (event.key === 'ArrowUp') {
          event.stopPropagation();
          i-- < 0;
          if (i < 0) {
            i = 0;
          }
          this.updatePercentageUsed(track, 'keys', i);
          //this.changing = true;
        }
        if (event.key === 'ArrowDown') {
          event.stopPropagation();
          i++;
          if (i >= this.tracks.length) {
            i = this.tracks.length - 1;
          }
          this.updatePercentageUsed(track, 'keys', i);
          //this.changing = true;
        }
      }
    }
  }

  updatePercentageUsed(track, from, i) {
    if (!track.percentageUsed.trim().includes(":")) {
      if (track.percentageUsed < 60) {
        if (track.percentageUsed < 10) {
          track.percentageUsed = '0' + track.percentageUsed;
        }
        track.percentageUsed = "00:" + track.percentageUsed;
      } else if (track.percentageUsed >= 100) {
        let secs = track.percentageUsed.slice(-2);
        let mins = track.percentageUsed.slice(0, -2);
        if (mins < 10) {
          mins = '0' + mins;
        }
        if (secs < 60) {
          track.percentageUsed = mins + ':' + secs;
        } else {
          alert("Tempo Inválido");
          track.percentageUsed = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        track.percentageUsed = "00:00";
        return;
      }
    }

    const projectId = this.selectedProject.ProjectsID;
    const trackId = track.ProjectsIID;
    this.projectService.updateMusicUsageTime(projectId, trackId, track.percentageUsed.trim()).subscribe(data => {
      if (from != null) {
        this.anyInputSelected = null;
        this.changing = false;
        $("#percentage-used-" + i).focus();
      }
    });
  }

  playNext($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      if(index != null){
        this.checkTracksToSelect(index);
        return;
      }
    }
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === this.tracks.length - 1) {
      return;
    }
    if (index === this.pagedTracks.length - 1) {
      this.setTracksPage(this.trackPager.currentPage + 1, 'next');
    } else {
      const next = (index + 1) % this.tracks.length;
      const obj = {
        track: this.pagedTracks[next],
        i: next,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = next;
      this.pagedTracks[next].isPlaying = true;
    }
  }

  checkFreeze() {
    if ($("#freeze").height() == 0) {
      return '';
    } else {
      return 'paddingBottomFP';
    }
  }


  playPrev($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      if(index != null){
        this.checkTracksToSelect(index);
        return;
      }
    }
    const positionAbsolute = ((this.trackPager.currentPage - 1) * this.trackPager.pageSize) + index;
    if (positionAbsolute === 0 && this.pageValue == 1) {
      return;
    }
    if (index === 0) {
      this.setTracksPage(this.trackPager.currentPage - 1, 'previous');
    } else {
      const prev = (index - 1) % this.itensPerPage;
      const obj = {
        track: this.pagedTracks[prev],
        i: prev,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
      this.indexGlobal = prev;
      this.pagedTracks[prev].isPlaying = true;
    }
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if($event != undefined && $event != null && $event.shiftKey){ 
      if(index != null){
        this.checkTracksToSelect(index);
        return;
      }
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipBackward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
      if (this.tracks[index].currentTime < 0) {
        this.tracks[index].currentTime = 0;
      }
      const obj = {
        track: this.pagedTracks[index],
        i: index,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  skipForward($event, index) {
    $event.stopPropagation();
    if($event != undefined && $event != null && $event.ctrlKey){
      this.selectTrack(null, index, this.pagedTracks);
      return;
    }
    if (index == this.indexGlobal) {
      window['globalWavesurfer'].skipForward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime + 10;

      if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
        this.tracks[index].currentTime = this.tracks[index].durationSecs;
      }
      const obj = {
        track: this.pagedTracks[index],
        i: index,
        tracks: this.pagedTracks
      };
      this.dataService.updateTrackSelected(obj);
    }
    this.indexGlobal = index;
  }

  loadExtraInfo(iterador: number): void {
    const positionWaveList = iterador;
    // const trackID = this.tracks[positionWaveList].metadataID;
    const trackID = 11340771;
    this.searchService.getMetaInfoMusic(trackID).subscribe(music => {
      const track = this.tracks[positionWaveList];
      const lang = this.i18n.language.toUpperCase();
      track.keyWords = music[lang + '_Keywords'].split(';');
      track.authors = music.AuthorsComposers;
      track.bpm = music.BPM;
      track.label = music.publisher;
      track.description = music[lang + '_Description'];
      track.description = music[lang + '_Description'];
    });
  }

  newPlay(track, i, event) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    if (!$(event.target).hasClass('dropdown-toggle') && !$(event.target).hasClass('dropdown-item')) {
      let obj = {
        track: track,
        i: i,
        tracks: this.pagedTracks
      };
      if(this.dataService.checkIfPlaying(obj.track)){
        window['globalWavesurfer'].playPause();
        if (window['globalWavesurfer'].isPlaying()) {
          if(track.hasOwnProperty('IsSFX') && track.IsSFX){
            window['beepPlayer'].play();
          }
        }else{
          window['beepPlayer'].pause();
        }
        return;
      }
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          window['globalWavesurfer'].playPause();
          if (window['globalWavesurfer'].isPlaying()) {
            track.isPlaying = true;
            if(track.hasOwnProperty('IsSFX') && track.IsSFX){
              window['beepPlayer'].play();
            }
          } else {
            obj['action'] = 'pause';
            track.isPlaying = false;
            window['beepPlayer'].pause();
          }
          this.dataService.updateTrackSelected(obj);
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
    this.indexGlobal = i;
  }

  selectTrack($event, index, tracks, state = null) {
    if($event != undefined && $event != null){
      $event.stopPropagation();
      if($event.shiftKey){ 
        this.checkTracksToSelect(index);
        return;
      }
    }
    let count = 0;
    if(state != null){
      tracks[index].isTrackSelected = state;
    } else {
      tracks[index].isTrackSelected = !tracks[index].isTrackSelected;
    }
    if (tracks[index].isTrackSelected) {
      const id = tracks[index].id;
      tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = true;
          this.dataService.setLastTrackSelectedProjec(index);
        }
      });
    } else {
      const id = tracks[index].id;
      tracks.forEach(element => {
        if (element.id == id) {
          element.isTrackSelected = false;
          this.dataService.setLastTrackSelectedProjec(index);
        }
      });
    }
    tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    this.countTracksSelected = count;
  }

  checkSelectedtracks(selectedTracks) {
    let count = 0;

    this.tracks.forEach(track => {
      if (selectedTracks.find(item => item.id === track.id)) {
        track.isTrackSelected = true;
        count++;
      }
    });
    this.countTracksSelected = count;
  }

  setFocusPlayer(el): void {
    return;
  }

  getPositionClicked(track, i, event) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    event.stopPropagation();
    setTimeout(() => {
      if(!this.wasDBL){
        const posX = event.offsetX > 0 ? event.offsetX : event.layerX;
        const width = $('#track-' + i + '-' + track.metadataID).width();
        const percentage = posX * 100 / width;
        const duration = moment.duration('00:' + track.duration).asSeconds();
        const playFrom = percentage * duration / 100;
        track.currentTime = playFrom;
        track.currentTimeDisplay = moment('2015-01-01').startOf('day').seconds(playFrom).format('mm:ss');
        const obj = {
          track: track,
          i: i,
          tracks: this.pagedTracks
        };
        this.dataService.updateTrackSelected(obj);
        this.indexGlobal = i;
      }
    }, 400);
  }

  // SEARCH
  addChip(chip, position): void {
    if (typeof chip === 'string') {
      if (chip.includes(' ')) {
        const chipstoAdd = chip.split(' ');
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty('name')) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (!chip || this.chips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }

    chip = chip.replace('_', ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };
    if (_chip !== null && _chip.hasOwnProperty('name')) {
      result['context'] = _chip.context;
    }

    if (position == null) {
      this.chips.push(result);
    } else {
      this.chips.splice(position, 0, result);
    }

    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      if (id > 0) {
        this.secondaryInputs.push({ input: 'secondary-input' + id, value: '' });
      }
    });
  }

  addMoreChip(chip): void {
    chip = chip.trim();
    if (!chip || this.moreChips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }

    chip = chip.replace('_', ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };

    this.moreChips.push(result);
  }

  addSecondMoreChip(chip): void {
    chip = chip.trim();
    if (!chip || this.secondMoreChips.length > 5) {
      return;
    }
    if (chip.charAt(0) === '-') {
      this.isChipPlus = false;
      chip = chip.substr(1);
    } else {
      this.isChipPlus = true;
    }

    chip = chip.replace('_', ' ');

    const result = {
      'name': chip,
      'isChipPlus': this.isChipPlus
    };

    this.secondMoreChips.push(result);
  }

  addChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById('secondary-input' + i) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";

    if (value.trim() != "") {
      this.addChip(value, i);
    }
  }

  checkIfDeleteChip(chip) {
    if (chip.name.length == 0) {
      this.deleteChip(chip);
    }
  }

  checkIfDeleteChipFromSearchBar() {
    const el = document.getElementById('row-one') as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.chips.length > 0) {
        const tempChip = this.chips[this.chips.length - 1];
        const text = tempChip.name + " ";
        this.deleteChip(tempChip);
        el.value = text;
      }
    }
  }

  updateChipValue(id, chip) {
    event.preventDefault();
    const texto = $("#" + id).text();
    chip.name = texto;
  }

  deleteChip(chip): void {
    this.chips = this.chips.filter(c => c !== chip);
    if (this.chips.length >= 1) {
      this.search(1);
    }
    if (this.chips.length === 0) {
      this.searchClean();
    }
  }

  deleteMoreChip(chip): void {
    this.moreChips = this.moreChips.filter(m => m !== chip);
  }

  deleteSecondMoreChip(chip): void {
    this.secondMoreChips = this.secondMoreChips.filter(m => m !== chip);
  }

  searchClean(): void {
    this.chips = [];
    this.moreChips = [];
    this.secondMoreChips = [];
    $("#row-one").val('');
    
    if(this.isAddRemoveLine){
      $("#keywords_title, #keywords_keywords, #date_creation_begin, #date_creation_end").val('');
      this.isAddRemoveLine = false;
    }
    this.search(1, 1);
  }

  search(page, force = 0): void {
    const rowOneElement: HTMLInputElement = document.getElementById('row-one') as HTMLInputElement;
    const rowOneInput: string = rowOneElement.value;
    if (rowOneInput.replace(/\s+/, '').length !== 0) {
      this.addChip(rowOneInput, null);
      rowOneElement.value = '';
      rowOneElement.focus();
    }
    const normalizedchips = [];
    const normalizedContext = [];
    for (const key in this.chips) {
      if (this.chips.hasOwnProperty(key)) {
        const chip = this.chips[key];
        if (chip.hasOwnProperty('context') && chip.isChipPlus) {
          normalizedContext.push(chip.context);
          continue;
        }
        if (chip.isChipPlus) {
          normalizedchips.push(chip.name);
        } else {
          normalizedchips.push('-' + chip.name);
        }
      }
    }
    this.isWavesurferReady = true;
    let searchString = '';
    normalizedchips.forEach(chip => {
      searchString += chip + ',';
    });
    searchString = searchString.substring(0, searchString.length - 1);
    this.searchText = searchString;
    this.projectPage = page != undefined ? page : 1;
    
    let search_obj = this.getSearchProject_obj(this.projectPage, this.searchProjectState, this.searchText, this.sortType,  this.sortReverse ? 1 : 0);
    let before_search_obj = this.saveState.getLastSearchChipsProjects();
    let last_results = this.saveState.getLastFindProjectsTab();
    if(force == 1 || before_search_obj == null || JSON.stringify(before_search_obj) != JSON.stringify(search_obj) || last_results == null){
      if(this.dataService.findProjectsSubscribeCancel !== null){
        this.dataService.findProjectsSubscribeCancel.unsubscribe();
      }
      this.loadingProjects = true;
      this.dataService.findProjectsSubscribeCancel = this.projectService.searchProjects(search_obj).subscribe(data => {
        this.dataService.findProjectsSubscribeCancel = null;
        this.countSearchs++;
        this.projects = [];
        this.projects = data['Data'];
        this.loadingProjects = false; 
        if (this.projects != null) {
          this.checkProductionTypes();
          this.checkShares();
        }
        // if(this.projects.length > 0){
          this.saveState.setLastSearchChipsProjects(search_obj);
          this.saveState.setLastFindProjectsTab(data);
        // }
        this.setProjectsPage(this.projectPage);
        if (this.projects != null) {
          if (this.countSearchs == 1) {
            this.readProjectId(true);
          } else {
            this.readProjectId(false);
          }
        }
        // this.sortType = undefined;
        // this.sortReverse = false;
        this.projectPager.currentPage = page;
        this.projectPager.totalPages = data['NumberOfPages'];
        this.projectPager.totalItems = data['Total'];
        this.loadingProjects = false;
        if(!this.isAnyOpen){
          this.projects.forEach(project => {
            project.isOpen = false;
          });
          this.selectedProject.isOpen = false;
        }
      });
    } else {
      this.loadingProjects = false; 
      if(this.projects == undefined){
        if(last_results['Data'] != null){
          this.projects = last_results['Data'];
          this.projects.forEach(projectGroup => {
            let group_playlists = projectGroup.Projects;
            for (let it = 0; it < projectGroup.Projects.length; it++) {
              const project = projectGroup.Projects[it];
              for (let it2 = 0; it2 < this.dataService.getProjects().length; it2++) {
                const project_dataService = this.dataService.getProjects()[it2];
                if(project.ProjectsID == project_dataService.ProjectsID){
                  project.Titles = project_dataService.Titles;
                  break;
                }
              }  
            }
          });
          this.readProjectId(false);
          this.setProjectsPage(before_search_obj['pageNumber']);
          this.projectPager.currentPage = before_search_obj['pageNumber'];
          this.projectPager.totalPages = last_results['NumberOfPages'];
          this.projectPager.totalItems = last_results['Total'];
        }
      }
    }
  }

  getSearchProject_obj(page, searchProjectState, searchText, sortType, sortReverse){
    let keywords_keywords = "";
    let title_keywords = "";
    let startdate_Creation = "";
    let enddate_Creation = "";
    let startdate_Modification = "";
    let enddate_Modification = "";
    if(this.isAddRemoveLine){
      keywords_keywords = $("#keywords_keywords").val().split(" ").join(",");
      title_keywords = $("#keywords_title").val().split(" ").join(",");
      startdate_Creation = $("#date_creation_begin").val();
      enddate_Creation = $("#date_creation_end").val();
      startdate_Modification = $("#date_modification_begin").val();
      enddate_Modification = $("#date_modification_end").val();
    }
    let search_obj = {
      pageNumber: page,
      status: searchProjectState,
      text: searchText,
      orderBy: sortType,
      orderByDirection: sortReverse,
      projectTitle: title_keywords,
      startDateINI: startdate_Creation,
      startDateEND: enddate_Creation,
      modifiedDateINI: startdate_Modification, 
      modifiedDateEND: enddate_Modification, 
      keywords: keywords_keywords
    };
    return search_obj;
  }

  changePage(page) {
    page = parseInt(page, 10);
    if (page < 1 || page > this.projectPager.totalPages || isNaN(page)) {
      return;
    }
    this.projectPage = page;
    this.projectPager.currentPage = page;
    this.loadingProjects = true;
    let search_obj = this.getSearchProject_obj(page, this.searchProjectState, this.searchText, this.sortType,  this.sortReverse ? 1 : 0);
    // this.projectService.searchProjects(this.authService.user.id, this.searchText, this.searchProjectState, page,  this.sortType, this.sortReverse).subscribe(data => {
      this.projectService.searchProjects(search_obj).subscribe(data => {
      this.projects = data['Data'];
      if (this.projects != null) {
        this.checkProductionTypes();
        this.checkShares();
      }
      this.setProjectsPage(page);
      if(this.projects.length > 0){
        this.saveState.setLastSearchChipsProjects(search_obj);
        this.saveState.setLastFindProjectsTab(data);
      }
      // this.readProjectId();
      this.sortType = undefined;
      this.sortReverse = false;
      this.projectPager.currentPage = page;
      this.projectPager.totalPages = data['NumberOfPages'];
      this.projectPager.totalItems = data['Total'];
      this.loadingProjects = false;
    });
  }

  searchByProjectState(key) {
    this.searchProjectState = key;
    this.search(1, 1);
  }

  cleanActualMusic(track, musics) {

    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }

    return musics;
  }

  showSimilars(event, i, track) {
    event.stopPropagation();
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    if (!('isSimilar' in track) && !('isRelated' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackSimilar[i] = !this.showTrackSimilar[i];
      if (this.showTrackSimilar[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
          this.showFromAlbum[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsSimilars(track.id, track.titleId).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, false, true, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isSimilar' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showFromAlbum[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
      }
    }
  }

  showRelated(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      event.stopPropagation();
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    this.wasDBL = true;
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    setTimeout(() => {
      this.wasDBL = false;
    }, 500);
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showFromAlbum[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.searchService.findMusicsRelated(track.id).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, true, false, false, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
          //this.checkSelected(); // tem isso na versão do search
          this.loadingTracks = false;
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isRelated' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showFromAlbum[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
        //this.checkSelected(); // tem isso na versão do search
      }
    }
  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.pagedTracks.length; id++) {
      this.pagedTracks[id].container = '#track-' + id + '-' + this.pagedTracks[id].metadataID + '-progress';
      if (this.pagedTracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
    }
    this.dataService.updateContainer({ tracks: this.pagedTracks });
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
  }

  getMusicsFromAlbum(event, i, track) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)) {
      event.stopPropagation();
      let pos = i;
      this.showFromAlbum[i] = !this.showFromAlbum[i];
      if (this.showFromAlbum[i]) {
        let decrement = 0;
        for (let j = this.pagedTracks.length - 1; j >= 0; j--) {
          if (('isRelated' in this.pagedTracks[j]) || ('isSimilar' in this.pagedTracks[j]) || ('isFromAlbum' in this.pagedTracks[j])) {
            this.pagedTracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showFromAlbum[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
        }
        pos -= decrement;
        this.loadingTracksSecondary = true;
        this.albumService.getSameAlbum(track).subscribe(data => {
          this.loadingTracksSecondary = false;
          let musics = this.musicService.mountMusicList(data, this.tonos, false, false, true, false);
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.pagedTracks.splice.apply(this.pagedTracks, [start, 0].concat(musics));
          this.updateContainer();
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.pagedTracks.length; id++) {
          if (!('isRelated' in this.pagedTracks[id]) && !('isSimilar' in this.pagedTracks[id]) && !('isFromAlbum' in this.pagedTracks[id])) {
            break;
          } else {
            if (('isFromAlbum' in this.pagedTracks[id])) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showFromAlbum[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.pagedTracks.splice(idsToRemove[j], 1);
        }
        this.updateContainer();
      }
    }
  }


  stopPropagation(event, i) {
    event.stopPropagation();
    if(event.ctrlKey && i != null){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
  }

  getReport(tipo) {
    let isAnyUsed = false;
    for (let i = 0; i < this.tracks.length; i++) {
      if (this.tracks[i].percentageUsed.trim() != "0:00" && this.tracks[i].percentageUsed.trim() != "00:00") {
        isAnyUsed = true;
      }

    }
    if (isAnyUsed) {
      let link = environment.baseUrl + 'report/' + tipo + '/' + this.selectedProject.ProjectsID + '/?token=' + this.authService.user.token;
      // window.open(link);
      let a = document.createElement("a");
      a.href = link;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      alert(this.i18n.getTranslationsBD('titles-without-duration-used'));
    }

  }

  //ORDENATION
  ordenaTracks(position) {
    this.orderBy = null;
    const selectedIds = [];
    this.tracks.forEach((track, index) => {
      if (track.isTrackSelected) {
        selectedIds.push(index);
      }
    });
    const temporaryTracks = [];
    if (position === 'top') {
      selectedIds.forEach((id) => {
        temporaryTracks.push(this.tracks[id]);
      });
      this.tracks.forEach((track, index) => {
        if (!selectedIds.includes(index)) {
          temporaryTracks.push(track);
        }
      });
      this.tracks = temporaryTracks;
      this.setTracksPage(1);
    } else if (position === 'last') {
      this.tracks.forEach((track, index) => {
        if (!selectedIds.includes(index)) {
          temporaryTracks.push(track);
        }
      });
      selectedIds.forEach((id) => {
        temporaryTracks.push(this.tracks[id]);
      });
      this.tracks = temporaryTracks;
      this.setTracksPage(this.trackPager.totalPages);
    } else if (position === 'previous') {
      const idsToTrade = [];
      selectedIds.forEach(element => {
        if (element - 1 >= 0) {
          idsToTrade.push(element - 1);
        } else {
          idsToTrade.push(element);
        }
      });
      for (let id = 0; id < selectedIds.length; id++) {
        let PreviousTrack = this.tracks[selectedIds[id]];
        let NextTrack = this.tracks[idsToTrade[id]];
        this.tracks[selectedIds[id]] = NextTrack;
        this.tracks[idsToTrade[id]] = PreviousTrack;
      }
      this.setTracksPage(this.pageValue);
    } else if (position === 'next') {
      const idsToTrade = [];
      selectedIds.forEach(element => {
        if (element + 1 < this.tracks.length) {
          idsToTrade.push(element + 1);
        } else {
          idsToTrade.push(element);
        }
      });
      for (let id = selectedIds.length - 1; id >= 0; id--) {
        let PreviousTrack = this.tracks[selectedIds[id]];
        let NextTrack = this.tracks[idsToTrade[id]];
        this.tracks[selectedIds[id]] = NextTrack;
        this.tracks[idsToTrade[id]] = PreviousTrack;
      }
      this.setTracksPage(this.pageValue);
    }
    this.presistOrdenacao();
  }

  orderTracksBy(parameter) {
    if (parameter != this.oredenation) {
      this.deselectAllTracks(this.tracks);
      let text = $("#project-" + parameter).text();
      this.orderBy = text;

      if (parameter != 'track') {
        this.tracks.sort((a, b) => (a[parameter] > b[parameter]) ? 1 : -1);
      } else if (parameter == 'track') {
        this.tracks.sort((a, b) => (parseInt(a[parameter], 10) > parseInt(b[parameter], 10)) ? 1 : -1);
      }

      this.trackPager = this.pagerService.getPager(this.tracks.length, this.trackPager.currentPage, 30);
      this.pagedTracks = this.tracks.slice(this.trackPager.startIndex, this.trackPager.endIndex + 1);
      this.presistOrdenacao();
      this.oredenation = parameter;
    }
  }

  presistOrdenacao() {
    if (this.oredenaTracksCancel == null) {
      const OrdenacaoArray = [];
      let Seq = 0;
      for (let i = 0; i < this.tracks.length; i++) {
        const obj = {
          ProjectsIID: this.tracks[i].ProjectsIID,
          Seq: Seq
        };
        OrdenacaoArray.push(obj);
        Seq += 100;
      }

      this.oredenaTracksCancel = this.projectService.ordenaTracks(OrdenacaoArray, this.projectID).subscribe(data => {
        this.oredenaTracksCancel = null;
      });
    }
  }

  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return function (a, b) {
      let result;
      if (typeof a[property] === 'string') {
        result = a[property].localeCompare(b[property]);
      } else {
        result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      }
      return result * sortOrder;
    };
  }

  sortProjects(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.search(this.projectPage);
  }

  checkIfSameProject(project, i, gi, projectPage, event) {
    event.stopPropagation();
    this.showMenuMobile = [];
    if (!$(event.target).hasClass('dropdown-toggle')) {
      if (project.ProjectsID == this.selectedProject.ProjectsID) {
        if(gi != 0){
          const grupo = this.projects[gi];
          this.projects.splice(gi,1);
          this.projects.unshift(grupo);
        }
        this.selectedProject['isOpen'] = !this.selectedProject['isOpen'];
        this.isAnyOpen = !this.isAnyOpen;
        this.updateMusics();
        setTimeout(() => {
          if($("#project-group-body1").is(':visible')){
            $("#project-group-body1").slideToggle();
          }
        }, 0);
      } else {
        this.projects[gi].Projects.splice(i,1);
        this.projects[gi].Projects.unshift(project);
        this.setProject(project, 0, gi, projectPage);
        this.dataService.setLastTrackSelectedProjec(null);
      }
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }

  updateMusics(){
    this.tracks.forEach((track, it) => {
      let containerIt = it;
      track.container = '#track-' + containerIt + '-' + track.metadataID + '-progress';
      track.timeContainer = '#currentTime-track-' + containerIt + '-' + track.metadataID;
      if(this.dataService.getFPobject() != null){
        for (let index = 0; index < this.dataService.getFPobject()['tracks'].length; index++) {
          const fpTrack = this.dataService.getFPobject()['tracks'][index];
          if(fpTrack.id == track.id){
            fpTrack.container = track.container;
            fpTrack.timeContainer = track.timeContainer;
            track.currentTime = fpTrack.currentTime;
            track.currentTimeDisplay = fpTrack.currentTimeDisplay;
            break;
          } 
        }
      }
      if(this.dataService.getFPobject() != null && this.dataService.getFPobject()['track'] != null && track.id == this.dataService.getFPobject()['track'].id){
        this.dataService.getFPobject()['track'].container = track.container;
        this.dataService.getFPobject()['track'].timeContainer = track.timeContainer;
      }
      const width = track.currentTime * 100 / track.durationSecs;
      if(width != 0 && width != 100){
        track.playerWidth = width + "%";
        track.borderRight = '1px solid black';
      }
    });
  }

  setZIndex(id) {
    if ($('.projects-state-dropdown_options').is(":visible") || $('.track-download-dropdown').is(":visible") || $('.track-projects-dropdown').is(":visible") || $('.track-actions-mobile').is(":visible")) {

    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    return width + "px";
  }

  setZIndexForce(id, event, i) {
    if(event != undefined && event != null && event.ctrlKey){
      this.selectTrack(null, i, this.pagedTracks);
      return;
    }
    if(event != undefined && event != null && event.shiftKey){ 
      if(i != null){
        this.checkTracksToSelect(i);
        return;
      }
    }
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  checkCommentSize(comment,it){
    if(document.getElementById("project_comments_"+it) != null){
      var height = $("#project_comments_"+it).height(); 
      var scrollHeight = document.getElementById("project_comments_"+it).scrollHeight;
      if(height == scrollHeight){
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  mySubString(string, number){
    var resultString = string;
    if(resultString.length > number){
      return resultString.substr(0, number) + "...";
    } else {
      return resultString;
    }
  }

  checkClassForCheckAll(){
    let anySelected = false;
    let allSelected = true;
    for (let it = 0; it < this.pagedTracks.length; it++) {
      const track = this.pagedTracks[it];
      if (!('isRelated' in track) && !('isSimilar' in track) && !('isFromAlbum' in track)){
        if(track.isTrackSelected){
          anySelected =  true;
        } else {
          allSelected = false;
        }
      }
    }
    if(allSelected && this.pagedTracks.length>0){
      return 'invertColor';
    } else if(anySelected && this.pagedTracks.length>0){
      return 'fullOpacity';
    } else{
      return '';
    }
  }
  
  checkIfFreezePlaying(){
    if(window['globalWavesurfer'].isPlaying()){
      return true;
    }
    return false;
  }
 
  //AGRUPAMENTOS
  toggleProject_group(gi, projectGroup){

    var anyToClose = false;
    $(".projectGroup-body").each(function( index ) {
      if(this.id != ("project-group-body"+gi))
      {
        if($("#"+this.id).is(':visible'))
        {
          anyToClose = true;
           $("#"+this.id).slideToggle();
        }
      }
    });
    
    setTimeout(() => {
      if(this.selectedProject != undefined && this.selectedProject != null){
        this.selectedProject['isOpen'] = false;
      }
      $("#project-group-body"+gi).slideToggle();
      let projectGroup = this.projects[gi];
        this.projects.splice(gi,1);
        this.projects.unshift(projectGroup);
        // setProject(project, index, gi, page)
        this.setProject(this.projects[0].Projects[0], 0, 0, this.projectPager.currentPage);
    }, (!anyToClose ? 0 : 400));
  }

  getMinHeight(gi){
    var minHeight;
    if($('#project-group-body'+gi).is(':visible')){
      minHeight = '52px';
    } else {
      minHeight = '26px';
    }
    if(window.matchMedia('(min-width: 640px)').matches){
      if($('#project-group-body'+gi).is(':visible')){
        minHeight = '76px';
      } else {
        minHeight = '38px';
      }
    }
    if(window.matchMedia('(min-width: 960px)').matches){
      if($('#project-group-body'+gi).is(':visible')){
        minHeight = '98px';
      } else {
        minHeight = '49px';
      }
    }
    if(window.matchMedia('(min-width: 1440px)').matches){
      if($('#project-group-body'+gi).is(':visible')){
        minHeight = '120px';
      } else {
        minHeight = '65px';
      }
    }
    if(window.matchMedia('(min-width: 1920px)').matches){
      if($('#project-group-body'+gi).is(':visible')){
        minHeight = '130px';
      } else {
        minHeight = '65px';
      }
    }
    return minHeight;
  }

  toggleOptions(id, evt, myclass,){
    evt.stopPropagation();
    if($("#"+id+"_options").is(':visible')){
      $("#"+id+"_options").toggle();
    } else {
      $(".dropdown-menu").css("display", "none");
      $("."+myclass).each(function( index ) {
        if($( this ).attr('id') != id){
          $( this ).css("display", "none");
        }
      });
      $("#"+id+"_options").toggle();
      $("#"+id+"_options").css("z-index", "13");
    }
  }

  closeOptions(id){
    var new_id = id+"_options";
    if($("#"+id+"_options").is(':visible')){
      $("#"+id+"_options").toggle();
    }
  }

  checkTracksToSelect(i){
    this.clearSelection();  
    let last_index = this.dataService.getLastTrackSelectedProject();
    let initial_index = -1;
    if(last_index != null){
      initial_index = last_index; 
    }    
    if(initial_index == i){
      this.selectTrack(null, i, true);
      return;
    }
    if(initial_index > i){
      for (let it = i; it < initial_index; it++) {
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedProjec(it);
          }
        });
      }
    } else {
      for (let it = initial_index +1; it <= i; it++){
        const id = this.tracks[it].id;
        this.tracks.forEach(element => {
          if (element.id == id) {
            element.isTrackSelected = true;
            this.dataService.setLastTrackSelectedProjec(it);
          }
        });
      }
    }
    let count = 0;
    this.tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    this.countTracksSelected = count;
    setTimeout(() => {
      this.clearSelection();  
    }, 0);
  }

  clearSelection()
  {
   if (window.getSelection) {
     window.getSelection().removeAllRanges();
    }
  }

  checkIfApplyMarginTop(project, projects, i){
    if(this.selectedProject != undefined && this.selectedProject != null && project.ProjectsID !== this.selectedProject.ProjectsID && !this.selectedProject.isOpen){
      if(i == 0 || i == 1 && projects[0].ProjectsID == this.selectedProject.ProjectsID){
        return true;
      } else {
        return false;
      }
    }
    return false;
  }


}

