import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { environment } from "../../environments/environment";
import { MindState } from "../mind-state";
import { Catalog } from "../catalog";
import { Track } from "../track";
import { Playlist } from "../playlist";
import { User } from "../user";
import { GenreService } from "../genre.service";
import { PlaylistService } from "../playlist.service";
import { ProjectService } from "../project.service";
import { AuthService } from "../auth/auth.service";
import { PlayerHistoryService } from "../player-history.service";
import { DataService } from "../data.service";
import { PagerService } from "../pager.service";
import { I18nGlvService } from "../i18n-glv.service";
import { SearchService } from "../search.service";
import { SaveStateService } from "../save-state.service";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../user.service";
import { AlbumService } from "../album.service";
import { TrackOperationsModalComponent } from "../track-operations-modal/track-operations-modal.component";
import { MatDialog, MatDialogRef } from "@angular/material";
import { NewprojectmodalComponent } from "../newprojectmodal/newprojectmodal.component";
import { DeleteProjectComponent } from "../delete-project/delete-project.component";
import { ToastrService } from "ngx-toastr";
import { DeleteTrackFromProjectComponent } from "../delete-track-from-project/delete-track-from-project.component";
import { MusicService } from "../music.service";
import { SendProjectEmailComponent } from "../send-project-email/send-project-email.component";
import { ShareProjectsComponent } from "../share-projects/share-projects.component";
import { DownloadZipMailComponent } from "../download-zip-mail/download-zip-mail.component";
import { Location } from "@angular/common";
import { element } from "@angular/core/src/render3";
import * as moment from "moment";
import { ProjectListExistanceModalComponent } from "../project-list-existance-modal/project-list-existance-modal.component";
import { timeout } from "q";
import { MessageDialogService } from "../message-dialog.service";
import { DownloadFilesService } from "../download-files.service";
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import myWaveSurfer from "../../assets/js/wavesurfer.js";
import { HomeinfoService } from "../homeinfo.service";

declare let $: any;
const WaveSurfer = window["WaveSurfer"];
// const myWaveSurfer = window['WaveSurfer'];
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @ViewChild("chip") inputEl: ElementRef;
  @ViewChild("pass") passEl: ElementRef;
  @ViewChild("user") userEl: ElementRef;
  @ViewChild("pageInput") pageInput: ElementRef;
  genres: any[];
  mindStates: MindState[];
  catalogs: Catalog[];
  tracks: any[];
  playingTracks: any[];
  playlists: Playlist[];
  projects: any[];
  secondaryInputs = [];
  secondaryInputsMore = [];
  secondaryInputsSecondMore = [];
  showDragLabel = [];
  chips = [];
  moreChips = [];
  secondMoreChips = [];
  currentTime: any;
  tonos = [];
  isWavesurferReady = false;
  isTrackHover: any[];
  isTrackSelected = false;
  modalOpen = false;
  isPassword = false;
  isLogged = false;
  isCatalog = false;
  isPlaylist = false;
  isChipPlus = true;
  isAddRemoveLine = false;
  isAdd = false;
  isSecondAdd = false;
  isSearchFav = false;
  isResult = false;
  isCatalogFilter = false;
  sortType: string;
  sortReverse = false;
  isCatalogSelected = false;
  activeSelected: any;
  isAlbumSelected = false;
  activeAlbumSelected: any;
  isPlaylistFilter = false;
  isSearchPlaylist = false;
  isProjectsFilter = false;
  isSearchProject = false;
  loadingProjects = true;
  inputTypeSearch1 = "text";
  inputSizeSearch1 = 1000;
  inputTypeSearch2 = "text";
  inputSizeSearch2 = 1000;
  inputTypeSearch3 = "text";
  inputSizeSearch3 = 1000;
  myRatingValue = 0;
  loginStep = 1;
  placeholder = null;
  getAudioFromURL = null;

  context_Preferences;
  context_newReleases;

  prevXhrRequest = null;
  searchDropdownItems = [
    {
      text: this.i18n.getTranslationsBD("anyfield"),
      value: "",
    },
    {
      text: this.i18n.getTranslationsBD("keywords"),
      value: "Keywords",
    },
    {
      text: this.i18n.getTranslationsBD("album-number"),
      value: "AlbumID",
    },
    {
      text: this.i18n.getTranslationsBD("album-title"),
      value: "AlbumTitle",
    },
    {
      text: this.i18n.getTranslationsBD("track-title"),
      value: "TrackTitle",
    },
    {
      text: this.i18n.getTranslationsBD("author"),
      value: "Autor",
    },
    {
      text: this.i18n.getTranslationsBD("lyrics"),
      value: "Lyrics",
    },
    {
      text: this.i18n.getTranslationsBD("artist-vimúsica"),
      value: "Artist",
    },
    {
      text: this.i18n.getTranslationsBD("my-comments"),
      value: "MyComments",
    },
    {
      text: this.i18n.getTranslationsBD("my-ratings"),
      value: "MyRatings",
    },
  ];
  searchDropdownItem = this.searchDropdownItems[0];
  moreSearchDropdownItemsRight = [
    {
      text: this.i18n.getTranslationsBD("anyfield"),
      value: "",
    },
    {
      text: this.i18n.getTranslationsBD("keywords"),
      value: "Keywords",
    },
    {
      text: this.i18n.getTranslationsBD("album-number"),
      value: "AlbumID",
    },
    {
      text: this.i18n.getTranslationsBD("album-title"),
      value: "AlbumTitle",
    },
    {
      text: this.i18n.getTranslationsBD("track-title"),
      value: "TrackTitle",
    },
    {
      text: this.i18n.getTranslationsBD("author"),
      value: "Autor",
    },
    {
      text: this.i18n.getTranslationsBD("lyrics"),
      value: "Lyrics",
    },
    {
      text: this.i18n.getTranslationsBD("artist-vimúsica"),
      value: "Artist",
    },
    {
      text: this.i18n.getTranslationsBD("my-comments"),
      value: "MyComments",
    },
    {
      text: this.i18n.getTranslationsBD("my-ratings"),
      value: "MyRatings",
    },
  ];
  moreSearchDropdownItemRight = this.moreSearchDropdownItemsRight[0];

  moreSearchDropdownItemsLeft = [
    {
      label: this.i18n.getTranslationsBD("and").toUpperCase(),
      value: "And",
    },
    {
      label: this.i18n.getTranslationsBD("or").toUpperCase(),
      value: "Or",
    },
    {
      label: this.i18n.getTranslationsBD("not").toUpperCase(),
      value: "Not",
    },
  ];

  moreSearchDropdownItemLeft = this.moreSearchDropdownItemsLeft[0];

  secondMoreSearchDropdownItemsRight = [
    {
      text: this.i18n.getTranslationsBD("anyfield"),
      value: "",
    },
    {
      text: this.i18n.getTranslationsBD("keywords"),
      value: "Keywords",
    },
    {
      text: this.i18n.getTranslationsBD("album-number"),
      value: "AlbumID",
    },
    {
      text: this.i18n.getTranslationsBD("album-title"),
      value: "AlbumTitle",
    },
    {
      text: this.i18n.getTranslationsBD("track-title"),
      value: "TrackTitle",
    },
    {
      text: this.i18n.getTranslationsBD("author"),
      value: "Autor",
    },
    {
      text: this.i18n.getTranslationsBD("lyrics"),
      value: "Lyrics",
    },
    {
      text: this.i18n.getTranslationsBD("artist-vimúsica"),
      value: "Artist",
    },
    {
      text: this.i18n.getTranslationsBD("my-comments"),
      value: "MyComments",
    },
    {
      text: this.i18n.getTranslationsBD("my-ratings"),
      value: "MyRatings",
    },
  ];
  secondMoreSearchDropdownItemRight =
    this.secondMoreSearchDropdownItemsRight[0];

  secondMoreSearchDropdownItemsLeft = [
    {
      label: this.i18n.getTranslationsBD("not").toUpperCase(),
      value: "Not",
    },
    {
      label: this.i18n.getTranslationsBD("and").toUpperCase(),
      value: "And",
    },
    {
      label: this.i18n.getTranslationsBD("or").toUpperCase(),
      value: "Or",
    },
  ];
  secondMoreSearchDropdownItemLeft = this.secondMoreSearchDropdownItemsLeft[0];

  minBPMone = null;
  maxBPMone = null;
  minBPMtwo = null;
  maxBPMtwo = null;
  minBPMthree = null;
  maxBPMthree = null;
  minDURone = null;
  maxDURone = null;
  minDURtwo = null;
  maxDURtwo = null;
  minDURthree = null;
  maxDURthree = null;
  searchProjectName = "";
  trackPlayingDuration: any;
  showTrackInfo = [];
  model = new User();
  isUserRememberBubble = false;
  isPasswordRememberBubble = false;
  isUserBubble = false;
  isPasswordBubble = false;
  isUserOptions = false;
  isProjectOptions = false;
  isProjects = false;
  isPlayerExtras: any[];
  countClicksOutUserOptions = 1;
  countClicksOutProjectOptions = 1;
  countClicksOutProjects = 1;
  isSpeechBubbleOpen = true;
  isSelectAllTracks = false;
  pager: any = {};
  volume = 1;
  isLoop = false;
  countClicksOutTabs = 1;
  value: number;
  tabCols: number;
  isSetIntervalDefined = null;
  selectedProject: any;
  url: string;
  dialogRef: MatDialogRef<NewprojectmodalComponent>;
  deleteDialogRef: MatDialogRef<DeleteProjectComponent>;
  trackOperationsDialog: MatDialogRef<TrackOperationsModalComponent>;
  deleteTrackDialog: MatDialogRef<DeleteTrackFromProjectComponent>;
  sendProjectEmailComponent: MatDialogRef<SendProjectEmailComponent>;
  shareProjectsComponent: MatDialogRef<ShareProjectsComponent>;
  downloadZipMailComponent: MatDialogRef<DownloadZipMailComponent>;
  projectListExistanceModalComponent: MatDialogRef<ProjectListExistanceModalComponent>;

  genresSubscriber = null;
  findMusicaSubscribeCansel = null;
  placeholderRequestSubscriber = null;
  showTrackRelatedOrSimilar = [];
  showTrackSimilar = [];
  showAlbumTracks = [];
  genresSearchString = "";
  moodsSearchString = "";
  instrumentsSearchString = "";
  temposSearchString = "";
  periodsSearchString = "";
  keysSearchString = "";
  cataloguesSearchString = "";
  itensPerPage = 20;
  downlaodOneTrackAsZip: any;
  isFirstTime = true;
  counter = 0;
  downloadFormats = [];
  loadingTracks = false;
  inputRef: any;
  trackInFreezePlayer = null;
  prefDownloadLabel = "";
  firstTimeSearchingTerm = false;
  lastFocusInput = "row-one";
  recentSearchs = [];
  favouriteSearches = [];
  wavesurfer: any[];
  indexGlobal = null;
  selectedTrack = null;
  clickTimeOut = null;
  anyPlaying = false;
  isPinned = false;
  loopOptions = "all_manual";
  tabOpen = null;
  showMenuMobile = [];
  timeoutFreezePlayerController = undefined;
  selectedProjectsTracks = [];
  loadingSelectedTracksProjects = true;
  xlpreparelogin = false;

  searchParmeters = [];
  rememberUser = false;
  rememberPass = false;

  searchMode = null;
  catalogsSearchResult: any;
  albunsSearchResult: any;

  cookiesAccepted = true;

  wasDBL = false;
  isDragActive = false;
  isDroppable = false;

  isNewReleasesChipActive = false;
  isPreferedChipActive = false;
  isSearchBlocked = false;

  dropAreaOver = false;
  /* drag e drop files */
  files = [];
  isDraggingFile = false;
  dragTimer;
  wavesurferFile = window;

  minTimeI1 = null;
  maxTimeI1 = null;
  time2 = null;
  minTimeI2 = null;
  maxTimeI2 = null;
  predefinedTimeI1 = null;
  predefinedTimeI2 = null;
  totalDuration = null;

  pageSubscriber = null;
  categoriesSubscriber = null;
  trackSelectedAllInfo = null;
  
  destroyPlayerFiles() {
    this.dataService.setFiles([]);
    if (window["wavesurferFile"] != null) {
      window["wavesurferFile"].destroy();
    }
    window["wavesurferFile"] = null;
    this.minTimeI1 = null;
    this.maxTimeI1 = null;
    this.time2 = null;
    this.minTimeI2 = null;
    this.maxTimeI2 = null;
    this.predefinedTimeI1 = null;
    this.predefinedTimeI2 = null;
    this.totalDuration = null;
  }

  @HostListener("window:dragover", ["$event"])
  onDragOver(event) {
    var dt =
      event.originalEvent != undefined
        ? event.originalEvent.dataTransfer
        : event.dataTransfer;
    if (
      dt.types &&
      (dt.types.indexOf
        ? dt.types.indexOf("Files") != -1
        : dt.types.contains("Files"))
    ) {
      this.isDraggingFile = true;
      this.dropAreaOver = false;
    }
    clearTimeout(this.dragTimer);
  }

  @HostListener("window:dragleave", ["$event"])
  onDragLeave(event) {
    event.preventDefault();
    this.dragTimer = setTimeout(() => {
      this.isDraggingFile = false;
      this.isDroppable = false;
    }, 25);
  }

  @HostListener("window:drop", ["$event"])
  onBodyDrop(event: DragEvent) {
    this.isDraggingFile = false;
    event.preventDefault();
  }

  dragOverDropZone() {
    setTimeout(() => {
      this.dropAreaOver = true;
    }, 1);
  }

  dropped(from, event) {
    this.isDraggingFile = false;
    this.dropAreaOver = false;
    var dt =
      event.originalEvent != undefined
        ? event.originalEvent.dataTransfer
        : event.dataTransfer;
    if (dt.files.length > 0) {
      const file = dt.files[dt.files.length - 1];
      this.checkIfFileAndSearchIt(0, file);
    }
  }

  droppedText(from, event) {
    this.isDraggingFile = false;
    this.dropAreaOver = false;
    var dt =
      event.originalEvent != undefined
        ? event.originalEvent.dataTransfer
        : event.dataTransfer;
    const droppedText = dt.getData("Text");
    if (droppedText.trim() != "") {
      this.searchClean();
      this.addChip(droppedText, null);
      this.search();
    }
  }

  focusInput(selector) {
    $(selector).focus();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.checkIfFileAndSearchIt(0, fileInput.target.files[0]);
    }
  }

  checkIfFileAndSearchIt(from, file) {
    if (
      file.type != "audio/mp3" &&
      file.type != "audio/wav" &&
      file.type != "audio/mpeg"
    ) {
      alert(this.i18n.getTranslationsBD("aimssimilarsuploadfileformat"));
      return;
    }
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.dataService.setFiles([
        { name: file.name, base64: fileReader.result, file: file },
      ]);
      const tempType = file.type.split("/")[1];
      const type = tempType != "mpeg" ? tempType : "mp3";
      const result = {
        name:
          file.name.split(".")[0].length > 22
            ? file.name.split(".")[0].substring(0, 22) + "..." + "." + type
            : file.name,
        entireName: file.name,
        isChipPlus: this.isChipPlus,
        isFile: true,
        starttime: 30,
        endtime: 90,
      };
      this.isSearchBlocked = true;

      this.loadingTracks = false;
      if (from == 0) {
        $("#row-one").val("");
        this.chips = [];
        this.moreChips = [];
        this.secondMoreChips = [];
        this.isAddRemoveLine = false;
        this.isAdd = false;
        this.isSecondAdd = false;
        this.chips.push(result);
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({
            input: "secondary-input" + id,
            value: "",
          });
        });
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({
            input: "secondary-input-more" + id,
            value: "",
          });
        });
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({
            input: "secondary-input-second-more" + id,
            value: "",
          });
        });
        this.showFile("file");
      }
    };
    fileReader.readAsDataURL(file);
  }

  showFile(input) {
    if (window["wavesurferFile"] != null) {
      window["wavesurferFile"].destroy();
    }
    this.mountFilePlayer(input);
  }

  mountFilePlayer(input) {
    if (document.getElementById("waveFile-track") != null) {
      window["wavesurferFile"] = myWaveSurfer.create({
        container: document.querySelector("#waveFile-track"),
        waveColor: "grey",
        progressColor: "#f16523",
        responsive: true,
        height: 50,
        barWidth: 1,
        barGap: 3,
        cursorColor: "transparent",
      });
      window["wavesurferFile"].loadBlob(this.dataService.getFiles()[0]["file"]);
    }
    const _self = this;
    window["wavesurferFile"].on("ready", function () {
      let totalTime = window["wavesurferFile"].getDuration();
      _self.totalDuration = totalTime;
      _self.minTimeI1 = _self.fromSeconds(0);
      _self.maxTimeI1 = _self.fromSeconds(totalTime - 1);
      _self.minTimeI2 = _self.fromSeconds(1);
      _self.maxTimeI2 = totalTime;

      let min = 30;
      let max = 90;
      let targetChip = null;
      for (let it = 0; it < _self.chips.length; it++) {
        const chip = _self.chips[it];
        if (chip.hasOwnProperty("isFile") || _self.isLink(chip.name)) {
          targetChip = chip;
          break;
        }
      }
      if (targetChip != null) {
        min = targetChip.starttime;
        max = targetChip.endtime;
      }
      if (max > totalTime) {
        max = Math.floor(totalTime);
        min = max - 60;
        if (min < 0) {
          min = 0;
        }
        if (targetChip != null) {
          targetChip.starttime = min;
          targetChip.endtime = max;
        }
      }
      $("#min-time").val(_self.fromSeconds(min));
      $("#max-time").val(_self.fromSeconds(max));
      _self.predefinedTimeI1 = _self.fromSeconds(min);
      _self.predefinedTimeI2 = _self.fromSeconds(max);
      $("#slider-range").slider({
        range: true,
        min: 0,
        max: totalTime,
        values: [min, max],
        slide: function (event, ui) {
          let minVal = ui.values[0];
          let maxVal = ui.values[1];
          if (ui.handleIndex == 0) {
            //range da esquerda
            if (minVal < maxVal - 60) {
              maxVal = minVal + 60;
            } else if (minVal + 5 > maxVal) {
              minVal = maxVal + 5;
              return false;
            }
          } else {
            //range da direita
            if (maxVal - 60 > minVal) {
              minVal = maxVal - 60;
            } else if (maxVal - 5 < minVal) {
              maxVal = minVal + 5;
              return false;
            }
          }
          _self.setSelectorValues(minVal, maxVal);
        },
      });

      if (input == "file") {
        _self.loadingTracks = true;
        _self.search();
      } //else {
        //const searchObj = _self.dataService.getLastSearchObj();
        //_self.findMusicaSubscribeCansel = _self.searchService
        //  .findMusicsAims(searchObj["searchObj"], 0)
        //  .subscribe((tks) => {
        //    _self.processSearchData(tks);
        //    // _self.playerHistory.updateRecentSearchs(searchObj['searchObj']);
        //  });
		//}
    });
  }

  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  setSelectorValues(minVal, maxVal) {
    $("#min-time").val(this.fromSeconds(minVal));
    $("#max-time").val(this.fromSeconds(maxVal));
    $("#slider-range").slider("values", 0, minVal);
    $("#slider-range").slider("values", 1, maxVal);
  }

  toSeconds(value) {
    return moment(value, "mm:ss").diff(moment().startOf("day"), "seconds");
  }

  fromSeconds(value) {
    return moment("2015-01-01").startOf("day").seconds(value).format("mm:ss");
  }

  calcAmountToCompareAims(from, event, search) {
    event.preventDefault();
    event.stopPropagation();
    let minVal = $("#min-time").val();
    let maxVal = $("#max-time").val();
    // minVal = this.toSeconds($("#min-time").val());
    // maxVal = this.toSeconds($("#max-time").val());
    if (from == 0) {
      //range da esquerda
      minVal = this.timeValidator(minVal);
      minVal = this.toSeconds(minVal);
      maxVal = this.toSeconds($("#max-time").val());
      if (minVal < maxVal - 60) {
        maxVal = minVal + 60;
        event.preventDefault();
      } else if (minVal + 5 > maxVal) {
        if (minVal + 5 <= this.totalDuration) {
          maxVal = minVal + 5;
        } else {
          minVal = maxVal + 5;
        }
        event.preventDefault();
      }
      if (minVal > maxVal) {
        minVal = maxVal - 5;
        event.preventDefault();
      }
      if (minVal > this.totalDuration - 5) {
        minVal = this.totalDuration - 5;
        maxVal = this.totalDuration;
      }
    } else {
      //range da direita
      maxVal = this.timeValidator(maxVal);
      maxVal = this.toSeconds(maxVal);
      minVal = this.toSeconds($("#min-time").val());
      if (maxVal - 60 > minVal) {
        minVal = maxVal - 60;
        event.preventDefault();
      } else if (maxVal - 5 < minVal) {
        if (minVal - 5 > 0) {
          minVal = minVal - 5;
        } else {
          maxVal = minVal + 5;
        }
        event.preventDefault();
      }
      if (maxVal < minVal) {
        maxVal = minVal + 5;
        event.preventDefault();
      }
      if (maxVal > this.totalDuration) {
        minVal = this.totalDuration - 5;
        maxVal = this.totalDuration;
      }
    }
    this.setSelectorValues(minVal, maxVal);
    if (search) {
      this.search();
    }
  }

  timeValidator(input) {
    var output;
    if (!input.trim().includes(":")) {
      if (input < 60) {
        if (input < 10) {
          output = "0" + input;
        }
        output = "00:" + input;
      } else if (input >= 100) {
        let secs = input.slice(-2);
        let mins = input.slice(0, -2);
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 60) {
          output = mins + ":" + secs;
        } else {
          output = "00:00";
        }
      } else {
        output = "00:00";
      }
    } else {
      output = input;
    }
    return output;
  }

  openFileSystem() {
    var type = $("#row-one").attr("type");
    if (type != "number") {
      $("#file-input").trigger("click");
    }
  }

  /* drag e drop files */

  checkVisibilityFP() {
    if (this.isPinned && this.anyPlaying) {
      return "fullHeight";
    } else {
      if (this.anyPlaying) {
        const container = this.selectedTrack.container;
        if (container == null) {
          return "fullHeight";
        } else {
          if (document.getElementById(container.substr(1)) == null) {
            return "fullHeight";
          } else {
            if (this.isScrolledIntoView(container)) {
              return "noHeight";
            } else {
              return "fullHeight";
            }
          }
        }
      } else {
        return "noHeight";
      }
    }
  }

  isScrolledIntoView(elem) {
    const docViewTop = $(window).scrollTop();
    let docViewBottom =
      docViewTop + ($(window).height() - $("#secondary-navbar").height());
    if (!window.location.href.includes("search")) {
      docViewBottom -= $("#main-nav").height();
    }
    if (window.location.href.includes("projects")) {
      docViewBottom -= $(".selectedProject").height() / 2;
    }
    if (window.location.href.includes("playlists")) {
      // docViewBottom -= $(".project-tracks").height();
    }

    let tamanho = 0;
    tamanho =
      $(".marginTop85").offset() != undefined
        ? $(".marginTop85").offset().top - $(elem).height()
        : $(".marginTop85FW").offset().top - $(elem).height();
    const elemTop = $(elem).offset().top - tamanho;
    const elemBottom = elemTop + $(elem).height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  globalKeys(event) {
    if (event.code == "Space") {
      const focused = document.activeElement;
      if (
        !$("input").is(":focus") &&
        !$("textarea").is(":focus") &&
        !$(focused).is('[contenteditable="true"]')
      ) {
        event.preventDefault();
      }
    }
    if (
      this.authService.isLoggedIn &&
      this.router.url.includes("search") &&
      event.key == "Enter"
    ) {
      this.checkIfSearchPlaceholder("#row-one");
      this.search();
    }

    if (!event.ctrlKey && !event.shiftKey && !event.altKey) {
      if (this.isInSearchAndAnyInputFocus()) {
        const lockedKeys = [
          "ArrowUp",
          "ArrowDown",
          "ArrowRight",
          "ArrowLeft",
          "Enter",
          " ",
        ];
        if (!lockedKeys.includes(event.key)) {
          event.stopPropagation();
          if (document.getElementById(this.lastFocusInput) != null) {
            const input: HTMLInputElement = document.getElementById(
              this.lastFocusInput
            ) as HTMLInputElement;
            input.focus();
          } else {
            if (document.getElementById(this.lastFocusInput) != null) {
              const input: HTMLInputElement = document.getElementById(
                "row-one"
              ) as HTMLInputElement;
              input.focus();
            }
          }
        }
      }
    } else if ((event.ctrlKey || event.metaKey) && event.keyCode == 86) {
      if (this.isInSearchAndAnyInputFocus()) {
        if (document.getElementById(this.lastFocusInput) != null) {
          const input: HTMLInputElement = document.getElementById(
            this.lastFocusInput
          ) as HTMLInputElement;
          input.focus();
        } else {
          const input: HTMLInputElement = document.getElementById(
            "row-one"
          ) as HTMLInputElement;
          input.focus();
        }
      }
    }
  }

  isInSearchAndAnyInputFocus() {
    if (
      $("#row-one") != null &&
      !this.modalOpen &&
      this.router.url === "/search"
    ) {
      const focused = document.activeElement;
      if (
        !$("input").is(":focus") &&
        !$("textarea").is(":focus") &&
        !$(focused).is('[contenteditable="true"]')
      ) {
        return true;
      }
    }
    return false;
  }

  justGetProjects() {
    let obj = {
      val: true,
    };
    this.dataService.updategetProjectLoading(obj);
    this.projectService.getProjects().subscribe((projects) => {
      let obj = {
        val: false,
      };
      this.dataService.updategetProjectLoading(obj);
      this.projects = projects["Data"];
      this.dataService.updateProjects(this.projects);
      this.dataService.updateProjectsSERVICE({
        projects: this.projects,
        from: "navbar",
      });
      if (this.projects.length > 0) {
        const lastModified = this.projects[0];
        this.selectedProject = lastModified;
        this.getSelectedProjectsTracks(this.selectedProject);
        this.dataService.updateSelectedProject(lastModified);
        $("#project-titles").text(this.selectedProject.Titles);
      }
    });
  }

  getSelectedProjectsTracks(project) {
	  if (project==null) {return;}
    const projectID = project.ProjectsID;
    this.loadingSelectedTracksProjects = true;
    if (this.dataService.getSelectedTracks != null) {
      this.dataService.getSelectedTracks.unsubscribe();
    }
    this.dataService.getSelectedTracks = this.projectService
      .getProjectTracks(projectID)
      .subscribe((tks) => {
        this.loadingSelectedTracksProjects = false;
        const listMusica = this.musicService.mountMusicList(
          tks,
          this.tonos,
          false,
          false,
          false,
          false
        );
        this.selectedProjectsTracks = listMusica;
        this.dataService.updateSelectedProjectTracks(
          this.selectedProjectsTracks
        );
      });
  }

  checkIfMusicExistsOnSelectedProject(track) {
    if (!this.loadingSelectedTracksProjects) {
      if (this.selectedProjectsTracks.some((e) => e.id == track.id)) {
        track.isProjectIconEnabeled = true;
        return true;
      } else {
        track.isProjectIconEnabeled = false;
        return false;
      }
    } else {
      track.isProjectIconEnabeled = false;
      return false;
    }
  }

  // OPERATIONS ON PROJECTS
  // novo projecto
  newProject() {
    this.modalOpen = true;
    this.dataService.updateModalOpen(true);
    this.dialogRef = this.NewprojectmodalComponent.open(
      NewprojectmodalComponent,
      { disableClose: true }
    );
    let instance = this.dialogRef.componentInstance;
    instance.project = null;
    this.dialogRef.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      if (result.action != "cancel") {
        let obj = {
          val: true,
        };
        this.dataService.updategetProjectLoading(obj);
        this.projectService.getProjects().subscribe((projects) => {
          let obj = {
            val: false,
          };
          this.dataService.updategetProjectLoading(obj);
          this.projects = projects["Data"];
          let lastModified = this.projects[0];
          this.selectedProject = lastModified;
          this.location.go("./projects/" + this.selectedProject.ProjectsID);
          this.getSelectedProjectsTracks(this.selectedProject);
          this.dataService.updateSelectedProject(lastModified);

          //PROJECTO SELECIONADO
          let project_selected_obj = {
            project: this.selectedProject,
            from: "navbar",
          };
          this.dataService.updateProjectsServiceSERVICE(
            project_selected_obj,
            "new"
          );

          if (this.url != undefined && this.url.includes("/projects/")) {
            this.dataService.updateProjects(this.projects);
            this.dataService.updateProjectsSERVICE({
              projects: this.projects,
              from: "navbar",
            });
          }
        });
      }
      this.dataService.updateModalOpen(false);
    });
  }
 

  // editar projecto
  editProject() {
    if (
      this.selectedProject.ShareRO == null ||
      !this.selectedProject.ShareRO.includes(this.authService.user.id)
    ) {
      this.dataService.updateModalOpen(true);
      this.modalOpen = true;
      this.dialogRef = this.NewprojectmodalComponent.open(
        NewprojectmodalComponent,
        { disableClose: true }
      );
      let instance = this.dialogRef.componentInstance;
      instance.project = this.selectedProject;
      this.dialogRef.afterClosed().subscribe((result) => {
        this.modalOpen = false;
        if (result.action != "cancel") {
          let obj = {
            val: true,
          };
          this.dataService.updategetProjectLoading(obj);
          this.projectService.getProjects().subscribe((projects) => {
            let obj = {
              val: false,
            };
            this.dataService.updategetProjectLoading(obj);
            this.projects = projects["Data"];
            let lastModified = this.projects[0];
            this.selectedProject = lastModified;
            this.location.go("./projects/" + this.selectedProject.ProjectsID);
            this.getSelectedProjectsTracks(this.selectedProject);
            this.dataService.updateSelectedProject(lastModified);

            let project_selected_obj = {
              project: this.selectedProject,
              from: "navbar",
            };
            this.dataService.updateProjectsServiceSERVICE(
              project_selected_obj,
              "edit"
            );

            if (this.url != undefined && this.url.includes("/projects/")) {
              this.dataService.updateProjects(this.projects);
              this.dataService.updateProjectsSERVICE({
                projects: this.projects,
                from: "navbar",
              });
            }
          });
        }
        this.dataService.updateModalOpen(false);
      });
    }
  }

  // apagar projecto
  deleteProject() {
    if (
      this.selectedProject.ShareRO == null ||
      !this.selectedProject.ShareRO.includes(this.authService.user.id)
    ) {
      this.dataService.updateModalOpen(true);
      this.deleteDialogRef = this.DeleteProjectComponent.open(
        DeleteProjectComponent,
        { disableClose: true }
      );
      let instance = this.deleteDialogRef.componentInstance;
      instance.projectID = this.selectedProject.ProjectsID;
      instance.userID = this.authService.user.id;
      this.modalOpen = true;
      this.deleteDialogRef.afterClosed().subscribe((result) => {
        this.modalOpen = false;
        if (result.message == "success") {
          let obj = {
            val: true,
          };
          this.dataService.updategetProjectLoading(obj);
          this.projectService.getProjects().subscribe((projects) => {
            let obj = {
              val: false,
            };
            this.dataService.updategetProjectLoading(obj);
            this.projects = projects["Data"];
            let lastModified = this.projects[0];
            this.selectedProject = lastModified;
            this.getSelectedProjectsTracks(this.selectedProject);
            this.dataService.updateSelectedProject(lastModified);

            let project_selected_obj = {
              project: this.selectedProject,
              from: "navbar",
            };
            this.dataService.updateProjectsServiceSERVICE(
              project_selected_obj,
              "delete"
            );

            if (this.url != undefined && this.url.includes("/projects/")) {
              this.dataService.updateProjects(this.projects);
              this.dataService.updateProjectsSERVICE({
                projects: this.projects,
                from: "navbar",
              });
            }
          });
        }
        this.dataService.updateModalOpen(false);
      });
    }
  }

  // partilhar projecto
  shareProject() {
    this.modalOpen = true;
    this.dataService.updateModalOpen(true);
    this.shareProjectsComponent = this.ShareProjectsComponent.open(
      ShareProjectsComponent,
      { disableClose: true }
    );
    let instance = this.shareProjectsComponent.componentInstance;
    instance.project = this.selectedProject;
    this.shareProjectsComponent.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      this.dataService.updateModalOpen(false);
      this.projectService.getProjects().subscribe((projects) => {
        this.projects = projects["Data"];
        let lastModified = this.projects[0];
        this.selectedProject = lastModified;
        this.getSelectedProjectsTracks(this.selectedProject);
        this.dataService.updateSelectedProject(lastModified);
        let project_selected_obj = {
          project: this.selectedProject,
          from: "navbar",
        };
        this.dataService.updateProjectsServiceSERVICE(project_selected_obj);
        if (this.url != undefined && this.url.includes("/projects/")) {
          this.dataService.updateProjects(this.projects);
          this.dataService.updateProjectsSERVICE({
            projects: this.projects,
            from: "navbar",
          });
        }
      });
    });
  }

  // adicionar um albúm ao projecto
  addAlbumToProject(track, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    let tracksToAdd = [];
    const stringValidate = track.Context1J + "_" + track.Context1K;
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToAdd.push(obj);
    this.projectService
      .validateAlbumExistance(this.selectedProject.ProjectsID, stringValidate)
      .subscribe((data) => {
        const errorList = data.ErrorList;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty("MusicExistsInOtherProjects")) {
            this.projectListExistanceModalComponent =
              this.ProjectListExistanceModalComponent.open(
                ProjectListExistanceModalComponent,
                { disableClose: true }
              );
            let instance =
              this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data["MusicInOtherProjectsList"];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent
              .afterClosed()
              .subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result["action"] == "continue") {
                  this.projectService
                    .addAlbunsToProject(
                      this.selectedProject.ProjectsID,
                      tracksToAdd
                    )
                    .subscribe((data) => {
                      this.justGetProjects();
                    });
                }
              });
          } else if (
            errorList.hasOwnProperty("MusicNotExistsInThisProject") ||
            (errorList.hasOwnProperty("UserNotAllowed") &&
              Object.entries(errorList).length === 1) ||
            Object.entries(errorList).length === 0
          ) {
            this.projectService
              .addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd)
              .subscribe((data) => {
                this.justGetProjects();
              });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      });
  }

  // adicionar vários albúns ao projecto - needs refactor
  addAlbumsToProject() {
    const tracksToAdd = [];
    let stringValidate = "";
    for (let id = 0; id < this.playerHistory.selectedSongsSEARCH.length; id++) {
      const track = this.playerHistory.selectedSongsSEARCH[id];
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
      };
      tracksToAdd.push(obj);
      stringValidate += track.Context1J + "_" + track.Context1K + ",";
    }
    stringValidate = stringValidate.substring(0, stringValidate.length - 1);
    this.projectService
      .validateAlbumExistance(this.selectedProject.ProjectsID, stringValidate)
      .subscribe((data) => {
        const errorList = data.ErrorList;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty("MusicExistsInOtherProjects")) {
            this.projectListExistanceModalComponent =
              this.ProjectListExistanceModalComponent.open(
                ProjectListExistanceModalComponent,
                { disableClose: true }
              );
            let instance =
              this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data["MusicInOtherProjectsList"];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent
              .afterClosed()
              .subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result["action"] == "continue") {
                  this.projectService
                    .addAlbunsToProject(
                      this.selectedProject.ProjectsID,
                      tracksToAdd
                    )
                    .subscribe((data) => {
                      this.justGetProjects();
                    });
                }
              });
          } else if (
            errorList.hasOwnProperty("MusicNotExistsInThisProject") ||
            (errorList.hasOwnProperty("UserNotAllowed") &&
              Object.entries(errorList).length === 1) ||
            Object.entries(errorList).length === 0
          ) {
            this.projectService
              .addAlbunsToProject(this.selectedProject.ProjectsID, tracksToAdd)
              .subscribe((data) => {
                this.justGetProjects();
              });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
      });
  }

  // apagar um albúm do projecto
  deleteAlbumFromProject(track, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    const tracksToDelete = [];
    const obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
    };
    tracksToDelete.push(obj);
    this.projectService
      .deleteAlbunsToProject(this.selectedProject.ProjectsID, tracksToDelete)
      .subscribe((data) => {
        this.justGetProjects();
      });
  }

  // apagar vários albúns do projecto - needs refactor
  deleteAlbumsFromProject() {
    const tracksToDelete = [];
    for (let id = 0; id < this.playerHistory.selectedSongsSEARCH.length; id++) {
      const track = this.playerHistory.selectedSongsSEARCH[id];
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
      };
      tracksToDelete.push(obj);
    }

    this.projectService
      .deleteAlbunsToProject(this.selectedProject.ProjectsID, tracksToDelete)
      .subscribe((data) => {
        this.justGetProjects();
      });
  }

  // adicionar uma track ao projecto selecionado (line-by-line )
  addToProjects(event, track, i): void {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      if (i != null) {
        this.selectTrack(null, i, this.tracks);
        return;
      }
    }
    if (event != undefined && event != null && event.shiftKey) {
      if (i != null) {
        this.checkTracksToSelect(i);
        return;
      }
    }
    if (!track.isProjectIconEnabeled) {
      const normalizedData = {
        ProjectsID: this.selectedProject.ProjectsID,
        Context1J: track.Context1J,
        Context1K: track.Context1K,
        Track: track.track,
        TrackTitle: track.name,
        TrackTime: track.duration,
        Description: "",
      };

      const musicList = track.id;
      const type = this.authService.user.Pref_DownloadFormat;
      const projectId = this.selectedProject.ProjectsID;

      if (this.prevXhrRequest != null) {
        this.prevXhrRequest.unsubscribe();
        this.prevXhrRequest = null;
      }

      this.prevXhrRequest = this.searchService
        .validateDownload(projectId, musicList, type)
        .subscribe((data) => {
          const errorList = data.ErrorList;
          // const url = environment.baseUrl + 'music/download/' + projectId + '/?type=' + type + '&musicList=' + musicList + '&token=' + this.authService.user.token;
          if (errorList != undefined) {
            if (errorList.hasOwnProperty("MusicExistsInOtherProjects")) {
              this.projectListExistanceModalComponent =
                this.ProjectListExistanceModalComponent.open(
                  ProjectListExistanceModalComponent,
                  { disableClose: true }
                );
              let instance =
                this.projectListExistanceModalComponent.componentInstance;
              instance.projectList = data["MusicInOtherProjectsList"];
              this.dataService.updateModalOpen(true);
              this.modalOpen = true;
              this.projectListExistanceModalComponent
                .afterClosed()
                .subscribe((result) => {
                  this.dataService.updateModalOpen(false);
                  this.modalOpen = false;
                  if (result["action"] == "continue") {
                    this.projectService
                      .setTrackToProject(normalizedData)
                      .subscribe((data) => {
                        this.justGetProjects();
                      });
                  }
                });
            } else if (
              errorList.hasOwnProperty("MusicNotExistsInThisProject") ||
              (errorList.hasOwnProperty("UserNotAllowed") &&
                Object.entries(errorList).length === 1) ||
              Object.entries(errorList).length === 0
            ) {
              this.projectService
                .setTrackToProject(normalizedData)
                .subscribe((data) => {
                  this.justGetProjects();
                });
            } else {
              if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
            }
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
          this.prevXhrRequest = null;
        });
    }
  }

  // adicionar uma track a um projecto, pela modal (line-by-line ) -removido temporariamente
  addOneTrackToProject(track) {
    let tracksToAdd = [];

    let obj = {
      Context1J: track.Context1J,
      Context1K: track.Context1K,
      Track: track.track,
      TrackTitle: track.name,
      TrackTime: track.duration,
      Description: track.description,
      isTrackSelected: true,
      metadataID: track.id,
    };
    tracksToAdd.push(obj);

    this.trackOperationsDialog = this.TrackOperationsModalComponent.open(
      TrackOperationsModalComponent,
      { disableClose: true }
    );
    let instance = this.trackOperationsDialog.componentInstance;
    instance.tracksToOperate = tracksToAdd;
    instance.projects = this.projects;
    instance.projectID = null;
    instance.mode = "playlists";
    this.dataService.updateModalOpen(true);
    this.modalOpen = true;
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      this.dataService.updateModalOpen(false);
      this.modalOpen = false;
      let success = false;
      this.ngOnInit();
      this.justGetProjects();
    });
  }

  // adicionar várias tracks ao projecto selecionado (results bar)
  addmultipleTracksToSelectedProject(event) {
    event.stopPropagation();
    let musicList = "";
    const tracksToAdd = [];
    for (
      let idx = 0;
      idx < this.playerHistory.selectedSongsSEARCH.length;
      idx++
    ) {
      const obj = {
        Context1J: this.playerHistory.selectedSongsSEARCH[idx].Context1J,
        Context1K: this.playerHistory.selectedSongsSEARCH[idx].Context1K,
        Track: this.playerHistory.selectedSongsSEARCH[idx].track,
        TrackTitle: this.playerHistory.selectedSongsSEARCH[idx].name,
        TrackTime: this.playerHistory.selectedSongsSEARCH[idx].duration,
        Description: this.playerHistory.selectedSongsSEARCH[idx].description,
        isTrackSelected: true,
        metadataID: this.playerHistory.selectedSongsSEARCH[idx].id,
      };
      tracksToAdd.push(obj);
      musicList += this.playerHistory.selectedSongsSEARCH[idx].id + ",";
    }
    musicList = musicList.substring(0, musicList.length - 1);
    const type = this.authService.user.Pref_DownloadFormat;
    const projectId = this.selectedProject.ProjectsID;

    if (this.prevXhrRequest != null) {
      this.prevXhrRequest.unsubscribe();
      this.prevXhrRequest = null;
    }

    this.prevXhrRequest = this.searchService
      .validateDownload(projectId, musicList, type)
      .subscribe((data) => {
        const errorList = data.ErrorList;
        const url =
          environment.baseUrl +
          "music/download/" +
          projectId +
          "/?type=" +
          type +
          "&musicList=" +
          musicList +
          "&token=" +
          this.authService.user.token;
        if (errorList != undefined) {
          if (errorList.hasOwnProperty("MusicExistsInOtherProjects")) {
            this.projectListExistanceModalComponent =
              this.ProjectListExistanceModalComponent.open(
                ProjectListExistanceModalComponent,
                { disableClose: true }
              );
            let instance =
              this.projectListExistanceModalComponent.componentInstance;
            instance.projectList = data["MusicInOtherProjectsList"];
            this.dataService.updateModalOpen(true);
            this.modalOpen = true;
            this.projectListExistanceModalComponent
              .afterClosed()
              .subscribe((result) => {
                this.dataService.updateModalOpen(false);
                this.modalOpen = false;
                if (result["action"] == "continue") {
                  this.projectService
                    .setMultipleTracksToProject(
                      tracksToAdd,
                      this.selectedProject.ProjectsID
                    )
                    .subscribe((result) => {
                      this.justGetProjects();
                    });
                }
              });
          } else if (
            errorList.hasOwnProperty("MusicNotExistsInThisProject") ||
            (errorList.hasOwnProperty("UserNotAllowed") &&
              Object.entries(errorList).length === 1) ||
            Object.entries(errorList).length === 0
          ) {
            this.projectService
              .setMultipleTracksToProject(
                tracksToAdd,
                this.selectedProject.ProjectsID
              )
              .subscribe((result) => {
                this.justGetProjects();
              });
          } else {
            if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
          }
        } else {
          if (errorList.ProjectOwner.indexOf('Permission denied')!=-1){alert(this.i18n.getTranslationsBD('ImpossibleNoPermission'));} else {alert(this.i18n.getTranslationsBD("impossibletoadd"));}
        }
        this.prevXhrRequest = null;
      });
  }

  // adicionar várias tracks a um projecto, pela modal (results bar) -removido temporariamente
  addmultipleTracksToProject() {
    this.dataService.updateModalOpen(true);
    let tracksToAdd = [];
    for (let id = 0; id < this.tracks.length; id++) {
      if (this.tracks[id].isTrackSelected) {
        let obj = {
          Context1J: this.tracks[id].Context1J,
          Context1K: this.tracks[id].Context1K,
          Track: this.tracks[id].track,
          TrackTitle: this.tracks[id].name,
          TrackTime: this.tracks[id].duration,
          Description: this.tracks[id].description,
          isTrackSelected: true,
          metadataID: this.tracks[id].id,
        };
        tracksToAdd.push(obj);
      }
    }

    this.trackOperationsDialog = this.TrackOperationsModalComponent.open(
      TrackOperationsModalComponent,
      { disableClose: true }
    );
    let instance = this.trackOperationsDialog.componentInstance;
    instance.tracksToOperate = tracksToAdd;
    instance.projects = this.projects;
    instance.projectID = null;
    instance.mode = "playlists";
    this.modalOpen = true;
    this.trackOperationsDialog.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      let success = false;
      this.ngOnInit();
      this.justGetProjects();
      this.dataService.updateModalOpen(false);
    });
  }

  // remover tracks do projecto selecionado
  removeTracksFromProject(track, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    let tracksToDelete = [];
    if (track != null) {
      const obj = {
        Context1J: track.Context1J,
        Context1K: track.Context1K,
        Track: track.Track,
        MetaDataID: track.id,
      };
      tracksToDelete.push(obj);
    } else {
      for (let i = 0; i < this.playerHistory.selectedSongsSEARCH.length; i++) {
        const obj = {
          Context1J: this.playerHistory.selectedSongsSEARCH[i].Context1J,
          Context1K: this.playerHistory.selectedSongsSEARCH[i].Context1K,
          Track: this.playerHistory.selectedSongsSEARCH[i]["Track"],
          MetaDataID: this.playerHistory.selectedSongsSEARCH[i].id,
        };
        tracksToDelete.push(obj);
      }
    }
    this.modalOpen = true;
    this.deleteTrackDialog = this.DeleteTrackFromProjectComponent.open(
      DeleteTrackFromProjectComponent,
      { disableClose: true }
    );
    let instance = this.deleteTrackDialog.componentInstance;
    instance.projectID = this.selectedProject.ProjectsID;
    instance.tracksToDelete = tracksToDelete;
    this.deleteTrackDialog.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      this.justGetProjects();
    });
  }

  // link do menu para página dos projectos
  gotoPlaylists(event) {
    if (event != null) {
      event.stopPropagation();
    }
    const lastURL = this.dataService.getLastURL();
    if (lastURL.includes("playlists")) {
      this.saveState.setLastFindPlaylists(null);
      this.saveState.removelastSearchPlaylists();
    }
    this.router.navigate(["./playlists"]);
  }

  gotoCatalogs(event) {
    if (event != null) {
      event.stopPropagation();
    }
    const lastURL = this.dataService.getLastURL();
    if (lastURL.includes("catalogos")) {
      let temp_catalogs = this.dataService.getInitialCatalogsGroups();
      temp_catalogs.forEach((temp_catalog) => {
        if (temp_catalog.hasOwnProperty("Context1J")) {
          temp_catalog.IsVisible = true;
        }
      });
      this.dataService.setInitialCatalogsGroups(temp_catalogs);
      this.saveState.setLastFindCatalogsTab(null);
      this.saveState.removelastSearchCatalogs();
      this.dataService.updateSelectedAlbum(null);
    }
    this.router.navigate(["./catalogos"]);
  }

  // link do menú para página dos projectos
  gotoProjects(event) {
    if (event != null) {
      event.stopPropagation();
    }
    this.isProjectOptions = false;
    this.isProjects = false;
    if (this.selectedProject != undefined) {
      if (this.url != undefined && this.url.includes("projects")) {
        this.desligaMusica("./projects/" + this.selectedProject.ProjectsID);
      } else {
        this.router.navigate(["./projects/" + this.selectedProject.ProjectsID]);
      }
    }
  }

  // click na DDL de projectos, estando na página dos projectos
  setProject(project, where) {
    this.selectedProject = project;
    this.getSelectedProjectsTracks(this.selectedProject);
    this.dataService.updateSelectedProject(project);
    let project_selected_obj = {
      project: this.selectedProject,
      from: "navbar",
    };
    this.dataService.updateProjectsServiceSERVICE(project_selected_obj);
    if (where == "in") {
      this.location.go("./projects/" + this.selectedProject.ProjectsID);
    }
    $("#project-titles").text(this.selectedProject.Titles);
  }

  // send project by e-mail
  sendProjectEmail() {
    this.dataService.updateModalOpen(true);
    this.sendProjectEmailComponent = this.SendProjectEmailComponent.open(
      SendProjectEmailComponent,
      { disableClose: true }
    );
    let instance = this.sendProjectEmailComponent.componentInstance;
    instance.project = this.selectedProject;
    instance.user = this.authService.user;
    this.modalOpen = true;
    this.sendProjectEmailComponent.afterClosed().subscribe((result) => {
      this.modalOpen = false;
      this.dataService.updateModalOpen(false);
      if (result.action == "result") {
        const nome = result.nome;
        alert(
          this.i18n.getTranslationsBD("your-email-has-been-sent-to") +
            " " +
            nome
        );
      }
    });
  }

  closeTabs(): void {
    if (this.countClicksOutTabs > 1) {
      this.tabOpen = null;
      this.countClicksOutTabs = 1;
    }

    if (this.tabOpen != null) {
      this.countClicksOutTabs++;
    }
  }

  genresNameTab(letter): String {
    return this.i18n.nameByLetterMarterCategory(
      this.i18n.language.toUpperCase(),
      letter
    );
  }

  openTab(tab) {
    if (tab == this.tabOpen) {
      this.tabOpen = null;
    } else {
      this.tabOpen = tab;
      if (this.tabOpen == "tempos") {
        this.addValueToBPMandDuration();
      }
      this.countClicksOutTabs = 1;
      this.genresSearchString = "";
      this.moodsSearchString = "";
      this.instrumentsSearchString = "";
      this.temposSearchString = "";
      this.periodsSearchString = "";
      this.keysSearchString = "";
      this.cataloguesSearchString = "";
    }
  }

  genresOverHasElements(letter: string) {
    if (this.genres === undefined) {
      return false;
    }
    if (this.genres[letter] === undefined) {
      return false;
    }
    if (this.genres[letter].length === 0) {
      return false;
    }
    if (this.isSearchBlocked) {
      return false;
    }
    return true;
  }

  checkPass() {
    const element = document.getElementById("password") as HTMLInputElement;
    const value = element.value;
    if (value.length === 0) {
      return "OpenSansFont";
    } else {
      return "dottedFont";
    }
  }

  closeUserOptions() {
    if (this.countClicksOutUserOptions > 1) {
      this.isUserOptions = false;
      this.countClicksOutUserOptions = 1;
    }

    if (this.isUserOptions) {
      this.countClicksOutUserOptions++;
    }
  }

  closeProjectOptions() {
    if (this.countClicksOutProjectOptions > 1) {
      this.isProjectOptions = false;
      this.countClicksOutProjectOptions = 1;
    }

    if (this.isProjectOptions) {
      this.countClicksOutProjectOptions++;
    }
  }

  stopPropagation(event, i) {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      if (i != null) {
        this.selectTrack(null, i, this.tracks);
        return;
      }
    }
    if (event != undefined && event != null && event.shiftKey) {
      if (i != null) {
        this.checkTracksToSelect(i);
        return;
      }
    }
  }

  closeProjects() {
    if (this.countClicksOutProjects > 1) {
      this.isProjects = false;
      this.countClicksOutProjects = 1;
    }

    if (this.isProjects) {
      this.countClicksOutProjects++;
    }
  }

  closeUserBubble() {
    this.isUserRememberBubble = false;
    if ($(".memorize-user").is(":hidden")) {
      return;
    }
    this.isUserBubble = false;
  }

  closePasswordBubble() {
    if ($(".memorize-password").is(":hidden")) {
      return;
    }
    this.isPasswordBubble = false;
  }

  dynamicSort(property) {
    let sortOrder = -1;

    if (this.sortReverse) {
      sortOrder = 1;
    }

    return function (a, b) {
      let result;
      if (typeof a[property] === "string") {
        result = a[property].localeCompare(b[property]);
      } else {
        result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      }
      return result * sortOrder;
    };
  }

  sortProjects(property) {
    this.sortType = property;
    this.sortReverse = !this.sortReverse;
    this.projects.sort(this.dynamicSort(property));
  }

  checkSubgenreState(i, letter = "") {
    const subgenreBtn = ".subgenre-btn-" + letter + i;
    const collapseEl = "#collapse-genre-" + letter + i;

    $(collapseEl).on("hide.bs.collapse", function () {
      $(subgenreBtn).text("+");
    });

    $(collapseEl).on("show.bs.collapse", function () {
      $(subgenreBtn).text("-");
    });
  }

  selectSearchDropdownItem(item) {
    this.searchDropdownItem = item;
    if (item.value == "MyRatings") {
      this.chips = [];
      // $("#row-one").val('');
      this.inputTypeSearch1 = "number";
      this.inputSizeSearch1 = 1;
    } else {
      this.inputTypeSearch1 = "text";
      this.inputSizeSearch1 = 1000;
    }
  }

  selectMoreSearchDropdownItemRight(item) {
    this.moreSearchDropdownItemRight = item;
    if (item.value == "MyRatings") {
      this.moreChips = [];
      // $("#row-two").val('');
      this.inputTypeSearch2 = "number";
      this.inputSizeSearch2 = 1;
    } else {
      this.inputTypeSearch2 = "text";
      this.inputSizeSearch2 = 1000;
    }
  }

  selectMoreSearchDropdownItemLeft(item) {
    this.moreSearchDropdownItemLeft = item;
  }

  selectSecondMoreSearchDropdownItemRight(item) {
    this.secondMoreSearchDropdownItemRight = item;
    if (item.value == "MyRatings") {
      this.secondMoreChips = [];
      // $("#row-three").val('');
      this.inputTypeSearch3 = "number";
      this.inputSizeSearch3 = 1;
    } else {
      this.inputTypeSearch3 = "text";
      this.inputSizeSearch3 = 1000;
    }
  }

  selectSecondMoreSearchDropdownItemLeft(item) {
    this.secondMoreSearchDropdownItemLeft = item;
  }

  addRow(): void {
    if (this.isAdd) {
      this.isSecondAdd = true;
      this.lastFocusInput = "row-three";
    } else {
      this.isAdd = true;
      this.lastFocusInput = "row-two";
    }
  }

  removeRow(): void {
    if (this.isSecondAdd) {
      this.isSecondAdd = false;
      this.secondMoreChips = [];
      const rowTwoElement: HTMLInputElement = document.getElementById(
        "row-two"
      ) as HTMLInputElement;
      rowTwoElement.focus();
      this.lastFocusInput = "row-two";
    } else {
      this.isAdd = false;
      this.moreChips = [];
      const rowOneElement: HTMLInputElement = document.getElementById(
        "row-one"
      ) as HTMLInputElement;
      rowOneElement.focus();
      this.lastFocusInput = "row-one";
    }
  }

  addTempos($event, track) {
    $event.stopPropagation();
    if (track.tempos[this.i18n.language.toUpperCase()] !== "") {
      if (
        ["Very Slow", "Slow", "Medium", "Fast", "Very Fast", "", null].indexOf(
          track.tempos["EN"]
        ) > -1
      ) {
        this.addChip(
          {
            name: track.tempos[this.i18n.language.toLocaleUpperCase()],
            context: track.Context1E,
          },
          null
        );
        this.search();
      } else {
        this.addChip({ name: "Varied", context: "E543" }, null);
        this.search();
      }
    }
  }

  addMoreTempos($event, track) {
    $event.stopPropagation();
    if (track.tempos[this.i18n.language.toUpperCase()] !== "") {
      if (
        ["Very Slow", "Slow", "Medium", "Fast", "Very Fast", "", null].indexOf(
          track.tempos["EN"]
        ) > -1
      ) {
        this.addMoreChip(
          {
            name: track.tempos[this.i18n.language.toLocaleUpperCase()],
            context: track.Context1E,
          },
          null
        );
        this.search();
      } else {
        this.addMoreChip({ name: "Varied", context: "E543" }, null);
        this.search();
      }
    }
  }

  addSecondMoreTempos($event, track) {
    $event.stopPropagation();
    if (track.tempos[this.i18n.language.toUpperCase()] !== "") {
      if (
        ["Very Slow", "Slow", "Medium", "Fast", "Very Fast", "", null].indexOf(
          track.tempos["EN"]
        ) > -1
      ) {
        this.addSecondMoreChip(
          {
            name: track.tempos[this.i18n.language.toLocaleUpperCase()],
            context: track.Context1E,
          },
          null
        );
        this.search();
      } else {
        this.addSecondMoreChip({ name: "Varied", context: "E543" }, null);
        this.search();
      }
    }
  }

  addTonos($event, track) {
    $event.stopPropagation();
    if (track.Context1Q != null && track.Context1Q != "") {
      this.addChip({ name: track.tono, context: track.Context1Q }, null);
    }
  }

  addMoreTonos($event, track) {
    $event.stopPropagation();
    if (track.Context1Q != null && track.Context1Q != "") {
      this.addMoreChip({ name: track.tono, context: track.Context1Q }, null);
    }
  }

  addSecondMoreTonos($event, track) {
    $event.stopPropagation();
    if (track.Context1Q != null && track.Context1Q != "") {
      this.addSecondMoreChip(
        { name: track.tono, context: track.Context1Q },
        null
      );
    }
  }

  addAuthors(auths, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    const authors = auths[0];
    if (authors.indexOf(";") > -1) {
      const autoresArray = authors.split(";");
      autoresArray.forEach((autor, id) => {
        if (id > 0) {
          const aut = autor.substr(1);
          this.addChip(aut.replace(/ /g, "_"), null);
        } else {
          this.addChip(autor.replace(/ /g, "_"), null);
        }
      });
      this.search();
    } else {
      this.addChip(authors.replace(/ /g, "_"), null);
      this.search();
    }
  }

  addChip(chip, position, loadcategories = true): void {
    if (typeof chip === "string") {
      if (chip.includes(" ")) {
        const chipstoAdd = chip.split(" ");
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty("name")) {
      _chip = chip;
      chip = chip.name;
    }
    if (chip != null) {
      chip = chip.trim();
      if (!chip || this.chips.length > 5) {
        return;
      }
      if (chip.charAt(0) === "-") {
        this.isChipPlus = false;
        chip = chip.substr(1);
      } else {
        this.isChipPlus = true;
      }

      if (!this.isLink(chip)) {
        chip = chip.replace(new RegExp("_", "g"), " ");
      }

      const result = {
        name: chip,
        isChipPlus: this.isChipPlus,
      };

      if (_chip !== null && _chip.hasOwnProperty("name")) {
        result["context"] = _chip.context;
      }
      if (position == null) {
        this.chips.push(result);
      } else {
        this.chips.splice(position, 0, result);
      }

      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: "secondary-input" + id, value: "" });
      });
    }
  }

  addMoreChip(chip, position): void {
    if (typeof chip === "string") {
      if (chip.includes(" ")) {
        const chipstoAdd = chip.split(" ");
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addMoreChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty("name")) {
      _chip = chip;
      chip = chip.name;
    }
    chip = chip.trim();
    if (chip != null) {
      if (!chip || this.moreChips.length > 5) {
        return;
      }
      if (chip.charAt(0) === "-") {
        this.isChipPlus = false;
        chip = chip.substr(1);
      } else {
        this.isChipPlus = true;
      }

      chip = chip.replace(new RegExp("_", "g"), " ");

      const result = {
        name: chip,
        isChipPlus: this.isChipPlus,
      };

      if (_chip !== null && _chip.hasOwnProperty("name")) {
        result["context"] = _chip.context;
      }

      if (position == null) {
        this.moreChips.push(result);
      } else {
        this.moreChips.splice(position, 0, result);
      }

      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({
          input: "secondary-input-more" + id,
          value: "",
        });
      });
    }
  }

  addSecondMoreChip(chip, position): void {
    if (typeof chip === "string") {
      if (chip.includes(" ")) {
        const chipstoAdd = chip.split(" ");
        const _self = this;
        chipstoAdd.map(function (x) {
          _self.addSecondMoreChip(x, null);
        });
        return;
      }
    }
    let _chip = null;
    if (chip.hasOwnProperty("name")) {
      _chip = chip;
      chip = chip.name;
    }
    if (chip != null) {
      chip = chip.trim();
      if (!chip || this.secondMoreChips.length > 5) {
        return;
      }
      if (chip.charAt(0) === "-") {
        this.isChipPlus = false;
        chip = chip.substr(1);
      } else {
        this.isChipPlus = true;
      }
      chip = chip.replace(new RegExp("_", "g"), " ");
      const result = {
        name: chip,
        isChipPlus: this.isChipPlus,
      };
      if (_chip !== null && _chip.hasOwnProperty("name")) {
        result["context"] = _chip.context;
      }
      if (position == null) {
        this.secondMoreChips.push(result);
      } else {
        this.secondMoreChips.splice(position, 0, result);
      }
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({
          input: "secondary-input-second-more" + id,
          value: "",
        });
      });
    }
  }

  checkWhereToAddTonos($event, track) {
    if (this.lastFocusInput == "row-one") {
      const contextQ = track.Context1Q;
      let alreadyExists = false;
      this.chips.forEach((chip) => {
        if (chip.context == contextQ) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addTonos($event, track);
      } else {
        $event.stopPropagation();
      }
    } else if (this.lastFocusInput == "row-two") {
      const contextQ = track.Context1Q;
      let alreadyExists = false;
      this.moreChips.forEach((chip) => {
        if (chip.context == contextQ) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addMoreTonos($event, track);
      } else {
        $event.stopPropagation();
      }
    } else if (this.lastFocusInput == "row-three") {
      const contextQ = track.Context1Q;
      let alreadyExists = false;
      this.secondMoreChips.forEach((chip) => {
        if (chip.context == contextQ) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addSecondMoreTonos($event, track);
      } else {
        $event.stopPropagation();
      }
    }
  }

  checkWhereToAddTempos($event, track, i) {
    if ($event != undefined && $event != null && $event.ctrlKey) {
      $event.stopPropagation();
      if (i != null) {
        this.selectTrack(null, i, this.tracks);
        return;
      }
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      if (i != null) {
        this.checkTracksToSelect(i);
        return;
      }
    }
    if (this.lastFocusInput == "row-one") {
      const contextE = track.Context1E;
      let alreadyExists = false;
      this.chips.forEach((chip) => {
        if (chip.context == contextE) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addTempos($event, track);
      } else {
        $event.stopPropagation();
      }
    } else if (this.lastFocusInput == "row-two") {
      const contextE = track.Context1E;
      let alreadyExists = false;
      this.moreChips.forEach((chip) => {
        if (chip.context == contextE) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addMoreTempos($event, track);
      } else {
        $event.stopPropagation();
      }
    } else if (this.lastFocusInput == "row-three") {
      const contextE = track.Context1E;
      let alreadyExists = false;
      this.secondMoreChips.forEach((chip) => {
        if (chip.context == contextE) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addSecondMoreTempos($event, track);
      } else {
        $event.stopPropagation();
      }
    }
  }

  checkWhereToAddChip(genre, position) {
    if (this.lastFocusInput == "row-one") {
      const context = genre.context;
      let contextArray = context.split(",");
      if (
        contextArray.length > 1 ||
        (this.genres["J"] != undefined &&
          this.genres["J"] != undefined &&
          this.genres["J"].length - 1 == contextArray.length &&
          this.isNewReleasesChipActive &&
          !this.isPreferedChipActive) ||
        (this.genres["J"] != undefined &&
          this.genres["J"].length - 1 == contextArray.length &&
          this.isPreferedChipActive &&
          !this.isNewReleasesChipActive) ||
        (this.genres["J"] != undefined &&
          this.genres["J"].length - 2 == contextArray.length &&
          this.isPreferedChipActive &&
          this.isNewReleasesChipActive)
      ) {
        // this.isSearchBlocked = true;
      }
      let alreadyExists = false;
      this.chips.forEach((chip) => {
        if (chip.context == context) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addChip(genre, position);
      }
    } else if (this.lastFocusInput == "row-two") {
      const context = genre.context;
      let alreadyExists = false;
      this.chips.forEach((chip) => {
        if (chip.context == context) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addMoreChip(genre, position);
      }
    } else if (this.lastFocusInput == "row-three") {
      const context = genre.context;
      let alreadyExists = false;
      this.chips.forEach((chip) => {
        if (chip.context == context) {
          alreadyExists = true;
          return;
        }
      });
      if (!alreadyExists) {
        this.addSecondMoreChip(genre, position);
      }
    }
  }

  checkWhereToAddBPMMin() {
    let value = $("#min-BPM").val();
    if (value.trim() == "") {
      value = null;
      this.minBPMone = null;
      this.minBPMtwo = null;
      this.minBPMthree = null;
    }
    if (this.lastFocusInput == "row-one") {
      this.minBPMone = value;
    } else if (this.lastFocusInput == "row-two") {
      this.minBPMtwo = value;
    } else if (this.lastFocusInput == "row-three") {
      this.minBPMthree = value;
    }
    $("#min-BPM").val(value);
  }

  checkWhereToAddBPMMax() {
    let value = $("#max-BPM").val();
    if (value.trim() == "") {
      value = null;
      this.maxBPMone = null;
      this.maxBPMtwo = null;
      this.maxBPMthree = null;
    }
    if (this.lastFocusInput == "row-one") {
      this.maxBPMone = value;
    } else if (this.lastFocusInput == "row-two") {
      this.maxBPMtwo = value;
    } else if (this.lastFocusInput == "row-three") {
      this.maxBPMthree = value;
    }
    $("#max-BPM").val(value);
  }

  checkWhereToAddDURMin() {
    let value = $("#min-DUR").val().replace(/^0+/, "");
    if (value.trim() == "") {
      // value = null;
      value = "";
      this.minDURone = null;
      this.minDURtwo = null;
      this.minDURthree = null;
      return;
    }
    if (!value.includes(":")) {
      if (value < 60) {
        if (value < 10) {
          value = "0" + value;
        }
        value = "00:" + value;
      } else if (value >= 100) {
        let secs = value.slice(-2);
        let mins = value.slice(0, -2);
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 60) {
          value = mins + ":" + secs;
        } else {
          alert("Tempo Inválido");
          value = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        value = "00:00";
        return;
      }
    }
    if (value.trim() == "") {
      value = null;
    }
    if (this.lastFocusInput == "row-one") {
      this.minDURone = value;
    } else if (this.lastFocusInput == "row-two") {
      this.minDURtwo = value;
    } else if (this.lastFocusInput == "row-three") {
      this.minDURthree = value;
    }
    $("#min-DUR").val(value);
  }

  checkWhereToAddDURMax() {
    let value = $("#max-DUR").val().replace(/^0+/, "");
    if (value.trim() == "") {
      // value = null;
      this.maxDURone = null;
      this.maxDURtwo = null;
      this.maxDURthree = null;
      value = "";
      return;
    }
    if (!value.includes(":")) {
      if (value < 60) {
        if (value < 10) {
          value = "0" + value;
        }
        value = "00:" + value;
      } else if (value >= 100) {
        let secs = value.slice(-2);
        let mins = value.slice(0, -2);
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (secs < 60) {
          value = mins + ":" + secs;
        } else {
          alert("Tempo Inválido");
          value = "00:00";
          return;
        }
      } else {
        alert("Tempo Inválido");
        value = "00:00";
        return;
      }
    }
    // if(value.trim() == ""){
    //     // value = null;
    //     value = "";
    //     return;
    // }
    if (this.lastFocusInput == "row-one") {
      this.maxDURone = value;
    } else if (this.lastFocusInput == "row-two") {
      this.maxDURtwo = value;
    } else if (this.lastFocusInput == "row-three") {
      this.maxDURthree = value;
    }
    $("#max-DUR").val(value);
  }

  addValueToBPMandDuration() {
    if (this.tabOpen == "tempos") {
      if (this.lastFocusInput == "row-one") {
        if (this.minBPMone != null) {
          setTimeout(() => {
            $("#min-BPM").val(this.minBPMone);
          }, 75);
        }
        if (this.maxBPMone != null) {
          setTimeout(() => {
            $("#max-BPM").val(this.maxBPMone);
          }, 75);
        }
        if (this.minDURone != null) {
          setTimeout(() => {
            $("#min-DUR").val(this.minDURone);
          }, 75);
        }
        if (this.maxDURone != null) {
          setTimeout(() => {
            $("#max-DUR").val(this.maxDURone);
          }, 75);
        }
      } else if (this.lastFocusInput == "row-two") {
        if (this.minBPMtwo != null) {
          setTimeout(() => {
            $("#min-BPM").val(this.minBPMtwo);
          }, 75);
        }
        if (this.maxBPMtwo != null) {
          setTimeout(() => {
            $("#max-BPM").val(this.maxBPMtwo);
          }, 75);
        }
        if (this.minDURtwo != null) {
          setTimeout(() => {
            $("#min-DUR").val(this.minDURtwo);
          }, 75);
        }
        if (this.maxDURtwo != null) {
          setTimeout(() => {
            $("#max-DUR").val(this.maxDURtwo);
          }, 75);
        }
      } else if (this.lastFocusInput == "row-three") {
        if (this.minBPMthree != null) {
          setTimeout(() => {
            $("#min-BPM").val(this.minBPMthree);
          }, 75);
        }
        if (this.maxBPMthree != null) {
          setTimeout(() => {
            $("#max-BPM").val(this.maxBPMthree);
          }, 75);
        }
        if (this.minDURthree != null) {
          setTimeout(() => {
            $("#min-DUR").val(this.minDURthree);
          }, 75);
        }
        if (this.maxDURthree != null) {
          setTimeout(() => {
            $("#max-DUR").val(this.maxDURthree);
          }, 75);
        }
      }
    }
  }

  checkIfFileChip(chip) {
    if (chip.hasOwnProperty("isFile") || this.isLink(chip.name)) {
      let fileToDelete = null;
      for (
        let index = this.dataService.getFiles().length - 1;
        index >= 0;
        index--
      ) {
        const file = this.dataService.getFiles()[index];
        if (chip.entireName == file.name) {
          this.dataService.getFiles().splice(index, 1);
          break;
        }
      }
    }
    this.destroyPlayerFiles();
    this.isSearchBlocked = false;
  }

  checkIfLinkChip(chip) {
    if (chip.hasOwnProperty("linkSimilar")) {
      this.dataService.linkIsSetted(false);
    }
    this.isSearchBlocked = false;
  }

  deleteChip(chip): void {
    let contextChip = chip.context;
    if (contextChip != undefined && contextChip != null && contextChip != "") {
      let contextChipArray = contextChip.split(",");
      if (
        contextChipArray.length > 1 ||
        (this.genres["J"].length - 1 == contextChipArray.length &&
          this.isNewReleasesChipActive &&
          !this.isPreferedChipActive) ||
        (this.genres["J"].length - 1 == contextChipArray.length &&
          this.isPreferedChipActive &&
          !this.isNewReleasesChipActive) ||
        (this.genres["J"].length - 2 == contextChipArray.length &&
          this.isPreferedChipActive &&
          this.isNewReleasesChipActive)
      ) {
        this.isSearchBlocked = false;
      }
    }
    this.checkIfFileChip(chip);
    this.checkIfLinkChip(chip);
    this.chips = this.chips.filter((c) => c !== chip);
    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      // if (id > 0) {
      this.secondaryInputs.push({ input: "secondary-input" + id, value: "" });
      // }
    });
    if (this.chips.length >= 1) {
      this.search();
    }
    // if (this.chips.length === 0) {
    //   this.searchClean();
    //   this.saveState.removelastSearch();
    //   this.tracks = [];
    //   this.getPlaceholder();
    // }
  }

  deleteMoreChip(chip): void {
    this.moreChips = this.moreChips.filter((m) => m !== chip);
    this.secondaryInputsMore = [];
    this.moreChips.forEach((chip, id) => {
      this.secondaryInputsMore.push({
        input: "secondary-input-more" + id,
        value: "",
      });
    });
  }

  deleteSecondMoreChip(chip): void {
    this.secondMoreChips = this.secondMoreChips.filter((m) => m !== chip);
    this.secondaryInputsSecondMore = [];
    this.secondMoreChips.forEach((chip, id) => {
      this.secondaryInputsSecondMore.push({
        input: "secondary-input-second-more" + id,
        value: "",
      });
    });
  }

  searchClean(): void {
    //stopping the endpoint request - not working
    if (this.findMusicaSubscribeCansel != null) {
      this.findMusicaSubscribeCansel.unsubscribe();
      this.findMusicaSubscribeCansel = null;
    }
    $("html, body").animate({ scrollTop: 0 }, "slow");
    this.loadingTracks = false;
    this.isSearchBlocked = false;
    this.chips = [];
    this.moreChips = [];
    this.secondMoreChips = [];
    $("#row-one").val("");
    $("#row-two").val("");
    $("#row-three").val("");
    this.inputTypeSearch1 = "text";
    this.inputTypeSearch2 = "text";
    this.inputTypeSearch3 = "text";

    $("#file-input").val("");

    this.isAddRemoveLine = false;
    this.isAdd = false;
    this.isSecondAdd = false;

    this.searchDropdownItem = this.searchDropdownItems[0];
    this.moreSearchDropdownItemRight = this.moreSearchDropdownItemsRight[0];
    this.moreSearchDropdownItemLeft = this.moreSearchDropdownItemsLeft[0];
    this.secondMoreSearchDropdownItemRight =
      this.secondMoreSearchDropdownItemsRight[0];
    this.secondMoreSearchDropdownItemLeft =
      this.secondMoreSearchDropdownItemsLeft[0];

    if (this.inputEl !== undefined) {
      this.inputEl.nativeElement.focus();
    }
    this.isCatalogSelected = false;
    this.isResult = false;
    this.isCatalogFilter = false;
    this.isCatalogSelected = false;
    this.isAlbumSelected = false;
    this.dataService.updateIsCatalogSelected(this.isCatalogSelected);
    this.dataService.updateIsResult(this.isResult);
    this.dataService.updateIsAlbumSelected(this.isAlbumSelected);
    // this.clearTracks();
    this.saveState.removelastSearch();
    this.tracks = [];

    let tempGenres = this.saveState.getInitialGenres();
    if (tempGenres != undefined && tempGenres.length > 0) {
      this.genres = tempGenres;
      const lang: any = this.i18n.language.toUpperCase();
      this.genres["A"] = this.getGenresByLetter(this.genres[lang].A, "A");
      this.genres["C"] = this.getGenresByLetter(this.genres[lang].C, "C");
      this.genres["D"] = this.getGenresByLetter(this.genres[lang].D, "D");
      this.genres["E"] = this.getGenresByLetter(this.genres[lang].E, "E");
      this.genres["F"] = this.getGenresByLetter(this.genres[lang].F, "F");
      this.genres["Q"] = this.getGenresByLetter(this.genres[lang].Q, "Q");
      this.genres["J"] = this.getGenresByLetter(this.genres[lang].J, "J");
      for (let type in this.genres) {
        for (let genre in this.genres[type]) {
          this.genres[type][genre].open = false;
        }
      }
      this.setTabCols();
    } else {
      this.getGenres();
    }
    $("body").css("background", this.authService.user.Pref_BodyBgColor);
    if (this.selectedProject == undefined) {
      this.getProjects();
    }
    this.getUserDownlaodFormats();
    this.destroyPlayerFiles();
    this.dataService.linkIsSetted(false);
    $("#min-BPM").val("");
    $("#max-BPM").val("");
    $("#min-DUR").val("");
    $("#max-DUR").val("");
    this.dataService.setLastSearchObj(null);
    this.minBPMone =
      this.minBPMone =
      this.maxBPMone =
      this.minBPMtwo =
      this.maxBPMtwo =
      this.minBPMthree =
      this.maxBPMthree =
      this.minDURone =
      this.maxDURone =
      this.minDURtwo =
      this.maxDURtwo =
      this.minDURthree =
      this.maxDURthree =
        null;
  }

  getUserDownlaodFormats() {
    if (this.dataService.getDownloadFormats() == undefined) {
      this.userService
        .getPreferedDownload(this.authService.user.id)
        .subscribe((downloads) => {
          this.dataService.updateDownloadFormats(downloads);
          this.downloadFormats = this.dataService.getDownloadFormats();
          this.downloadFormats.forEach((format) => {
            if (
              format.DownloadFormat == this.authService.user.Pref_DownloadFormat
            ) {
              this.prefDownloadLabel = format.DownloadFormatEN;
              return;
            }
          });
        });
    }
  }

  inputFocus(): void {
    if (
      this.userEl &&
      !this.authService.isLoggedIn &&
      this.router.url === "/"
    ) {
      this.userEl.nativeElement.focus();
    }
  }

  inputSearchFocus(): void {
    this.inputEl.nativeElement.focus();
  }

  toggleProjectsOptions(event) {
    event.stopPropagation();
    this.isProjectOptions = !this.isProjectOptions;
    this.isProjects = false;
    this.countClicksOutProjectOptions = 1;
  }

  toggleProjects(event) {
    event.stopPropagation();
    this.isProjects = !this.isProjects;
    this.isProjectOptions = false;
    this.countClicksOutProjects = 1;
  }

  toggleLogged(): void {
    this.isProjectOptions = false;
    this.isProjects = false;
    if (!this.authService.isLoggedIn) {
      if (this.model.username === "") {
        this.backToUser();
        this.isUserRememberBubble = true;
        this.isPasswordRememberBubble = false;
        this.loginStep = 1;
        return;
      } else if (this.isPassword === false) {
        this.isPassword = true;
        this.isUserRememberBubble = false;
        this.isPasswordRememberBubble = true;
        this.loginStep = 2;
        setTimeout(() => {
          this.passEl.nativeElement.focus();
          document.getElementById("password").focus();
          let suggestionsArray =
            localStorage.getItem("user_suggestions") != null
              ? JSON.parse(localStorage.getItem("user_suggestions"))
              : [];
          if (suggestionsArray.length > 0) {
            let perfectMatch = null;
            for (let index = 0; index < suggestionsArray.length; index++) {
              const suggestion = suggestionsArray[index];
              if (this.model.username == suggestion.user) {
                perfectMatch = suggestion;
                break;
              }
            }
            if (perfectMatch != null) {
              if (perfectMatch.hasOwnProperty("pass")) {
                this.rememberPass = true;
                this.model.password = perfectMatch.pass;
              } else {
                this.rememberPass = false;
                this.model.password = "";
              }
            } else {
              this.rememberPass = false;
              this.model.password = "";
            }
          }
        }, 10);
        return;
      } else if (this.isPassword === true && this.isLogged === false) {
        this.isUserRememberBubble = false;
        this.isPasswordRememberBubble = false;
        this.isPassword = false;
        this.isLogged = true;
        this.isResult = false;
        this.dataService.updateIsResult(false);
        this.login();
        this.cdr.detectChanges();
        return;
      }
    } else {
      this.isUserOptions = !this.isUserOptions;
      this.countClicksOutUserOptions = 1;
      this.isUserRememberBubble = false;
      this.isPasswordRememberBubble = false;
      return;
    }
  }

  backToUser(): void {
    this.loginStep = 1;
    this.isLogged = false;
    this.isPassword = false;
    setTimeout(() => this.userEl.nativeElement.focus(), 0);
  }

  cleanActualMusic(track, musics) {
    for (let j = musics.length - 1; j >= 0; j--) {
      const music = musics[j];
      if (music.id == track.id) {
        musics.splice(j, 1);
      }
    }
    return musics;
  }

  getMusicsFromAlbum(event, i, track) {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    if (
      !("isRelated" in track) &&
      !("isSimilar" in track) &&
      !("isFromAlbum" in track)
    ) {
      let pos = i;
      this.showAlbumTracks[i] = !this.showAlbumTracks[i];
      if (this.showAlbumTracks[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (
            "isRelated" in this.tracks[j] ||
            "isSimilar" in this.tracks[j] ||
            "isFromAlbum" in this.tracks[j]
          ) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showAlbumTracks[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        pos -= decrement;
        this.loadingTracks = true;
        this.albumService.getSameAlbum(track).subscribe((data) => {
          let musics = this.musicService.mountMusicList(
            data,
            this.tonos,
            false,
            false,
            true,
            false
          );
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
          if (this.searchMode == "Catalog") {
            this.insertSecondaryMusicsSearchCatalogs(track, musics);
          }
          if (this.searchMode == "Album") {
            this.insertSecondaryMusicsSearchAlbum(track, musics);
          }
          this.updateContainer();
          this.checkSelected();
          this.loadingTracks = false;
        });
      } else {
        const idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (
            !("isRelated" in this.tracks[id]) &&
            !("isSimilar" in this.tracks[id]) &&
            !("isFromAlbum" in this.tracks[id])
          ) {
            break;
          } else {
            if ("isFromAlbum" in this.tracks[id]) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  updateContainer() {
    let isSomethingPlaying = false;
    for (let id = 0; id < this.tracks.length; id++) {
      this.tracks[id].container =
        "#track-" + id + "-" + this.tracks[id].metadataID + "-progress";
      if (this.tracks[id].isPlaying) {
        this.indexGlobal = id;
        isSomethingPlaying = true;
      }
      if (this.selectedTrack != null) {
        if (this.tracks[id].id == this.selectedTrack.id) {
          this.selectedTrack.container = this.tracks[id].container;
        }
      }
    }
    if (!isSomethingPlaying) {
      this.indexGlobal = null;
    }
  }

  constructor(
    private genreService: GenreService,
    private homeInfo: HomeinfoService,
    private playlistService: PlaylistService,
    private projectService: ProjectService,
    public zone: NgZone,
    public authService: AuthService,
    public router: Router,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private pagerService: PagerService,
    public i18n: I18nGlvService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    public saveState: SaveStateService,
    public NewprojectmodalComponent: MatDialog,
    public DeleteProjectComponent: MatDialog,
    private userService: UserService,
    public TrackOperationsModalComponent: MatDialog,
    private toastr: ToastrService,
    private albumService: AlbumService,
    public DeleteTrackFromProjectComponent: MatDialog,
    private musicService: MusicService,
    public SendProjectEmailComponent: MatDialog,
    public ShareProjectsComponent: MatDialog,
    public DownloadZipMailComponent: MatDialog,
    public ProjectListExistanceModalComponent: MatDialog,
    public location: Location,
    public playerHistory: PlayerHistoryService,
    public messageDialog: MessageDialogService,
    public downloadService: DownloadFilesService,
	private http: HttpClient
  ) {

    this.genres = [];
    this.genres["A"] = [];
    this.genres["C"] = [];
    this.genres["D"] = [];
    this.genres["E"] = [];
    this.genres["F"] = [];
    this.genres["I"] = [];
    this.genres["J"] = [];
    this.chips = [];
    this.moreChips = [];
    this.secondMoreChips = [];
    this.wavesurfer = [];
    this.isTrackHover = [];
    this.isPlayerExtras = [];
    this.volume = 1;
    if (this.dataService.routerSubscriber != null) {
      this.dataService.routerSubscriber.unsubscribe();
    }
    this.dataService.routerSubscriber = router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
          this.isCatalog = false;
          this.isPlaylist = false;
          this.isChipPlus = true;
          this.isAddRemoveLine = false;
          this.isAdd = false;
          this.isSecondAdd = false;
          this.isSearchFav = false;

          this.isCatalogFilter = false;
          this.isCatalogSelected = false;
          this.isAlbumSelected = false;
          this.isPlaylistFilter = false;
          this.isSearchPlaylist = false;
          this.isProjectsFilter = false;
          this.isSearchProject = false;
          this.dataService.updateIsCatalogSelected(this.isCatalogSelected);
          this.dataService.updateIsSearchPlaylist(this.isSearchPlaylist);
          this.dataService.updateIsSearchProject(this.isSearchProject);
          this.dataService.updateIsAlbumSelected(this.isAlbumSelected);
          // this.clearTracks();
          this.isSpeechBubbleOpen = true;
          this.isSelectAllTracks = false;
          this.chips = [];
          this.moreChips = [];
          this.secondMoreChips = [];
          $("body").removeClass();

          if (!event.url.includes("/search")) {
            if (
              window["wavesurferFile"] != undefined &&
              window["wavesurferFile"] != null
            ) {
              window["wavesurferFile"].destroy();
            }
          }

          if (event.url.includes("/projects/")) {
            this.getProjects();
          } else {
            this.checkSelectedProject();
          }

          if (window["ws"] !== undefined) {
            window["ws"].pause();
            window["ws"].destroy();
          }
          if (window["interval_glv"] !== undefined) {
            window.clearInterval(window["interval_glv"]);
          }
          switch (event.url) {
            case "/search":
              $("body").addClass("body-search");
              setTimeout(() => {
                if (this.selectedTrack != null) {
                  if ($(this.selectedTrack.container).length > 0) {
                    const iterator = parseInt(
                      $(this.selectedTrack.container).data("iterador"),
                      10
                    );
                    if (this.tracks[iterator] != undefined) {
                      this.indexGlobal = iterator;
                      this.tracks[iterator].isPlaying = true;
                    }
                  }
                }
              }, 100);
              if (this.authService.isLoggedIn) {
                this.mountviewSate(true);
              }
              break;
            case "/playlists":
              setTimeout(() => {
                if (this.selectedTrack != null) {
                  if ($(this.selectedTrack.container).length > 0) {
                    const iterator = parseInt(
                      $(this.selectedTrack.container).data("iterador"),
                      10
                    );
                    if (this.tracks[iterator] != undefined) {
                      this.indexGlobal = iterator;
                      this.tracks[iterator].isPlaying = true;
                    }
                  }
                }
              }, 100);
              break;
            case "/catalogos":
              setTimeout(() => {
                if (this.selectedTrack != null) {
                  if ($(this.selectedTrack.container).length > 0) {
                    const iterator = parseInt(
                      $(this.selectedTrack.container).data("iterador"),
                      10
                    );
                    if (this.tracks[iterator] != undefined) {
                      this.indexGlobal = iterator;
                      this.tracks[iterator].isPlaying = true;
                    }
                  }
                }
              }, 100);
              break;
            case "/projects":
              $("body").addClass("body-projects");
              setTimeout(() => {
                if (this.selectedTrack != null) {
                  if ($(this.selectedTrack.container).length > 0) {
                    const iterator = parseInt(
                      $(this.selectedTrack.container).data("iterador"),
                      10
                    );
                    if (this.tracks[iterator] != undefined) {
                      this.indexGlobal = iterator;
                      this.tracks[iterator].isPlaying = true;
                    }
                  }
                }
              }, 100);
              break;
            default:
              break;
          }
          this.dataService.setLastURL(this.url);
        }
      }
    );
  }

  checkSelectedProject() {
    const selectedProject = this.dataService.getSelectedProject();
    if (selectedProject != undefined) {
      if (selectedProject.Status == "5" || selectedProject.Status == "9") {
        this.getProjects();
      }
    }
  }

  checkCookies() {
    const cookie = this.saveState.getCookie("cookiesAccepted");
    if (cookie == "") {
      this.cookiesAccepted = false;
      setTimeout(() => {
        const _self = this;
        if (document.getElementById("text-col_cookies-banner-click") != null) {
          document
            .getElementById("text-col_cookies-banner-click")
            .addEventListener("click", function () {
              _self.router.navigate(["/info"]);
            });
        }
      }, 10);
    } else {
      this.cookiesAccepted = true;
    }
  }

  acceptCookies() {
    this.saveState.setCookie("cookiesAccepted", "true");
    this.cookiesAccepted = true;
  }

ftestUrl(){
	if (this.authService.isLoggedIn && this.authService.user.username=='spainsh' && !this.router.url.includes('sharedProjects')) {
		// PG:
		this.authService.logout(); 
		this.model.username="";
		this.model.password="";
		this.loginStep=1;
		this.router.navigateByUrl("/");
		return;
	}
	}

  ngOnInit() {
	  
		setTimeout(() => this.ftestUrl(), 1000);		
	  
		this.model.username=this.getCookie("user");
		this.model.password=this.getCookie("pass");
		
		this.rememberUser=(this.model.username!="");
		this.rememberPass=(this.model.password!="");

    window.onkeydown = function (e) {
      if (
        (e.key === " " || e.key === "ArrowDown" || e.key === "ArrowUp") &&
        e.target === document.body
      ) {
        e.preventDefault();
      }
    };
    this.dataService.getLoopOption().subscribe((data) => {
      this.loopOptions = data["value"];
    });
    this.dataService.getTrackSelected().subscribe((data) => {
      this.trackSelectedAllInfo = data;
      if (data.hasOwnProperty("action")) {
        this.selectedTrack.isPlaying = false;
      } else {
        let tracks = data["tracks"];
        this.startPlaying(data["track"], data["i"], tracks);
      }
    });
    this.checkCookies();
    if (this.authService.isLoggedIn) {
      this.dataService.setLastTrackSelectedSearch(null);
      this.cookiesAccepted = true;
      this.isUserBubble = false;
      this.isPasswordBubble = false;
      this.isUserRememberBubble = false;
      this.isPasswordRememberBubble = false;
      const number = this.authService.user.Pref_PageResults;
      this.itensPerPage = number === undefined ? this.itensPerPage : number;
      this.downlaodOneTrackAsZip =
        this.authService.user.Pref_DownloadSingleTrackZip;
      if (this.dataService.findProjectsSubscribeCancelService !== null) {
        this.dataService.findProjectsSubscribeCancelService.unsubscribe();
      }
      this.dataService.findProjectsSubscribeCancelService = this.dataService
        .getProjectsSERVICE()
        .subscribe((projects) => {
          this.dataService.findProjectsSubscribeCancelService = null;
          this.projects = projects["projects"] as Array<any>;
          // if(projects['from'] == 'projects'){
          this.dataService.updateProjects(this.projects);
          if (this.projects.length > 0) {
            let lastModified = this.projects[0];
            this.selectedProject = lastModified;
            this.getSelectedProjectsTracks(this.selectedProject);
            this.dataService.updateSelectedProject(lastModified);
          }
          // }
        });
      if (this.dataService.navBarProjectSubscriber != null) {
        this.dataService.navBarProjectSubscriber.unsubscribe();
      }
      this.dataService.navBarProjectSubscriber = this.dataService
        .getProjectsSelectedSERVICE()
        .subscribe((value) => {
          if (value["from"] !== "navbar") {
            this.selectedProject = value["project"];
            this.getSelectedProjectsTracks(this.selectedProject);
          }
        });
      this.dataService.getModalOpen().subscribe((value) => {
        this.modalOpen = value as boolean;
      });
      this.getRecentSearchs();
      this.getFavouriteSearchs();
      this.getPlaceholder(true);
    }
    if (this.authService.user) {
      this.isLogged = true;
      this.model = this.authService.user;
      if (Number(this.authService.user.Pref_TitlesView2) == 1) {
        this.searchMode = "Catalog";
      } else if (Number(this.authService.user.Pref_TitlesView2) == 4) {
        this.searchMode = "Title";
      } else if (Number(this.authService.user.Pref_TitlesView2) == 2) {
        this.searchMode = "Album";
      }
      this.getGenres();
      this.getProjects();
    } else {
      this.isLogged = false;
      this.isPassword = false;
      this.checkRemeberedUser();
    }

    this.buildBeep();
  }

  buildBeep() {
    if (window["beepPlayer"] == undefined || window["beepPlayer"] == null) {
      const _self = this;
      window["beepPlayer"] = myWaveSurfer.create({
        container: document.getElementById("beep_player"),
        waveColor: "grey",
        progressColor: "#f16523",
        responsive: true,
        height: 1,
        cursorColor: "transparent",
      });
      window["beepPlayer"].load("../../assets/beep.mp3");
      window["beepPlayer"].on("finish", () => {
        if (
          window["globalWavesurfer"] != undefined &&
          window["globalWavesurfer"] != null
        ) {
          if (
            window["globalWavesurfer"].isPlaying() &&
            _self.shoudBeepBePlayed(_self.trackSelectedAllInfo)
          ) {
            window["beepPlayer"].play();
          }
        }
      });
      window["beepPlayer"].setVolume(0.45);
    }
  }

  shoudBeepBePlayed(info) {
    let shoudPlay = false;
    if (info.from == "sfx") {
      shoudPlay = true;
    } else if (info.track.hasOwnProperty("IsSFX") && info.track["IsSFX"]) {
      shoudPlay = true;
    }
    return shoudPlay;
  }

  checkIfGetNewPlaceholder(selector) {
    const text = $(selector).val();
    if (this.chips.length > 0 || text.trim() != "") {
      return;
    }
    this.getPlaceholder();
  }

  getPlaceholder(isDefault = false) {
    if (this.placeholderRequestSubscriber != null) {
      this.placeholderRequestSubscriber.unsubscribe();
    }
    this.placeholderRequestSubscriber = this.searchService
      .getPlaceholder(isDefault)
      .subscribe((placeholder) => {
        this.placeholderRequestSubscriber = null;
        this.placeholder = placeholder;
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setWaveformHeight();
    this.checkOpenDDLs();
  }

  checkOpenDDLs() {
    if (
      window.matchMedia("(min-width: 320px)").matches &&
      !window.matchMedia("(min-width: 640px)").matches
    ) {
      $(".show").removeClass("show");
    }
    if (window.matchMedia("(min-width: 640px)").matches) {
      this.showMenuMobile = [];
    }
  }

  setWaveformHeight() {
    if (window["globalWavesurfer"] != undefined) {
      if (window.matchMedia("(min-width: 320px)").matches) {
        window["globalWavesurfer"].setHeight(24);
      }
      if (window.matchMedia("(min-width: 640px)").matches) {
        window["globalWavesurfer"].setHeight(28);
      }
      if (window.matchMedia("(min-width: 960px)").matches) {
        window["globalWavesurfer"].setHeight(56);
      }
      if (window.matchMedia("(min-width: 1440px)").matches) {
        window["globalWavesurfer"].setHeight(56);
      }
      if (window.matchMedia("(min-width: 1920px)").matches) {
        window["globalWavesurfer"].setHeight(100);
      }
    }
  }

  fpreparelogin(l,u) {
    if (l) {
      if (this.xlpreparelogin) {
	    var prepareUrl = `${environment.baseUrl}v2/global/getprepare/`+u;  // URL to web api
        this.http.get<any>(prepareUrl).subscribe(data => {
			this.messageDialog.openMessageDialog2(data.Data);
		})
      }	
    } else {
      this.xlpreparelogin = false;
      this.messageDialog.closeMessageDialog();
    }
  }

  login() {
    let copy = Object.assign({}, this.model);
    const user = {
      user: copy.username,
      pass: copy.password,
    };

    this.xlpreparelogin = true;
    setTimeout(() => this.fpreparelogin(true,copy.username), 3000);

    this.authService
      .login(this.model.username, this.model.password)
      .subscribe((data) => {
        if (data != undefined) {
          if (this.authService.isLoggedIn) {
            this.cookiesAccepted = true;
            this.authService.isLoggedIn = true;
            this.model = this.authService.user;
            if (Number(this.authService.user.Pref_TitlesView2) == 1) {
              this.searchMode = "Catalog";
            } else if (Number(this.authService.user.Pref_TitlesView2) == 4) {
              this.searchMode = "Title";
            } else if (Number(this.authService.user.Pref_TitlesView2) == 4) {
              this.searchMode = "Album";
            }
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            const redirect = this.authService.redirectUrl
              ? this.authService.redirectUrl
              : "/search";
            // Redirect the user
            $("body").css("background", this.authService.user.Pref_BodyBgColor);
            this.router.navigate([redirect]);
            if (this.dataService.findProjectsSubscribeCancelService !== null) {
              this.dataService.findProjectsSubscribeCancelService.unsubscribe();
            }
            this.dataService.findProjectsSubscribeCancelService =
              this.dataService.getProjectsSERVICE().subscribe((projects) => {
                this.dataService.findProjectsSubscribeCancelService = null;
                this.projects = projects["projects"] as Array<any>;
                // if(projects['from'] == 'projects'){
                this.dataService.updateProjects(this.projects);
                if (this.projects.length > 0) {
                  let lastModified = this.projects[0];
                  this.selectedProject = lastModified;
                  this.getSelectedProjectsTracks(this.selectedProject);
                  this.dataService.updateSelectedProject(lastModified);
                }
                // }
              });

            if (this.dataService.navBarProjectSubscriber != null) {
              this.dataService.navBarProjectSubscriber.unsubscribe();
            }
            this.dataService.navBarProjectSubscriber = this.dataService
              .getProjectsSelectedSERVICE()
              .subscribe((value) => {
                if (value["from"] !== "navbar") {
                  this.selectedProject = value["project"];
                  this.getSelectedProjectsTracks(this.selectedProject);
                }
              });

            this.dataService.getModalOpen().subscribe((value) => {
              this.modalOpen = value as boolean;
            });
            this.getRecentSearchs();
            this.getFavouriteSearchs();
            this.getPlaceholder(true);
            this.isUserRememberBubble = false;
            this.isPasswordRememberBubble = false;
            this.dataService.setInitialPlaylistsGroups([]);
            let obj = {};

            if (this.rememberUser && this.rememberPass) {
			  this.setCookie("user", user.user, 365);
			  this.setCookie("pass", user.pass, 365);
			  this.model.password=user.pass;
            }
            if (this.rememberUser && !this.rememberPass) {
			  this.setCookie("user", user.user, 365);
			  this.setCookie("pass", "", 365);
            }
            if (!this.rememberUser) {
			  this.setCookie("user", "", 365);
			  this.setCookie("pass", "", 365);
			}

            if (this.rememberPass && !this.rememberUser) {
              obj["show"] = false;
            } else {
              obj["show"] = true;
            }

            if (obj.hasOwnProperty("user")) {
              let suggestionsArray =
                localStorage.getItem("user_suggestions") != null
                  ? JSON.parse(localStorage.getItem("user_suggestions"))
                  : [];
              for (
                let index = suggestionsArray.length - 1;
                index >= 0;
                index--
              ) {
                const suggestion = suggestionsArray[index];
                if (suggestion.user == obj["user"]) {
                  suggestionsArray.splice(index, 1);
                  break;
                }
              }
              suggestionsArray.push(obj);
              localStorage.setItem(
                "user_suggestions",
                JSON.stringify(suggestionsArray)
              );
            }
            if (!this.rememberPass && !this.rememberUser) {
              let suggestionsArray =
                localStorage.getItem("user_suggestions") != null
                  ? JSON.parse(localStorage.getItem("user_suggestions"))
                  : [];
              for (
                let index = suggestionsArray.length - 1;
                index >= 0;
                index--
              ) {
                const suggestion = suggestionsArray[index];
                if (suggestion.user == this.model.username) {
                  suggestionsArray.splice(index, 1);
                  break;
                }
              }
              localStorage.setItem(
                "user_suggestions",
                JSON.stringify(suggestionsArray)
              );
            }
            this.getProjects();
            this.fpreparelogin(false,"");
          } else {
            this.logout();
          }
        } else {
          alert("Log in failed");
          this.fpreparelogin(false,"");  
          this.backToUser();
        }
      });
    this.model = new User();
  }

  logout() {
    this.isPinned = false;
    this.authService.logout();
    this.router.navigate([""]);
    this.isPassword = false;
    this.isLogged = false;
    this.isCatalog = false;
    this.isPlaylist = false;
    this.isChipPlus = true;
    this.isAddRemoveLine = false;
    this.isAdd = false;
    this.isSecondAdd = false;
    this.isSearchFav = false;
    this.isResult = false;
    this.isCatalogFilter = false;
    this.isCatalogSelected = false;
    this.isAlbumSelected = false;
    this.isPlaylistFilter = false;
    this.isSearchPlaylist = false;
    this.isProjectsFilter = false;
    this.isSearchProject = false;
    this.dataService.updateIsCatalogSelected(this.isCatalogSelected);
    this.dataService.updateIsResult(this.isResult);
    this.dataService.updateIsSearchPlaylist(this.isSearchPlaylist);
    this.dataService.updateIsSearchProject(this.isSearchProject);
    this.dataService.updateIsAlbumSelected(this.isAlbumSelected);
    this.dataService.updateSFXDownloadFormats(undefined);
    this.saveState.removelastSearchCatalogs();
    this.saveState.setLastFindCatalogsTab(null);
    this.saveState.setLastSearchPlaylistsTracks(null);
    this.saveState.saveInitialGenres(null);
    this.saveState.removeLastSearchChipsProjects();
    this.homeInfo.updateHomeAlbuns(null);

    this.dataService.setLastTrackSelectedSearch(null);
    this.dataService.setLastTrackSelectedSFX(null);
    this.dataService.setLastTrackSelectedCatalog(null);
    this.dataService.setLastTrackSelectedPlaylists(null);
    this.dataService.setLastTrackSelectedProjec(null);

    sessionStorage.clear();
	
    if (!this.rememberUser)
	{
		this.model.username = "";
	}
	if (!this.rememberPass) 
	{
		this.model.password = "";
	}
	
    this.isUserOptions = false;
    this.selectedProject = undefined;
    this.loginStep = 1;
    this.emptyAllChips();

    if (window["beepPlayer"] != undefined) {
      window["beepPlayer"].pause();
    }
    if (window["globalWavesurfer"] != undefined) {
      window["globalWavesurfer"].destroy();
      this.dataService.updateFPobject(null);
      this.anyPlaying = false;
      this.selectedTrack = null;
    }
    this.checkRemeberedUser();
  }

  checkRemeberedUser() {
    let suggestionsArray =
      localStorage.getItem("user_suggestions") != null
        ? JSON.parse(localStorage.getItem("user_suggestions"))
        : [];
    if (suggestionsArray.length > 0) {
      const lastLogged = suggestionsArray[suggestionsArray.length - 1];
      if (lastLogged != undefined && lastLogged["show"]) {
        this.model.username = lastLogged["user"];
        if (lastLogged.show) {
          this.rememberUser = true;
        }
        if (lastLogged.hasOwnProperty("pass")) {
          this.rememberPass = true;
        }
      }
    }
  }

  checkIfSearchPlaceholder(selector) {
    const text = $(selector).val();
    if (
      (this.chips != undefined && this.chips.length > 0) ||
      (text != undefined && text.trim() != "") ||
      this.minBPMone != null ||
      this.maxBPMone != null ||
      this.minDURone != null ||
      this.maxDURone != null
    ) {
      return;
    }
    if (
      this.isAddRemoveLine &&
      (this.searchDropdownItem.value == "Lyrics" ||
        this.searchDropdownItem.value == "Artist" ||
        this.searchDropdownItem.value == "MyComments" ||
        this.searchDropdownItem.value == "MyRatings")
    ) {
      return;
    }
    this.placeholder["Keywords" + this.i18n.language.toUpperCase()]
      .split(" ")
      .forEach((text) => {
        this.addChip(text, null);
      });
  }

  isLink(element) {
    if (
      element.includes("http://") ||
      element.includes("https://") ||
      element.includes("www.")
    ) {
      this.isSearchBlocked = true;
      this.dataService.linkIsSetted(true);
      return true;
    }
    this.isSearchBlocked = false;
    this.dataService.linkIsSetted(false);
    return false;
  }

  emptyAllChips() {
    this.chips = [];
    this.secondaryInputs = [];
    this.chips.forEach((chip, id) => {
      // if (id > 0) {
      this.secondaryInputs.push({ input: "secondary-input" + id, value: "" });
      // }
    });
    this.emptyAllSecondaryChips();
  }

  emptyAllSecondaryChips() {
    this.moreChips = [];
    this.secondMoreChips = [];
    this.secondaryInputsMore = [];
    this.moreChips.forEach((chip, id) => {
      this.secondaryInputsMore.push({
        input: "secondary-input-more" + id,
        value: "",
      });
    });
    this.secondaryInputsSecondMore = [];
    this.secondMoreChips.forEach((chip, id) => {
      this.secondaryInputsSecondMore.push({
        input: "secondary-input-second-more" + id,
        value: "",
      });
    });
  }

  fromMenuGlobal = false;
  search(fromMenu = false): void {
    // if(this.router.url.indexOf('catalogos') != -1){
    // this.location.go('./search');
    // }
	if (this.getAudioFromURL) {
		this.getAudioFromURL.unsubscribe();
		this.getAudioFromURL=null;
	}
    this.loadingTracks = false;
    this.fromMenuGlobal = fromMenu;
    this.isPreferedChipActive = false;
    this.isNewReleasesChipActive = false;
    let tracks = [];
    const rowMenuElement: HTMLInputElement = document.getElementById(
      "row-menu"
    ) as HTMLInputElement;
    let rowOneElement: HTMLInputElement = document.getElementById(
      "row-one"
    ) as HTMLInputElement;
    let isReturn = false;
    if (rowMenuElement != null) {
      rowOneElement.value = fromMenu
        ? rowMenuElement.value
        : rowOneElement.value;
      if (rowMenuElement.value == "") {
        this.fromMenuGlobal = false;
        if (
          (this.saveState.getLastSearchChips() != null &&
            JSON.stringify(this.saveState.getLastSearchChips()[0].chips) !==
              JSON.stringify(this.chips) &&
            this.chips[0] != undefined &&
            !this.chips[0].hasOwnProperty("isFile")) ||
          (this.chips[0] != undefined &&
            this.chips[0].hasOwnProperty("isFile") &&
            this.saveState
              .getLastSearchChips()[0]
              .chips[0].hasOwnProperty("isFile") &&
            this.saveState.getLastSearchChips()[0].chips[0].entireName !=
              this.chips[0].entireName)
        ) {
          this.chips = this.saveState.getLastSearchChips()[0].chips;
          this.secondaryInputs = [];
          this.chips.forEach((chip, id) => {
            this.secondaryInputs.push({
              input: "secondary-input" + id,
              value: "",
            });
          });
        } else {
          // this.tracks = this.saveState.getLastFindMusic();
          isReturn = true;
          this.showAlbuns = [];
          this.showTracks = [];
        }
      } else {
        window["wavesurferFile"] = null;
        if (
          window["wavesurferFile"] != undefined &&
          window["wavesurferFile"] != null
        ) {
          window["wavesurferFile"].destroy();
        }
        this.dataService.setFiles([]);
        this.dataService.linkIsSetted(false);
        this.emptyAllChips();
        this.isAddRemoveLine = this.isAdd = this.isSecondAdd = false;
      }
    }
    if (isReturn) {
      if (this.chips.length == 0) {
        this.searchClean();
      }
      return;
    }
    let obj = this.returnSearchObj(rowOneElement, fromMenu);
    if (
      obj["searchObj"][0].hasOwnProperty("linkSimilar") &&
      document.getElementById("min-time") != null &&
      document.getElementById("max-time") != null
    ) {
		var duration = window["wavesurferFile"].getDuration(); // novo $$$
      obj["searchObj"][0].starttime =
        $("#min-time").val() != undefined
          ? this.toSeconds($("#min-time").val())
          : 0;
      obj["searchObj"][0].endtime =
        $("#max-time").val() != undefined
          ? this.toSeconds($("#max-time").val())
          : Math.floor(duration) > 60
          ? 60
          : Math.floor(duration);
    }
    var lastObj = this.dataService.getLastSearchObj();
    if (lastObj == null || JSON.stringify(obj) != JSON.stringify(lastObj)) {
      this.dataService.setLastSearchObj(obj);
      if (this.findMusicaSubscribeCansel !== null) {
        this.findMusicaSubscribeCansel.unsubscribe();
      }
      if (obj["search"] == "search") {
        this.loadingTracks = true;
        this.findMusicaSubscribeCansel = this.searchService
          .newfindMusics(obj["searchObj"], 0)
          .subscribe((tks) => {
            this.dataService.setLastTrackSelectedSearch(null);
            this.processSearchData(tks);
            // this.playerHistory.updateRecentSearchs(obj['searchObj']);
          });
      } else {
        if (obj["searchObj"][0].hasOwnProperty("linkSimilar")) {
          const linkObj = {
            link: obj["searchObj"][0]["linkSimilar"],
          };
          var files = this.dataService.getFiles();
          if (files.length == 0 || files[0].name !== linkObj.link) {
            let new_obj_search = obj;
            new_obj_search["searchObj"][0].starttime = 30;
            new_obj_search["searchObj"][0].endtime = 90;
            this.chips[0].starttime = 30;
            this.chips[0].endtime = 90;
            let saveStateObj = {
              chips: this.chips,
              searchLine: 1,
            };
            this.saveState.setLastSearchChips([saveStateObj]);
            this.dataService.setLastSearchObj(new_obj_search);
            this.loadingTracks = true;
			
		    // PG: AIMS procura por URL:
			
			this.findMusicaSubscribeCansel = this.searchService
				.findMusicsAims(obj["searchObj"], 0)
				.subscribe((tks) => {
				  this.processSearchData(tks);
				  // this.playerHistory.updateRecentSearchs(obj['searchObj']);
				});
		
			// PG: AIMS download audio file from URL

            this.getAudioFromURL = this.searchService.getAudioFromURL(linkObj).subscribe((music) => {
            if (music != null) {
                let sampleBytes = this.base64ToArrayBuffer(music);
                let blob = new Blob([sampleBytes], { type: "audio/mp3" });
                this.dataService.setFiles([
                  { name: linkObj.link, base64: music, file: blob },
                ]);
                this.showFile("url");

				setTimeout(() => {
					this.getAudioFromURL=null;
				},1000);

              } else {
                this.searchClean();
                this.loadingTracks = false;
                alert(this.i18n.getTranslationsBD("urlnotfound"));
              }
            });
			
          } else {

            this.loadingTracks = true;
            let new_obj_search = obj;
            new_obj_search["searchObj"][0].starttime =
              $("#min-time").val() != undefined
                ? this.toSeconds($("#min-time").val())
                : 0;
            new_obj_search["searchObj"][0].endtime =
              $("#max-time").val() != undefined
                ? this.toSeconds($("#max-time").val())
                : 0;
            this.dataService.setLastSearchObj(new_obj_search);
            let saveStateObj = {
              chips: this.chips,
              searchLine: 1,
            };
            this.saveState.setLastSearchChips([saveStateObj]);

			  // PG: AIMS procura por URL:
			  this.findMusicaSubscribeCansel = this.searchService
				.findMusicsAims(obj["searchObj"], 0)
				.subscribe((tks) => {
				  this.processSearchData(tks);
				  // this.playerHistory.updateRecentSearchs(obj['searchObj']);
				});


          }
        } else {
          this.loadingTracks = true;
		  // PG: AIMS procura por URL:
          this.findMusicaSubscribeCansel = this.searchService
            .findMusicsAims(obj["searchObj"], 0)
            .subscribe((tks) => {
              this.processSearchData(tks);
              // this.playerHistory.updateRecentSearchs(obj['searchObj']);
            });
        }
      }
    } else {
      if (lastObj.searchObj[0].hasOwnProperty("linkSimilar")) {
        this.chips[0].starttime = lastObj.searchObj[0].starttime;
        this.chips[0].endtime = lastObj.searchObj[0].endtime;
        let saveStateObj = {
          chips: this.chips,
          searchLine: 1,
        };
        this.saveState.setLastSearchChips([saveStateObj]);
      }
      if (this.findMusicaSubscribeCansel == null) {
        this.loadingTracks = false;
      }
    }
    this.getRecentSearchs();
    this.getFavouriteSearchs();
  }

  returnSearchObj(rowOneElement, fromMenu) {
    if (rowOneElement != null) {
      const rowOneInput: string = rowOneElement.value;
      if (rowOneInput.replace(/\s+/, "").length !== 0) {
        if (this.isLink(rowOneInput)) {
          this.chips.push({
            name: rowOneInput.replace(/\s+/, ""),
            isChipPlus: true,
          });
          this.secondaryInputs = [];
          this.chips.forEach((chip, id) => {
            this.secondaryInputs.push({
              input: "secondary-input" + id,
              value: "",
            });
          });
        } else {
          this.addChip(rowOneInput, null);
        }
        rowOneElement.value = "";
        rowOneElement.focus();
      }
    }
    if (this.isAddRemoveLine && this.isAdd && !this.isSecondAdd) {
      const rowTwoElement: HTMLInputElement = document.getElementById(
        "row-two"
      ) as HTMLInputElement;
      const rowTwoInput: string = rowTwoElement.value;
      if (rowTwoInput.replace(/\s+/, "").length !== 0) {
        this.addMoreChip(rowTwoInput, null);
        rowTwoElement.value = "";
        rowTwoElement.focus();
      }
    }
    if (this.isAddRemoveLine && this.isAdd && this.isSecondAdd) {
      const rowTwoElement: HTMLInputElement = document.getElementById(
        "row-two"
      ) as HTMLInputElement;
      const rowTwoInput: string = rowTwoElement.value;
      if (rowTwoInput.replace(/\s+/, "").length !== 0) {
        this.addMoreChip(rowTwoInput, null);
        rowTwoElement.value = "";
        rowTwoElement.focus();
      }
      const rowThreeElement: HTMLInputElement = document.getElementById(
        "row-three"
      ) as HTMLInputElement;
      const rowThreeInput: string = rowThreeElement.value;
      if (rowThreeInput.replace(/\s+/, "").length !== 0) {
        this.addSecondMoreChip(rowThreeInput, null);
        rowThreeElement.value = "";
        rowThreeElement.focus();
      }
    }
    this.isWavesurferReady = true;
    let normalizedchips = [];
    let normalizedContext = [];
    let isNew = false;
    let isPref = false;
    let normizedFiles = [];
    for (let i = this.chips.length - 1; i >= 0; i--) {
      const chip = this.chips[i];
      if (
        chip.name == this.i18n.getTranslationsBD("new-releases") &&
        chip.hasOwnProperty("context") &&
        chip["context"].split(",").length > 1
      ) {
        isNew = true;
      }
      if (
        chip.name == this.i18n.getTranslationsBD("preferred-catalogues") &&
        chip.hasOwnProperty("context") &&
        chip["context"].split(",").length > 1
      ) {
        isPref = true;
      }
      if (chip.hasOwnProperty("context") && chip.isChipPlus) {
        normalizedContext.push(chip.context);
        continue;
      }
      if (chip.hasOwnProperty("isFile") && chip.isChipPlus) {
        if (i == this.chips.length - 1) {
          var targetFile = null;
          targetFile = this.dataService.getFiles()[0];
          if (targetFile != null) {
            var duration = window["wavesurferFile"].getDuration();
            normizedFiles.push({
              fileSimilar: targetFile.base64,
              fileNameSimilar: targetFile.name,
              starttime:
                $("#min-time").val() != undefined
                  ? this.toSeconds($("#min-time").val())
                  : 0,
              endtime:
                $("#max-time").val() != undefined
                  ? this.toSeconds($("#max-time").val())
                  : Math.floor(duration) > 60
                  ? 60
                  : Math.floor(duration),
            });
            chip.starttime =
              $("#min-time").val() != undefined
                ? this.toSeconds($("#min-time").val())
                : 0;
            chip.endtime =
              $("#max-time").val() != undefined
                ? this.toSeconds($("#max-time").val())
                : Math.floor(duration) > 60
                ? 60
                : Math.floor(duration);
            this.chips = [];
            this.moreChips = [];
            this.secondMoreChips = [];
            this.isAddRemoveLine = false;
            this.isAdd = false;
            this.isSecondAdd = false;
            this.chips.push(chip);
            this.secondaryInputs = [];
            this.chips.forEach((chip, id) => {
              this.secondaryInputs.push({
                input: "secondary-input" + id,
                value: "",
              });
            });
            this.secondaryInputsMore = [];
            this.moreChips.forEach((chip, id) => {
              this.secondaryInputsMore.push({
                input: "secondary-input-more" + id,
                value: "",
              });
            });
            this.secondaryInputsSecondMore = [];
            this.secondMoreChips.forEach((chip, id) => {
              this.secondaryInputsSecondMore.push({
                input: "secondary-input-second-more" + id,
                value: "",
              });
            });
          }
          break;
        }
      }
      if (chip.isChipPlus) {
        if (!chip.hasOwnProperty("isFile")) {
          normalizedchips.push(chip.name);
        } else {
          this.deleteChip(chip);
        }
      } else {
        normalizedchips.push("-" + chip.name);
      }
    }
    if (
      normalizedchips.length === 0 &&
      normalizedContext.length === 0 &&
      normizedFiles.length === 0 &&
      this.minBPMone == null &&
      this.maxBPMone == null &&
      this.minDURone == null &&
      this.maxDURone == null &&
      !this.checkSearchDropdownItem(this.searchDropdownItem.value)
    ) {
      if (!fromMenu) {
      } else {
        return;
      }
    }

    this.showTrackInfo.length = 0;
    this.indexGlobal = null;
    let linksArray = [];
    let finalObj;
    normalizedchips = normalizedchips.reverse();
    for (let index = normalizedchips.length - 1; index >= 0; index--) {
      const element = normalizedchips[index];
      if (this.isLink(element)) {
        if (index == normalizedchips.length - 1) {
          linksArray.push(element);
          normalizedchips = [];
          normalizedContext = [];
          this.chips = [];
          this.moreChips = [];
          this.secondMoreChips = [];
          this.isAddRemoveLine = false;
          this.isAdd = false;
          this.isSecondAdd = false;
          this.chips.push({ name: element, isChipPlus: true });
          this.secondaryInputs = [];
          this.chips.forEach((chip, id) => {
            this.secondaryInputs.push({
              input: "secondary-input" + id,
              value: "",
            });
          });
          this.secondaryInputsMore = [];
          this.moreChips.forEach((chip, id) => {
            this.secondaryInputsMore.push({
              input: "secondary-input-more" + id,
              value: "",
            });
          });
          this.secondaryInputsSecondMore = [];
          this.secondMoreChips.forEach((chip, id) => {
            this.secondaryInputsSecondMore.push({
              input: "secondary-input-second-more" + id,
              value: "",
            });
          });
          // this.destroyPlayerFiles();
          this.dataService.linkIsSetted(true);
          break;
        } else {
          this.deleteChip(this.chips[index]);
          normalizedchips.splice(index, 1);
        }
      }
    }
    if (
      !this.isAddRemoveLine ||
      (this.isAddRemoveLine && !this.isAdd && !this.isSecondAdd)
    ) {
      let obj;
      if (!this.isAddRemoveLine) {
        obj = {
          text: normalizedchips.join(","),
          context: normalizedContext.join(","),
          searchLine: 1,
        };
        if (normizedFiles.length > 0) {
          let fileSimilar = "";
          let fileNameSimilar = "";
          let starttime = "";
          let endtime = "";
          normizedFiles.forEach((file) => {
            fileSimilar += file.fileSimilar + ",";
            fileNameSimilar += file.fileNameSimilar + ",";
            starttime += file.starttime + ",";
            endtime += file.endtime + ",";
          });
          fileSimilar = fileSimilar.substring(0, fileSimilar.length - 1);
          fileNameSimilar = fileNameSimilar.substring(
            0,
            fileNameSimilar.length - 1
          );
          starttime = starttime.substring(0, starttime.length - 1);
          endtime = endtime.substring(0, endtime.length - 1);
          obj["fileSimilar"] = fileSimilar;
          obj["fileNameSimilar"] = fileNameSimilar;
          obj["starttime"] = starttime;
          obj["endtime"] = endtime;
        }
        if (linksArray.length > 0) {
          obj["linkSimilar"] = linksArray.join(",");
        }
      } else {
        obj = {
          text: normalizedchips.join(","),
          context: normalizedContext.join(","),
          searchLine: 1,
          searchType: this.searchDropdownItem.value,
        };
        if (normizedFiles.length > 0) {
          let fileSimilar = "";
          let fileNameSimilar = "";
          let starttime = "";
          let endtime = "";
          normizedFiles.forEach((file) => {
            fileSimilar += file.fileSimilar + ",";
            fileNameSimilar += file.fileNameSimilar + ",";
            starttime += file.starttime + ",";
            endtime += file.starttime + ",";
          });
          fileSimilar = fileSimilar.substring(0, fileSimilar.length - 1);
          fileNameSimilar = fileNameSimilar.substring(
            0,
            fileNameSimilar.length - 1
          );
          starttime = starttime.substring(0, starttime.length - 1);
          endtime = endtime.substring(0, endtime.length - 1);
          obj["fileSimilar"] = fileSimilar;
          obj["fileNameSimilar"] = fileNameSimilar;
          obj["starttime"] = starttime;
          obj["endtime"] = endtime;
        }
        if (linksArray.length > 0) {
          obj["linkSimilar"] = linksArray.join(",");
        }
      }
      if (this.minBPMone !== null) {
        obj["bpmMin"] = this.minBPMone;
      }
      if (this.maxBPMone !== null) {
        obj["bpmMax"] = this.maxBPMone;
      }
      if (this.minDURone !== null) {
        obj["durationMin"] = this.minDURone;
      }
      if (this.maxDURone !== null) {
        obj["durationMax"] = this.maxDURone;
      }
      if (isNew) {
        obj.context = this.remove_contexts_from_contextString(
          obj.context,
          this.context_newReleases.context
        );
        obj["isNew"] = true;
      }
      if (isPref) {
        obj.context = this.remove_contexts_from_contextString(
          obj.context,
          this.context_Preferences.context
        );
        obj["isPref"] = true;
      }
      let ObjArray = [];
      ObjArray.push(obj);
      let saveStateObj = {
        chips: this.chips,
        searchLine: 1,
      };
      if (this.isAddRemoveLine) {
        saveStateObj["searchType"] = this.searchDropdownItem.value;
      }
      this.saveState.setLastSearchChips([saveStateObj]);
      this.searchParmeters = ObjArray;
      if (
        obj.hasOwnProperty("fileSimilar") ||
        obj.hasOwnProperty("linkSimilar")
      ) {
        finalObj = { search: "aims", searchObj: ObjArray };
      } else {
        finalObj = { search: "search", searchObj: ObjArray };
      }
    } else if (this.isAddRemoveLine && this.isAdd && !this.isSecondAdd) {
      const normalizedchips2 = [];
      const normalizedContext2 = [];
      for (const key in this.moreChips) {
        if (this.moreChips.hasOwnProperty(key)) {
          const chip = this.moreChips[key];
          if (chip.hasOwnProperty("context") && chip.isChipPlus) {
            normalizedContext2.push(chip.context);
            continue;
          }
          if (chip.isChipPlus) {
            normalizedchips2.push(chip.name);
          } else {
            normalizedchips2.push("-" + chip.name);
          }
        }
      }
      const obj1 = {
        text: normalizedchips.join(","),
        context: normalizedContext.join(","),
        searchLine: 1,
        searchType: this.searchDropdownItem.value,
      };
      if (this.minBPMone !== null) {
        obj1["bpmMin"] = this.minBPMone;
      }
      if (this.maxBPMone !== null) {
        obj1["bpmMax"] = this.maxBPMone;
      }
      if (this.minDURone !== null) {
        obj1["durationMin"] = this.minDURone;
      }
      if (this.maxDURone !== null) {
        obj1["durationMax"] = this.maxDURone;
      }
      const obj2 = {
        text: normalizedchips2.join(","),
        context: normalizedContext2.join(","),
        searchLine: 2,
        searchOperator: this.moreSearchDropdownItemLeft.value,
        searchType: this.moreSearchDropdownItemRight.value,
      };
      if (this.minBPMtwo !== null) {
        obj2["bpmMin"] = this.minBPMtwo;
      }
      if (this.maxBPMtwo !== null) {
        obj2["bpmMax"] = this.maxBPMtwo;
      }
      if (this.minDURtwo !== null) {
        obj2["durationMin"] = this.minDURtwo;
      }
      if (this.maxDURtwo !== null) {
        obj2["durationMax"] = this.maxDURtwo;
      }
      let ObjArray = [];
      ObjArray.push(obj1);
      ObjArray.push(obj2);
      const saveStateObj1 = {
        chips: this.chips,
        searchLine: 1,
        searchType: this.searchDropdownItem.value,
      };
      const saveStateObj2 = {
        chips: this.moreChips,
        searchLine: 2,
        searchType: this.moreSearchDropdownItemRight.value,
        searchOperator: this.moreSearchDropdownItemLeft.value,
      };
      this.saveState.setLastSearchChips([saveStateObj1, saveStateObj2]);
      this.searchParmeters = ObjArray;
      finalObj = { search: "search", searchObj: ObjArray };
    } else if (this.isAddRemoveLine && this.isAdd && this.isSecondAdd) {
      const normalizedchips2 = [];
      const normalizedContext2 = [];
      for (const key in this.moreChips) {
        if (this.moreChips.hasOwnProperty(key)) {
          const chip = this.moreChips[key];
          if (chip.hasOwnProperty("context") && chip.isChipPlus) {
            normalizedContext2.push(chip.context);
            continue;
          }
          if (chip.isChipPlus) {
            normalizedchips2.push(chip.name);
          } else {
            normalizedchips2.push("-" + chip.name);
          }
        }
      }
      const normalizedchips3 = [];
      const normalizedContext3 = [];
      for (const key in this.secondMoreChips) {
        if (this.secondMoreChips.hasOwnProperty(key)) {
          const chip = this.secondMoreChips[key];
          if (chip.hasOwnProperty("context") && chip.isChipPlus) {
            normalizedContext3.push(chip.context);
            continue;
          }
          if (chip.isChipPlus) {
            normalizedchips3.push(chip.name);
          } else {
            normalizedchips3.push("-" + chip.name);
          }
        }
      }
      const obj1 = {
        text: normalizedchips.join(","),
        context: normalizedContext.join(","),
        searchLine: 1,
        searchType: this.searchDropdownItem.value,
      };
      if (this.minBPMone !== null) {
        obj1["bpmMin"] = this.minBPMone;
      }
      if (this.maxBPMone !== null) {
        obj1["bpmMax"] = this.maxBPMone;
      }
      if (this.minDURone !== null) {
        obj1["durationMin"] = this.minDURone;
      }
      if (this.maxDURone !== null) {
        obj1["durationMax"] = this.maxDURone;
      }
      const obj2 = {
        text: normalizedchips2.join(","),
        context: normalizedContext2.join(","),
        searchLine: 2,
        searchOperator: this.moreSearchDropdownItemLeft.value,
        searchType: this.moreSearchDropdownItemRight.value,
      };
      if (this.minBPMtwo !== null) {
        obj2["bpmMin"] = this.minBPMtwo;
      }
      if (this.maxBPMtwo !== null) {
        obj2["bpmMax"] = this.maxBPMtwo;
      }
      if (this.minDURtwo !== null) {
        obj2["durationMin"] = this.minDURtwo;
      }
      if (this.maxDURtwo !== null) {
        obj2["durationMax"] = this.maxDURtwo;
      }
      const obj3 = {
        text: normalizedchips3.join(","),
        context: normalizedContext3.join(","),
        searchLine: 3,
        searchOperator: this.secondMoreSearchDropdownItemLeft.value,
        searchType: this.secondMoreSearchDropdownItemRight.value,
      };
      if (this.minBPMthree !== null) {
        obj3["bpmMin"] = this.minBPMthree;
      }
      if (this.maxBPMthree !== null) {
        obj3["bpmMax"] = this.maxBPMthree;
      }
      if (this.minDURthree !== null) {
        obj3["durationMin"] = this.minDURthree;
      }
      if (this.maxDURthree !== null) {
        obj3["durationMax"] = this.maxDURthree;
      }

      let ObjArray = [];
      ObjArray.push(obj1);
      ObjArray.push(obj2);
      ObjArray.push(obj3);
      const saveStateObj1 = {
        chips: this.chips,
        searchLine: 1,
        searchType: this.searchDropdownItem.value,
      };
      const saveStateObj2 = {
        chips: this.moreChips,
        searchLine: 2,
        searchType: this.moreSearchDropdownItemRight.value,
        searchOperator: this.moreSearchDropdownItemLeft.value,
      };
      const saveStateObj3 = {
        chips: this.secondMoreChips,
        searchLine: 3,
        searchType: this.secondMoreSearchDropdownItemRight.value,
        searchOperator: this.secondMoreSearchDropdownItemLeft.value,
      };
      this.saveState.setLastSearchChips([
        saveStateObj1,
        saveStateObj2,
        saveStateObj3,
      ]);
      this.searchParmeters = ObjArray;
      finalObj = { search: "search", searchObj: ObjArray };
      // return {search:"search", searchObj: ObjArray};
    }
    return finalObj;
  }

  remove_contexts_from_contextString(context_string, contexts_to_remove) {
    var context_string_array = context_string.split(",");
    var contexts_to_remove_array = contexts_to_remove.split(",");
    for (let it = context_string_array.length - 1; it >= 0; it--) {
      const existente = context_string_array[it];
      if (existente.trim() != "") {
        var to_be_removed = false;
        for (let it_c = 0; it_c < contexts_to_remove_array.length; it_c++) {
          const candidate = contexts_to_remove_array[it_c];
          if (candidate == existente) {
            to_be_removed = true;
            break;
          }
        }
        context_string_array.splice(it, 1);
      }
    }
    return context_string_array.join(",");
  }

  processSearchData(tks) {
    this.searchMode = tks["GroupBy"];
    this.loadingTracks = false;
    this.findMusicaSubscribeCansel = null;
    let listMusica = [];
    if (this.searchMode == "Title") {
      if (tks["Data"] != null) {
        listMusica = this.musicService.mountMusicList(
          tks["Data"],
          this.tonos,
          false,
          false,
          false,
          false
        );
      }
      this.saveState.setLastFindMusic(listMusica);
    } else if (this.searchMode == "Catalog") {
      this.catalogsSearchResult = tks["Data"];
      if (this.catalogsSearchResult != null) {
        this.catalogsSearchResult.forEach((catalog) => {
          catalog["AlbumList"].forEach((album) => {
            let musicsFromAlbum = [];
            album["TrackList"].forEach((track) => {
              musicsFromAlbum.push(track);
            });
            musicsFromAlbum = this.musicService.mountMusicList(
              musicsFromAlbum,
              this.tonos,
              false,
              false,
              false,
              false
            );
            album["finalTrackList"] = musicsFromAlbum;
            album["finalTrackList"].forEach((track) => {
              listMusica.push(track);
            });
          });
        });
      }
      this.saveState.setLastFindMusic(listMusica);
      this.saveState.setLastFindCatalogs(this.catalogsSearchResult);
    } else if (this.searchMode == "Album") {
      this.albunsSearchResult = tks["Data"];
      if (this.albunsSearchResult != null) {
        this.albunsSearchResult.forEach((album) => {
          let musicsFromAlbum = [];
          album["TrackList"].forEach((track) => {
            musicsFromAlbum.push(track);
          });
          musicsFromAlbum = this.musicService.mountMusicList(
            musicsFromAlbum,
            this.tonos,
            false,
            false,
            false,
            false
          );
          album["finalTrackList"] = musicsFromAlbum;
          album["finalTrackList"].forEach((track) => {
            listMusica.push(track);
          });
        });
      }

      this.saveState.setLastFindMusic(listMusica);
      this.saveState.setLastFindAlbuns(this.albunsSearchResult);
    }

    this.saveState.setLastPageVisited(1);
    this.firstTimeSearchingTerm = true;
    this.pager.totalPages = tks["NumberOfPages"];
    this.pager.totalItems = tks["Total"];
    this.mountviewSate(false);
  }

  checkIfItWasContext(chip) {
    if (!chip.isChipPlus) {
      if (chip.hasOwnProperty("context")) {
        if (chip.context != "") {
          chip.context = "";
        }
      }
    }
  }

  mountviewSate(loadchipes) {
    this.counter++;
    const listMusica = this.saveState.getLastFindMusic();
    const lastChips = this.saveState.getLastSearchChips();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    // if(Number(this.authService.user.Pref_TitlesView2) == 1){
    //   this.searchMode = 'Catalog';
    //   this.catalogsSearchResult = this.saveState.getLastFindCatalogs();
    // } else if(Number(this.authService.user.Pref_TitlesView2) == 4){
    //   this.searchMode = 'Title';
    // } else if(Number(this.authService.user.Pref_TitlesView2) == 2){
    //   this.searchMode = 'Album';
    // }
    let page = 1;
    if (loadchipes) {
      if (
        this.saveState.getLastPageVisited() != undefined &&
        this.saveState.getLastPageVisited() != null
      ) {
        page = this.saveState.getLastPageVisited();
      }
      setTimeout(() => {
        const localchips = this.saveState.getLastSearchChips();
        if (localchips != null && !this.fromMenuGlobal) {
          if (localchips.length > 0) {
            if (localchips.length == 1) {
              const tempChips = localchips[0].chips;
              tempChips.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("isFile")) {
                  if (this.dataService.getFiles().length > 0) {
                    this.chips = [];
                    this.chips.push(chip);
                    this.secondaryInputs = [];
                    this.chips.forEach((chip, id) => {
                      this.secondaryInputs.push({
                        input: "secondary-input" + id,
                        value: "",
                      });
                    });
                    this.showFile("file");
                    return;
                  } else {
                    return;
                  }
                }
                if (this.isLink(chip.name)) {
                  this.chips = [];
                  this.chips.push(chip);
                  this.secondaryInputs = [];
                  this.chips.forEach((chip, id) => {
                    this.secondaryInputs.push({
                      input: "secondary-input" + id,
                      value: "",
                    });
                  });
                  var file = this.dataService.getFiles()[0];
                  if (file != undefined) {
                    this.showFile("file");
                    return;
                  }
                }
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip, null, false);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addChip(tempChip, null, false);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip.name, null, false);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addChip("-" + chip.name, null, false);
                  return;
                }
              });

              if (this.chips.length == 0) {
                this.searchClean();
                return;
              }
              this.secondaryInputs = [];
              this.chips.forEach((chip, id) => {
                // if (id > 0) {
                this.secondaryInputs.push({
                  input: "secondary-input" + id,
                  value: "",
                });
                // }
              });
              if (localchips[0].hasOwnProperty("searchType")) {
                // this.isAddRemoveLine = true;
                this.searchDropdownItems.forEach((item) => {
                  if (item.value == localchips[0]["searchType"]) {
                    this.searchDropdownItem = item;
                    return;
                  }
                });
              }
              this.secondaryInputsMore = [];
              this.secondaryInputsSecondMore = [];
            } else if (localchips.length == 2) {
              // this.isAddRemoveLine = true;
              const tempChips = localchips[0].chips;
              tempChips.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip, null, false);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addChip(tempChip, null, false);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip.name, null, false);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addChip("-" + chip.name, null, false);
                  return;
                }
              });
              this.secondaryInputs = [];
              this.chips.forEach((chip, id) => {
                // if (id > 0) {
                this.secondaryInputs.push({
                  input: "secondary-input" + id,
                  value: "",
                });
                // }
              });
              this.searchDropdownItems.forEach((item) => {
                if (item.value == localchips[0]["searchType"]) {
                  this.searchDropdownItem = item;
                  return;
                }
              });

              this.isAdd = true;
              const tempChips2 = localchips[1].chips;
              tempChips2.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addMoreChip(chip, null);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addMoreChip(tempChip, null);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addMoreChip(chip.name, null);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addMoreChip("-" + chip.name, null);
                  return;
                }
              });

              this.secondaryInputsMore = [];
              this.moreChips.forEach((chip, id) => {
                this.secondaryInputsMore.push({
                  input: "secondary-input-more" + id,
                  value: "",
                });
              });

              for (
                let it = 0;
                it < this.moreSearchDropdownItemsLeft.length;
                it++
              ) {
                const element = this.moreSearchDropdownItemsLeft[it];
                if (element.value == localchips[1].searchOperator) {
                  this.moreSearchDropdownItemLeft = element;
                  break;
                }
              }

              this.moreSearchDropdownItemsRight.forEach((item) => {
                if (item.value == localchips[1]["searchType"]) {
                  this.moreSearchDropdownItemRight = item;
                  return;
                }
              });
              this.secondaryInputsSecondMore = [];
            } else if (localchips.length == 3) {
              // this.isAddRemoveLine = true;
              const tempChips = localchips[0].chips;
              tempChips.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip, null, false);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addChip(tempChip, null, false);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addChip(chip.name, null, false);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addChip("-" + chip.name, null, false);
                  return;
                }
              });
              this.secondaryInputs = [];
              this.chips.forEach((chip, id) => {
                // if (id > 0) {
                this.secondaryInputs.push({
                  input: "secondary-input" + id,
                  value: "",
                });
                // }
              });
              this.searchDropdownItems.forEach((item) => {
                if (item.value == localchips[0]["searchType"]) {
                  this.searchDropdownItem = item;
                  return;
                }
              });

              this.isAdd = true;
              const tempChips2 = localchips[1].chips;
              tempChips2.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addMoreChip(chip, null);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addMoreChip(tempChip, null);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addMoreChip(chip.name, null);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addMoreChip("-" + chip.name, null);
                  return;
                }
              });
              this.secondaryInputsMore = [];
              this.moreChips.forEach((chip, id) => {
                this.secondaryInputsMore.push({
                  input: "secondary-input-more" + id,
                  value: "",
                });
              });
              for (
                let it = 0;
                it < this.moreSearchDropdownItemsLeft.length;
                it++
              ) {
                const element = this.moreSearchDropdownItemsLeft[it];
                if (element.value == localchips[1].searchOperator) {
                  this.moreSearchDropdownItemLeft = element;
                  break;
                }
              }
              this.moreSearchDropdownItemsRight.forEach((item) => {
                if (item.value == localchips[1]["searchType"]) {
                  this.moreSearchDropdownItemRight = item;
                  return;
                }
              });

              this.isSecondAdd = true;
              const tempChips3 = localchips[2].chips;
              tempChips3.forEach((mychip) => {
                const chip = mychip;
                chip.name = mychip.name.replace(/ /g, "_");
                if (chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addSecondMoreChip(chip, null);
                  return;
                } else if (chip.hasOwnProperty("context") && !chip.isChipPlus) {
                  const tempChip = chip;
                  tempChip.name = "-" + chip.name;
                  this.addSecondMoreChip(tempChip, null);
                  return;
                } else if (!chip.hasOwnProperty("context") && chip.isChipPlus) {
                  this.addSecondMoreChip(chip.name, null);
                  return;
                } else if (
                  !chip.hasOwnProperty("context") &&
                  !chip.isChipPlus
                ) {
                  this.addSecondMoreChip("-" + chip.name, null);
                  return;
                }
              });
              this.secondaryInputsSecondMore = [];
              this.secondMoreChips.forEach((chip, id) => {
                this.secondaryInputsSecondMore.push({
                  input: "secondary-input-second-more" + id,
                  value: "",
                });
              });

              for (
                let it = 0;
                it < this.secondMoreSearchDropdownItemsLeft.length;
                it++
              ) {
                const element = this.secondMoreSearchDropdownItemsLeft[it];
                if (element.value == localchips[2].searchOperator) {
                  this.secondMoreSearchDropdownItemLeft = element;
                  break;
                }
              }

              this.secondMoreSearchDropdownItemsRight.forEach((item) => {
                if (item.value == localchips[2]["searchType"]) {
                  this.secondMoreSearchDropdownItemRight = item;
                  return;
                }
              });
            }
            if (listMusica == null) {
              this.search();
            }
          }
        }
      }, 500);
    }
    if (lastChips === null) {
      this.searchClean();
      return;
    }
    this.isWavesurferReady = true;
    this.tracks = listMusica;
    this.getUserDownlaodFormats();
    this.isResult = true;
    this.getGenres();
    const _self = this;
    setTimeout(() => {
      _self.dataService.updateIsResult(_self.isResult);
    }, 700);
    const tracks = this.tracks;
    this.dataService.updateIsResult(this.isResult);
    if (tracks != null) {
      tracks.forEach((track) => {
        track.isTrackSelected = false;
        this.playerHistory.selectedSongsSEARCH.forEach((selectedTrack) => {
          if (track.id == selectedTrack.id) {
            track.isTrackSelected = true;
          }
        });
      });
      this.firstTimeSearchingTerm = true;
      this.setPage(page);
    }
  }

  // one ring to rule them all
  oneDownloadToRuleThemAll(index, event, track, selectedType) {
    if (
      this.authService.isLoggedIn &&
      this.authService.user.Accs_MaxDownloads === 0
    ) {
      alert(this.i18n.getTranslationsBD("impossible-to-download"));
      return false;
    }
    if (event != null) {
      event.stopPropagation();
      if (event != undefined && event != null && event.ctrlKey) {
        if (index != null) {
          this.selectTrack(null, index, this.tracks);
          return;
        }
      }
      if (event != undefined && event != null && event.shiftKey) {
        if (index != null) {
          this.checkTracksToSelect(index);
          return;
        }
      }
    }
    let musicList = this.downloadService.prepareDownloadList(
      track,
      this.playerHistory.selectedSongsSEARCH
    );
    let type = this.downloadService.prepareType(selectedType);
    let projectId = this.downloadService.prepareProjectId(
      this.dataService.getSelectedProject()
    );
    let emails = null;
    this.downloadService.DownloadWithValidation(
      projectId,
      type,
      musicList,
      this
    );
  }

  selectAllTracks(tracks) {
    let areAlreadySelected = true;
    let firsTrackOfPage = 0;
    let lastTrackOfPage = this.tracks.length - 1;
    for (let i = 0; i < tracks.length; i++) {
      const element = tracks[i];
      if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
        if (!element.isTrackSelected) {
          areAlreadySelected = false;
        }
      }
    }
    if (areAlreadySelected) {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = false;
          this.playerHistory.removeSongFromSelectedSEARCH(element);
        }
      }
    } else {
      for (let i = 0; i < tracks.length; i++) {
        const element = tracks[i];
        if (i >= firsTrackOfPage && i <= lastTrackOfPage) {
          element.isTrackSelected = true;
          this.playerHistory.addSongToSelectedSEARCH(element);
        }
      }
    }
  }

  deselectAllTracks(tracks) {
    tracks.map(function (x) {
      x.isTrackSelected = false;
      return x;
    });
    this.playerHistory.addSongToSelectedSEARCH(null);
  }

  selectTrack($event, index, tracks) {
    if ($event != null) {
      $event.stopPropagation();
      if ($event.shiftKey) {
        this.checkTracksToSelect(index);
        return;
      }
    }
    let count = 0;
    tracks[index].isTrackSelected = !tracks[index].isTrackSelected;
    if (tracks[index].isTrackSelected) {
      this.playerHistory.addSongToSelectedSEARCH(tracks[index]);
      const id = tracks[index].id;
      this.tracks.forEach((element) => {
        if (element.id == id) {
          element.isTrackSelected = true;
          this.dataService.setLastTrackSelectedSearch({
            id: index,
            page: this.pager.currentPage,
          });
        }
      });
    } else {
      this.playerHistory.removeSongFromSelectedSEARCH(tracks[index]);
      const id = tracks[index].id;
      this.tracks.forEach((element) => {
        if (element.id == id) {
          element.isTrackSelected = false;
        }
      });
    }
  }

  playNext($event, index) {
    $event.stopPropagation();
    if ($event != undefined && $event != null && $event.ctrlKey) {
      this.selectTrack(null, index, this.tracks);
      return;
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      this.checkTracksToSelect(index);
      return;
    }
    const positionAbsolute = index;
    const fpObject = this.dataService.getFPobject();

    if (
      window.location.href.includes("search") &&
      (fpObject == null || fpObject.hasOwnProperty("from"))
    ) {
      const tracks = fpObject != null ? fpObject.tracks : null;
      if (
        tracks != null &&
        positionAbsolute === tracks.length - 1 &&
        this.value == this.pager.totalPages
      ) {
        return;
      }
      if (index === this.tracks.length - 1) {
        this.setPage(parseInt(this.pager.currentPage) + 1, "next");
      } else {
        const next = (index + 1) % this.itensPerPage;
        let obj = {
          track: this.saveState.getLastFindMusic()[next],
          i: next,
          tracks: this.saveState.getLastFindMusic(),
          from: "search",
          chips: this.saveState.getLastSearchChips(),
        };
        this.dataService.updateTrackSelected(obj);
      }
    } else {
      const tracks = fpObject != null ? fpObject.tracks : null;
      if (tracks != null && positionAbsolute === tracks.length - 1) {
        return;
      }
      const next = (index + 1) % this.itensPerPage;
      let obj = fpObject;
      obj["i"] = next;
      obj["track"] = obj["tracks"][next];
      this.dataService.updateTrackSelected(obj);
    }
  }

  playRandom(index) {
    const fpObject = this.dataService.getFPobject();
    const tracks = fpObject.tracks;
    const random = Math.floor(Math.random() * tracks.length);
    const positionWaveList = random;

    let all_alreadyPlayed = true;
    for (let it = 0; it < tracks.length; it++) {
      const track = tracks[it];
      if (!track.hasOwnProperty("played")) {
        all_alreadyPlayed = false;
      }
    }
    if (!all_alreadyPlayed) {
      if (random === index) {
        this.playRandom(index);
      } else {
        let obj = fpObject;
        obj["i"] = random;
        obj["track"] = obj["tracks"][random];
        this.dataService.updateTrackSelected(obj);
      }
    }
  }

  playSelectedRandom(index) {
    let count = 0;
    const fpObject = this.dataService.getFPobject();
    const tracks = fpObject.tracks;
    tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    if (count < 2) {
      return;
    }
    let all_alreadyPlayed = true;
    for (let it = 0; it < tracks.length; it++) {
      const track = tracks[it];
      if (!track.hasOwnProperty("played")) {
        all_alreadyPlayed = false;
      }
    }
    if (!all_alreadyPlayed) {
      const random = Math.floor(Math.random() * tracks.length);
      const positionWaveList = random;
      if (
        random === index ||
        !tracks[random].isTrackSelected ||
        tracks[random].hasOwnProperty("played")
      ) {
        this.playSelectedRandom(index);
      } else {
        let obj = fpObject;
        obj["i"] = random;
        obj["track"] = obj["tracks"][random];
        this.dataService.updateTrackSelected(obj);
      }
    }
  }

  playNextSelected(index) {
    let count = 0;
    const fpObject = this.dataService.getFPobject();
    const tracks = fpObject.tracks;
    tracks.map(function (x) {
      if (x.isTrackSelected) {
        count++;
      }
    });
    if (count < 2) {
      return;
    }
    if (index === tracks.length - 1) {
      return;
    }
    const next = (index + 1) % tracks.length;
    const positionWaveList = next;
    if (!tracks[next].isTrackSelected) {
      this.playNextSelected(next);
    } else {
      let obj = fpObject;
      obj["i"] = next;
      obj["track"] = obj["tracks"][next];
      this.dataService.updateTrackSelected(obj);
    }
  }

  newPlay(track, i, event) {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    if (
      !$(event.target).hasClass("dropdown-toggle") &&
      !$(event.target).hasClass("dropdown-item")
    ) {
      let obj = {
        track: track,
        i: i,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      if (this.indexGlobal == null) {
        this.dataService.updateTrackSelected(obj);
      } else {
        if (this.indexGlobal == i) {
          if (!window.location.href.includes("search")) {
            window["globalWavesurfer"].playPause();
            if (
              window["wavesurferFile"] != undefined &&
              window["wavesurferFile"] != null &&
              window["wavesurferFile"].isPlaying()
            ) {
              window["wavesurferFile"].pause();
            }
            if (window["globalWavesurfer"].isPlaying()) {
              track.isPlaying = true;
              this.selectedTrack.isPlaying = true;
            } else {
              track.isPlaying = false;
              this.selectedTrack.isPlaying = false;
            }
          } else {
            const fpObject = this.dataService.getFPobject();
            if (track.metadataID != fpObject.track.metadataID) {
              this.dataService.updateTrackSelected(obj);
            } else {
              window["globalWavesurfer"].playPause();
              if (
                window["wavesurferFile"] != undefined &&
                window["wavesurferFile"] != null &&
                window["wavesurferFile"].isPlaying()
              ) {
                window["wavesurferFile"].pause();
              }
              if (window["globalWavesurfer"].isPlaying()) {
                track.isPlaying = true;
                this.selectedTrack.isPlaying = true;
              } else {
                track.isPlaying = false;
                this.selectedTrack.isPlaying = false;
              }
            }
          }
        } else {
          this.dataService.updateTrackSelected(obj);
        }
      }
    }
  }

  getWidth(id) {
    let div = document.getElementById(id);
    let width = div.offsetWidth;
    //return "1024px"; //PG: Estava a dar erro por mudar o valor
    return width + "px";
  }

  playPauseFreeze(selectedTrack) {
    window["globalWavesurfer"].playPause();
    if (
      window["wavesurferFile"] != undefined &&
      window["wavesurferFile"] != null &&
      window["wavesurferFile"].isPlaying()
    ) {
      window["wavesurferFile"].pause();
    }
    if (window["globalWavesurfer"].isPlaying()) {
      selectedTrack.isPlaying = true;
      if (this.shoudBeepBePlayed(this.trackSelectedAllInfo)) {
        window["beepPlayer"].play();
      }
    } else {
      selectedTrack.isPlaying = false;
      window["beepPlayer"].pause();
    }
    const fpObject = this.dataService.getFPobject();
    const tracks = fpObject.tracks;
    for (let id = 0; id < fpObject.tracks.length; id++) {
      const track = fpObject.tracks[id];
      if (track.id == selectedTrack.id) {
        track.isPlaying = selectedTrack.isPlaying;
      }
    }
  }

  startPlaying(ntrack, i, tracks, mustNotRefreshList = null) {
    let track = ntrack;
    this.anyPlaying = true;
    this.indexGlobal = i;
    if (this.indexGlobal != null) {
      this.selectedTrack = Object.assign({}, tracks[this.indexGlobal]);
      tracks[this.indexGlobal].played = true;
      this.selectedTrack.currentTime = ntrack.currentTime;
    } else {
      this.selectedTrack = Object.assign({}, track);
      let isVisible = false;
      tracks.forEach((mtrack, it) => {
        if (mtrack.id == this.selectedTrack.id) {
          this.indexGlobal = it;
          this.selectedTrack = Object.assign({}, mtrack);
          track = mtrack;
          isVisible = true;
          mtrack.played = true;
          return;
        }
      });
      if (!isVisible) {
        this.selectedTrack.container = null;
      }
    }
    let StartFrom = this.selectedTrack.currentTime;
    if (this.selectedTrack.durationSecs - StartFrom <= 2) {
      StartFrom = 0;
    }
    this.selectedTrack.played = true;
    this.dataService.justUpdateTrackSelected(this.selectedTrack);
    this.dataService.updateFPobject({
      track: this.selectedTrack,
      i: i,
      tracks: tracks,
    });
    if (this.authService.isLoggedIn) {
      if (!this.musicService.checkIfAbleToPlay()) {
        return;
      }
    }
    if (tracks != undefined) {
      tracks.forEach((t, id) => {
        t.isPlaying = false;
      });
    }
    if (window["globalWavesurfer"] != null) {
      window["globalWavesurfer"].destroy();
    }
    const cont = document.getElementById("freeze");
    if (cont != null) {
      cont.innerHTML = "";
    }
    let height = 95;
    if (window.matchMedia("(min-width: 320px)").matches) {
      height = 24;
    }
    if (window.matchMedia("(min-width: 640px)").matches) {
      height = 28;
    }
    if (window.matchMedia("(min-width: 960px)").matches) {
      height = 56;
    }
    if (window.matchMedia("(min-width: 1440px)").matches) {
      height = 56;
    }
    if (window.matchMedia("(min-width: 1920px)").matches) {
      height = 100;
    }
    window["globalWavesurfer"] = WaveSurfer.create({
      container: cont,
      waveColor: "grey",
      progressColor: "#f16523",
      responsive: true,
      height: height,
      backend: "MediaElement",
      mediaType: "audio",
      normalize: true,
      barWidth: 1,
      barGap: 3,
      cursorColor: "transparent",
    });
    const _self = this;

    if (
      window["wavesurferFile"] != undefined &&
      window["wavesurferFile"] != null &&
      window["wavesurferFile"].isPlaying()
    ) {
      window["wavesurferFile"].pause();
    }
    track.isPlaying = true;
    this.selectedTrack.isPlaying = true;
    if (mustNotRefreshList == null) {
      window["globalWavesurfer"].load(this.selectedTrack.url, []);
    } else {
      window["globalWavesurfer"].load(
        this.selectedTrack.url + "?mustNotRefreshList=true",
        []
      );
    }

	// PG: TrackTime:
    // this.searchService.getPeaks(-this.selectedTrack.id).subscribe((t) => {
	//	track.duration=t;
	// })

    this.searchService.getPeaks(this.selectedTrack.id).subscribe((peaks) => {
      window["globalWavesurfer"].drawer.clearWave();
      window["globalWavesurfer"].backend.setPeaks(peaks);
      window["globalWavesurfer"].drawBuffer();
      track.isloadToWave = true;
      window["globalWavesurfer"].fireEvent("waveform-ready");
    });
    window["globalWavesurfer"].on("seek", (position) => {
      window["globalWavesurfer"].play();
      track.isPlaying = true;
      _self.selectedTrack.isPlaying = true;
    });

    let isSafari = false;
    if (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    ) {
      isSafari = true;
    }
    if (isSafari) {
      setTimeout(() => {
        window["globalWavesurfer"].play(StartFrom);
      }, 500);
    } else {
      window["globalWavesurfer"].play(StartFrom);
    }

    window["globalWavesurfer"].on("ready", (position) => {
      if (_self.shoudBeepBePlayed(_self.trackSelectedAllInfo)) {
        if (!window["beepPlayer"].isPlaying()) {
          window["beepPlayer"].play();
        }
      } else {
        window["beepPlayer"].pause();
      }
    });

    window["globalWavesurfer"].on("audioprocess", function () {
      if (window["globalWavesurfer"] !== null) {
        const totalTime = window["globalWavesurfer"].getDuration();
        const currentTime = window["globalWavesurfer"].getCurrentTime();
        const percentage = (currentTime * 100) / totalTime;
        if (_self.selectedTrack.container != null) {
          $(_self.selectedTrack.container).css({
            width: percentage + "%",
            // borderRight: '1px solid black'
          });
        }
        track.isPlaying = true;
        _self.selectedTrack.isPlaying = true;
        if (_self.indexGlobal == null) {
          _self.indexGlobal = _self.getRealIndex(_self.selectedTrack);
        }
        const timeTodisplay = moment("2015-01-01")
          .startOf("day")
          .seconds(window["globalWavesurfer"].getCurrentTime())
          .format("mm:ss");
        track.currentTime = currentTime;
        track.currentTimeDisplay = timeTodisplay;
        $(_self.selectedTrack.timeContainer).text(timeTodisplay);
        _self.selectedTrack.currentTime = currentTime;
        _self.selectedTrack.currentTimeDisplay = timeTodisplay;
        if (tracks[_self.indexGlobal] != undefined) {
          tracks[_self.indexGlobal].currentTime = currentTime;
          tracks[_self.indexGlobal].currentTimeDisplay = timeTodisplay;
        }
        _self.dataService.updateFPobject({
          track: _self.selectedTrack,
          i: i,
          tracks: tracks,
        });
      }
    });
    window["globalWavesurfer"].on("finish", () => {
      if (this.loopOptions == "all_automatic") {
        this.playNext(event, this.indexGlobal);
      } else if (this.loopOptions == "all_random") {
        this.playRandom(this.indexGlobal);
      } else if (this.loopOptions == "all_selected") {
        this.playNextSelected(this.indexGlobal);
      } else if (this.loopOptions == "all_selected_random") {
        this.playSelectedRandom(this.indexGlobal);
      } else {
        this.anyPlaying = false;
        window["beepPlayer"].pause();
      }
    });
    window["globalWavesurfer"].setVolume(this.volume);
    const volumeInput = document.querySelector(".volume");
    const self = this;
    const onChangeVolume = function (e) {
      window["globalWavesurfer"].setVolume(e.target.value);
      self.volume = e.target.value;
    };
    if (volumeInput != null) {
      volumeInput.addEventListener("input", onChangeVolume);
      volumeInput.addEventListener("change", onChangeVolume);
    }
    this.playerHistory.updatePlayedSongs(this.selectedTrack.id);
  }

  playPauseWaveSurferFile() {
    if (
      window["globalWavesurfer"] != undefined &&
      window["globalWavesurfer"] != null &&
      window["globalWavesurfer"].isPlaying()
    ) {
      window["globalWavesurfer"].pause();
    }
    window["wavesurferFile"].playPause();
    window["beepPlayer"].pause();
  }

  playPrev($event, index) {
    $event.stopPropagation();
    if ($event != undefined && $event != null && $event.ctrlKey) {
      this.selectTrack(null, index, this.tracks);
      return;
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      this.checkTracksToSelect(index);
      return;
    }
    const positionAbsolute = index;
    if (positionAbsolute === 0 && this.value == 1) {
      return;
    }
    if (index === 0) {
      this.setPage(parseInt(this.pager.currentPage) - 1, "previous");
    } else {
      const prev = (index - 1) % this.itensPerPage;
      // this.startPlaying(this.tracks[prev], prev, this.tracks);
      let obj = {
        track: this.saveState.getLastFindMusic()[prev],
        i: prev,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      this.dataService.updateTrackSelected(obj);
    }
  }

  goToTrack(index) {
    setTimeout(() => {
      const offset = 110 * (index - 1);
      if (offset === undefined) {
        return;
      }
      const positionTop = offset;
      $("html, body").scrollTop(positionTop);
    }, 200);
  }

  skipBackward($event, index) {
    $event.stopPropagation();
    if ($event != undefined && $event != null && $event.ctrlKey) {
      if (index != null) {
        this.selectTrack(null, index, this.tracks);
        return;
      }
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      if (index != null) {
        this.checkTracksToSelect(index);
        return;
      }
    }
    if (index == this.indexGlobal) {
      window["globalWavesurfer"].skipBackward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime - 10;
      if (this.tracks[index].currentTime < 0) {
        this.tracks[index].currentTime = 0;
      }
      let obj = {
        track: this.saveState.getLastFindMusic()[index],
        i: index,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      this.dataService.updateTrackSelected(obj);
      // this.startPlaying(this.tracks[index], index, this.tracks);
    }
    this.indexGlobal = index;
  }

  skipForward($event, index) {
    $event.stopPropagation();
    if ($event != undefined && $event != null && $event.ctrlKey) {
      if (index != null) {
        this.selectTrack(null, index, this.tracks);
        return;
      }
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      if (index != null) {
        this.checkTracksToSelect(index);
        return;
      }
    }
    if (index == this.indexGlobal) {
      window["globalWavesurfer"].skipForward(10);
    } else {
      this.tracks[index].currentTime = this.tracks[index].currentTime + 10;

      if (this.tracks[index].currentTime > this.tracks[index].durationSecs) {
        this.tracks[index].currentTime = this.tracks[index].durationSecs;
      }
      let obj = {
        track: this.saveState.getLastFindMusic()[index],
        i: index,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      this.dataService.updateTrackSelected(obj);
      // this.startPlaying(this.tracks[index], index, this.tracks);
    }
    this.indexGlobal = index;
  }

  playerKeys(event) {
    event.preventDefault();
    event.stopPropagation();
    const focused = document.activeElement;
    if (
      !$("input").is(":focus") &&
      !$("textarea").is(":focus") &&
      !$(focused).is('[contenteditable="true"]')
    ) {
      const positionWaveList = this.indexGlobal;
      if (window["globalWavesurfer"] != null) {
        if (event.key === "ArrowRight") {
          window["globalWavesurfer"].skipForward(10);
        }
        if (event.key === "ArrowLeft") {
          window["globalWavesurfer"].skipBackward(10);
        }
        if (event.key === "ArrowUp") {
          this.playFP(event, "previous");
        }
        if (event.key === "ArrowDown") {
          this.playFP(event, "next");
        }
        if (event.key === " ") {
          const focused = document.activeElement;
          if (
            !$("input").is(":focus") &&
            !$("textarea").is(":focus") &&
            !$(focused).is('[contenteditable="true"]')
          ) {
            window["globalWavesurfer"].playPause();
            if (
              window["wavesurferFile"] != undefined &&
              window["wavesurferFile"] != null &&
              window["wavesurferFile"].isPlaying()
            ) {
              window["wavesurferFile"].pause();
            }
            if (window["globalWavesurfer"].isPlaying()) {
              this.selectedTrack.isPlaying = true;
              if (this.shoudBeepBePlayed(this.trackSelectedAllInfo)) {
                window["beepPlayer"].play();
              } else {
                window["beepPlayer"].pause();
              }
            } else {
              this.selectedTrack.isPlaying = false;
              window["beepPlayer"].pause();
            }
            const fpObject = this.dataService.getFPobject();
            const tracks = fpObject.tracks;
            for (let id = 0; id < tracks.length; id++) {
              const track = tracks[id];
              if (track.id == this.selectedTrack.id) {
                track.isPlaying = this.selectedTrack.isPlaying;
              }
            }
          }
        }
      }
    }
  }

  playFP(event, operation) {
    event.stopPropagation();
    let isCatalogDLLOpen = $("#catalogsDDL").is(":visible");
    let isAlbumDLLOpen = $("#albunsDDL").is(":visible");
    if (!isAlbumDLLOpen && !isCatalogDLLOpen) {
      const fpObject = this.dataService.getFPobject();
      let newIt = fpObject.i;
      if (operation == "next") {
        newIt++;
        if (newIt > fpObject.tracks.length - 1) {
          newIt = fpObject.tracks.length - 1;
          if (
            this.trackSelectedAllInfo != null &&
            this.trackSelectedAllInfo.hasOwnProperty("from") &&
            this.trackSelectedAllInfo.from == "search"
          ) {
            if (
              JSON.stringify(this.chips) ==
              JSON.stringify(this.trackSelectedAllInfo.chips[0].chips)
            ) {
              const currentPage = this.pager.currentPage;
              const newPage = currentPage + 1;
              if (newPage <= this.pager.totalPages) {
                this.firstTimeSearchingTerm = false;
                this.setPage(newPage, "next");
              }
              return;
            }
          }
          if (
            this.trackSelectedAllInfo != null &&
            this.trackSelectedAllInfo.hasOwnProperty("from") &&
            this.trackSelectedAllInfo.from == "sfx"
          ) {
            const newPage = 1;
            const fromSearchObj = this.trackSelectedAllInfo.search;
            if (window.location.href.includes("sfx")) {
              if (
                JSON.stringify(fromSearchObj) ==
                JSON.stringify(this.saveState.getLastSearchSFX())
              ) {
                this.dataService.changePageSFX(newPage);
              }
            }
          }
        }
      } else {
        newIt--;
        if (newIt < 0) {
          newIt = 0;
          if (
            this.trackSelectedAllInfo != null &&
            this.trackSelectedAllInfo.hasOwnProperty("from") &&
            this.trackSelectedAllInfo.from == "search"
          ) {
            if (
              JSON.stringify(this.chips) ==
              JSON.stringify(this.trackSelectedAllInfo.chips[0].chips)
            ) {
              const currentPage = this.pager.currentPage;
              const newPage = currentPage - 1;
              if (newPage >= 1) {
                this.firstTimeSearchingTerm = false;
                this.setPage(newPage, "previous");
              }
              return;
            }
          }
          if (
            this.trackSelectedAllInfo != null &&
            this.trackSelectedAllInfo.hasOwnProperty("from") &&
            this.trackSelectedAllInfo.from == "sfx"
          ) {
            const newPage = -1;
            const fromSearchObj = this.trackSelectedAllInfo.search;
            if (window.location.href.includes("sfx")) {
              if (
                JSON.stringify(fromSearchObj) ==
                JSON.stringify(this.saveState.getLastSearchSFX())
              ) {
                this.dataService.changePageSFX(newPage);
              }
            }
          }
        }
      }
      if (
        this.trackSelectedAllInfo != null &&
        this.trackSelectedAllInfo.hasOwnProperty("from") &&
        this.trackSelectedAllInfo.from == "catalogs"
      ) {
        const newPage = Math.ceil(
          (newIt + 1) / this.authService.user.Pref_PageResults
        );
        const fromSearchObj = this.trackSelectedAllInfo.search;
        if (window.location.href.includes(fromSearchObj)) {
          this.dataService.changePageCatalgos(newPage);
        }
      }
      if (fpObject.tracks[newIt] != undefined) {
        const obj = this.trackSelectedAllInfo;
        obj["track"] = fpObject.tracks[newIt];
        obj["tracks"] = fpObject.tracks;
        obj["i"] = newIt;
        this.dataService.updateTrackSelected(obj);
      }
    }
  }

  getPositionClicked(track, i, event) {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    setTimeout(() => {
      if (!this.wasDBL) {
        const posX = Math.round(event.offsetX > 0 ? event.offsetX : event.layerX);
        const width = Math.round($("#track-" + i + "-" + track.metadataID).width());
        const percentage = (posX * 100) / width;
        const duration = moment.duration("00:" + track.duration).asSeconds();
        const playFrom = (percentage * duration) / 100;

        track.currentTime = playFrom;
        track.currentTimeDisplay = moment("2015-01-01")
          .startOf("day")
          .seconds(playFrom)
          .format("mm:ss");
        this.selectedTrack = track;
        let obj = {
          track: track,
          i: i,
          tracks: this.saveState.getLastFindMusic(),
          from: "search",
          chips: this.saveState.getLastSearchChips(),
        };
        if (this.indexGlobal == null) {
          // this.startPlaying(track, i, this.tracks);
          this.dataService.updateTrackSelected(obj);
        } else {
          if (window["globalWavesurfer"] != null) {
            window["globalWavesurfer"].pause();
          }
          if (this.indexGlobal == i) {
            track.isPlaying = true;
            this.selectedTrack.isPlaying = true;
            // this.startPlaying(track, null, this.tracks, 'mustNotRefreshList');
            this.dataService.updateTrackSelected(obj);
          } else {
            // this.startPlaying(track, i, this.tracks);
            this.dataService.updateTrackSelected(obj);
          }
        }
      }
    }, 500);
  }

  clickOpenInfo($event, i, track) {
    let pos = i;
    $event.stopPropagation();
    if ($event != undefined && $event != null && $event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if ($event != undefined && $event != null && $event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    this.showTrackInfo[pos] = !this.showTrackInfo[pos];
    if (this.showTrackInfo[pos]) {
      if (
        !("isRelated" in track) &&
        !("isSimilar" in track) &&
        !("isFromAlbum" in track)
      ) {
        this.updateContainer();
        this.value = +this.pager.currentPage;
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService
          .getRatingAndComments(track.metadataID)
          .subscribe((data) => {
            if (data != null) {
              var xaMyComments = data["MyComments"].split("~");
              track.MyComments = xaMyComments[0];
              track.MyRating = data["MyRating"];
              if (xaMyComments[1]!="") {track.genres = xaMyComments[1].split(";");} else {track.genres = "";}
              if (xaMyComments[2]!="") {track.moods = xaMyComments[2].split(";");} else {track.moods = "";}
              if (xaMyComments[3]!="") {track.instruments = xaMyComments[3].split(";");} else {track.instruments = "";}
              if (xaMyComments[4]!="") {track.tempos = xaMyComments[4].split(";");} else {track.tempos = "";}
              if (xaMyComments[5]!="") {track.periods = xaMyComments[5].split(";");} else {track.periods = "";}
              if (xaMyComments[6]!="") {track.keys = xaMyComments[6].split(";");} else {track.keys = "";}
              if (xaMyComments[7]!="") {track.keywords = xaMyComments[7]} else {track.keywords = "";}
            }
          });
      } else {
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (j != i) {
            this.showTrackInfo[j] = false;
          }
        }
        track.star1Over = false;
        track.star2Over = false;
        track.star3Over = false;
        track.star4Over = false;
        track.star5Over = false;
        track.MyComments = null;
        track.MyRating = null;
        this.searchService
          .getRatingAndComments(track.metadataID)
          .subscribe((data) => {
            if (data != null) {
              var xaMyComments = data["MyComments"].split("~");
              track.MyComments = xaMyComments[0];
              track.MyRating = data["MyRating"];

              if (xaMyComments[1]!="") {track.genres = xaMyComments[1].split(";");} else {track.genres = "";}
              if (xaMyComments[2]!="") {track.moods = xaMyComments[2].split(";");} else {track.moods = "";}
              if (xaMyComments[3]!="") {track.instruments = xaMyComments[3].split(";");} else {track.instruments = "";}
              if (xaMyComments[4]!="") {track.tempos = xaMyComments[4].split(";");} else {track.tempos = "";}
              if (xaMyComments[5]!="") {track.periods = xaMyComments[5].split(";");} else {track.periods = "";}
              if (xaMyComments[6]!="") {track.keys = xaMyComments[6].split(";");} else {track.keys = "";}
              if (xaMyComments[7]!="") {track.keywords = xaMyComments[7]} else {track.keywords = "";}
            }
          });
      }
    }
  }

  addCommentAndRating(track, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    const rating = track.MyRating;
    const textarea = document.getElementById(
      "comments-" + track.guid
    ) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      userId: this.authService.user.id,
      contextJ: track.Context1J,
      contextK: track.Context1K,
      track: track.track,
      comment: comments,
      rate: rating,
    };

    this.searchService.setRatingAndComments(obj).subscribe((data) => {});
  }

  removeRating(track, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    track.MyRating = "";
    const rating = track.MyRating;
    const textarea = document.getElementById(
      "comments-" + track.guid
    ) as HTMLTextAreaElement;
    const comments = textarea.value;
    const obj = {
      userId: this.authService.user.id,
      contextJ: track.Context1J,
      contextK: track.Context1K,
      track: track.track,
      comment: comments,
      rate: rating,
    };
    this.searchService.setRatingAndComments(obj).subscribe((data) => {});
  }

  setPage(page: number, action = null) {
    if (page < 1 || page > this.pager.totalPages || isNaN(page)) {
      return;
    }
	
    this.showAlbuns = [];
    this.showTracks = [];
    this.showTrackInfo = [];

    // get pager object from service
    if (!this.firstTimeSearchingTerm) {
      this.loadingTracks = true;
      if (this.pageSubscriber !== null) {
        this.pageSubscriber.unsubscribe();
      }
      if (
        this.saveState.getLastSearchChips().length == 1 &&
        this.saveState.getLastSearchChips()[0].chips.length == 1 &&
        (this.saveState
          .getLastSearchChips()[0]
          .chips["0"].hasOwnProperty("isFile") ||
          this.isLink(this.saveState.getLastSearchChips()[0].chips["0"].name))
      ) {
        const obj = this.returnSearchObj(null, false);
        this.dataService.setLastSearchObj(obj);

		// PG: WaveSurfer dos Similares (desabilitado): $$$$$
        if (obj["searchObj"][0].hasOwnProperty("linkSimilarxxx")) {
          var duration = window["wavesurferFile"].getDuration();
          let new_obj_search = obj;
          new_obj_search["searchObj"][0].starttime =
            $("#min-time").val() != undefined
              ? this.toSeconds($("#min-time").val())
              : 0;
          new_obj_search["searchObj"][0].endtime =
            $("#max-time").val() != undefined
              ? this.toSeconds($("#max-time").val())
              : Math.floor(duration) > 60
              ? 60
              : Math.floor(duration);
          this.chips[0].starttime =
            $("#min-time").val() != undefined
              ? this.toSeconds($("#min-time").val())
              : 0;
          this.chips[0].endtime =
            $("#max-time").val() != undefined
              ? this.toSeconds($("#max-time").val())
              : Math.floor(duration) > 60
              ? 60
              : Math.floor(duration);
          let saveStateObj = {
            chips: this.chips,
            searchLine: 1,
          };
          this.saveState.setLastSearchChips([saveStateObj]);
          this.dataService.setLastSearchObj(new_obj_search);
        }
		
        const lastObj = this.dataService.getLastSearchObj();
        this.findMusicaSubscribeCansel = this.searchService
          .findMusicsAims(lastObj["searchObj"], page)
          .subscribe((tks) => {
            this.processPageResults(tks, page, action);
          });
      } else {
        this.pageSubscriber = this.searchService
          .newfindMusics(this.dataService.getLastSearchObj()["searchObj"], page)
          .subscribe((tks) => {
            this.processPageResults(tks, page, action);
          });
      }
    } else {
      const number = this.authService.user.Pref_PageResults;
      this.itensPerPage = number === undefined ? this.itensPerPage : number;
      this.pager.currentPage = page;
      this.pager.pageSize = this.itensPerPage;
      this.pager.startPage = 1;
      this.pager.startIndex = 0;
      this.pager.endIndex = this.itensPerPage - 1;

      this.value = +this.pager.currentPage;
      this.saveState.setLastPageVisited(this.value);
      this.firstTimeSearchingTerm = false;
      this.updateMusics();
      this.goToTrack(0);
    }
  }

  processPageResults(tks, page, action) {
    this.indexGlobal = null;
    this.loadingTracks = false;
    this.findMusicaSubscribeCansel = null;
    let listMusica = [];
    this.searchMode = tks["GroupBy"];
    if (this.searchMode == "Title") {
      if (tks["Data"] != null) {
        listMusica = this.musicService.mountMusicList(
          tks["Data"],
          this.tonos,
          false,
          false,
          false,
          false
        );
      }
    } else if (this.searchMode == "Catalog") {
      this.catalogsSearchResult = tks["Data"];
      if (this.catalogsSearchResult != null) {
        this.catalogsSearchResult.forEach((catalog) => {
          catalog["AlbumList"].forEach((album) => {
            let musicsFromAlbum = [];
            album["TrackList"].forEach((track) => {
              musicsFromAlbum.push(track);
            });
            musicsFromAlbum = this.musicService.mountMusicList(
              musicsFromAlbum,
              this.tonos,
              false,
              false,
              false,
              false
            );
            album["finalTrackList"] = musicsFromAlbum;
            album["finalTrackList"].forEach((track) => {
              listMusica.push(track);
            });
          });
        });
      }
    } else if (this.searchMode == "Album") {
      this.albunsSearchResult = tks["Data"];
      if (this.albunsSearchResult != null) {
        this.albunsSearchResult.forEach((album) => {
          let musicsFromAlbum = [];
          album["TrackList"].forEach((track) => {
            musicsFromAlbum.push(track);
          });
          musicsFromAlbum = this.musicService.mountMusicList(
            musicsFromAlbum,
            this.tonos,
            false,
            false,
            false,
            false
          );
          album["finalTrackList"] = musicsFromAlbum;
          album["finalTrackList"].forEach((track) => {
            listMusica.push(track);
          });
        });
      }
      this.saveState.setLastFindMusic(listMusica);
      this.saveState.setLastFindAlbuns(this.catalogsSearchResult);
    }
    this.tracks = listMusica;
    this.saveState.setLastFindMusic(listMusica);
    if (this.tracks) {
      const number = this.authService.user.Pref_PageResults;
      this.itensPerPage = number === undefined ? this.itensPerPage : number;
      this.pager = this.pagerService.getPager(
        this.tracks.length,
        page,
        this.itensPerPage
      );
      this.pager.totalPages = tks["NumberOfPages"];
      this.pager.totalItems = tks["Total"];
      this.pager.currentPage = page;
    }
    this.value = +this.pager.currentPage;
    this.saveState.setLastPageVisited(this.value);
    this.tracks.forEach((track, iterador) => {
      track.isTrackSelected = false;
      this.playerHistory.selectedSongsSEARCH.forEach((selectedTrack) => {
        if (track.id == selectedTrack.id) {
          track.isTrackSelected = true;
        }
      });
    });
    if (action != null && action == "next") {
      this.goToTrack(1);
      let obj = {
        track: this.saveState.getLastFindMusic()[0],
        i: 0,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      this.dataService.updateTrackSelected(obj);
    } else if (action != null && action == "previous") {
      this.goToTrack(this.tracks.length);
      let obj = {
        track:
          this.saveState.getLastFindMusic()[
            this.saveState.getLastFindMusic().length - 1
          ],
        i: this.saveState.getLastFindMusic().length - 1,
        tracks: this.saveState.getLastFindMusic(),
        from: "search",
        chips: this.saveState.getLastSearchChips(),
      };
      this.dataService.updateTrackSelected(obj);
    }
    setTimeout(() => {
      if (this.selectedTrack != null) {
        if ($(this.selectedTrack.container).length > 0) {
          const iterator = parseInt(
            $(this.selectedTrack.container).data("iterador"),
            10
          );
          if (this.tracks[iterator] != undefined) {
            this.indexGlobal = iterator;
            this.tracks[iterator].isPlaying = true;
          }
        }
      }
      this.goToTrack(0);
    }, 0);
    this.updateMusics();
  }

  updateMusics() {
    setTimeout(() => {
      this.tracks.forEach((track, it) => {
        let containerIt = it;
        track.container =
          "#track-" + containerIt + "-" + track.metadataID + "-progress";
        track.timeContainer =
          "#currentTime-track-" + containerIt + "-" + track.metadataID;
        if (this.dataService.getFPobject() != null) {
          for (
            let index = 0;
            index < this.dataService.getFPobject()["tracks"].length;
            index++
          ) {
            const fpTrack = this.dataService.getFPobject()["tracks"][index];
            if (fpTrack.id == track.id) {
              fpTrack.container = track.container;
              fpTrack.timeContainer = track.timeContainer;
              track.currentTime = fpTrack.currentTime;
              track.currentTimeDisplay = fpTrack.currentTimeDisplay;
              break;
            }
          }
        }
        if (
          this.dataService.getFPobject() != null &&
          this.dataService.getFPobject()["track"] != null &&
          track.id == this.dataService.getFPobject()["track"].id
        ) {
          this.dataService.getFPobject()["track"].container = track.container;
          this.dataService.getFPobject()["track"].timeContainer =
            track.timeContainer;
        }
        const width = (track.currentTime * 100) / track.durationSecs;
        if (width != 0 && width != 100) {
          track.playerWidth = width + "%";
          // track.borderRight = '1px solid black';
        }
      });
    }, 10);
  }

  checkIfAlreadyPlayed(track) {
    if (
      track.alreadyPlayed &&
      $(track.timeContainer).text().trim() == "00:00" &&
      (this.selectedTrack == null ||
        (this.selectedTrack != null && this.selectedTrack.id != track.id))
    ) {
      return "alreadyPlayed";
    }
    return "";
  }

  setTabCols(): void {
    if ($(window).width() >= 1200) {
      this.tabCols = 6;
    } else if ($(window).width() >= 992) {
      this.tabCols = 4;
    } else if ($(window).width() >= 640) {
      this.tabCols = 4;
      this.tabCols = 2;
    } else if ($(window).width() < 320) {
      this.tabCols = 2;
    }

    const self = this;

    this.genres["A"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["A"].length / self.tabCols)
      );
    });
    this.genres["C"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["C"].length / self.tabCols)
      );
    });
    this.genres["D"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["D"].length / self.tabCols)
      );
    });
    this.genres["E"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["E"].length / self.tabCols)
      );
    });
    this.genres["F"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["E"].length / self.tabCols*2)
      );
    });
    this.genres["Q"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["Q"].length / self.tabCols)
      );
    });

    let contextsNEW = "";
    for (let it = 0; it < this.genres["J"].length; it++) {
      const catalog = this.genres["J"][it];
      if (catalog["new"]) {
        this.isNewReleasesChipActive = true;
        contextsNEW += catalog.context + ",";
      }
      if (catalog["pref"]) {
        this.isPreferedChipActive = true;
      }
    }
    contextsNEW = contextsNEW.substring(0, contextsNEW.length - 1);

    let prefCatalogs =
      this.authService.user.Context2J_PrefCatalogs != undefined
        ? this.authService.user.Context2J_PrefCatalogs.split(" ").join(",")
        : "";

    this.context_Preferences = {
      name: this.i18n.getTranslationsBD("preferred-catalogues"),
      context: prefCatalogs,
      containerId: 0,
      subgenres: [],
      contextName: undefined,
      open: false,
      catalogos: true,
      isPref: true,
    };

    this.context_newReleases = {
      name: this.i18n.getTranslationsBD("new-releases"),
      context: contextsNEW,
      containerId: 0,
      subgenres: [],
      contextName: undefined,
      open: false,
      catalogos: true,
      isNew: true,
    };

    if (this.isPreferedChipActive) {
      this.genres["J"].unshift(this.context_Preferences);
    }

    if (this.isNewReleasesChipActive) {
      this.genres["J"].unshift(this.context_newReleases);
    }

    this.genres["J"].forEach(function (item, index) {
      item.containerId = Math.floor(
        index / (self.genres["J"].length / self.tabCols)
      );
    });
  }

  getGenres(): void {
    // PG: Genres
    this.genres = [];
    let searchParmetersSize = this.searchParmeters.length;
    let searchText =
      searchParmetersSize == 1 ? this.searchParmeters[0]["text"] : "";
    let searchContext =
      searchParmetersSize == 1 ? this.searchParmeters[0]["context"] : "";
    if (this.genresSubscriber !== null) {
      this.genresSubscriber.unsubscribe();
    }
    if (this.isResult) {
      if (!this.isSearchBlocked) {
        this.genresSubscriber = this.genreService
          .getGenresSearch(searchText, searchContext)
          .subscribe((genres) => {
            const tempGenres = this.processGeneres(genres);
            this.saveState.saveInitialGenres(tempGenres);
            this.groupGenres(tempGenres);
          });
      }
    } else {
      let already_genres = this.saveState.getInitialGenres();
      if (already_genres != null) {
        const tempGenres = already_genres;
        this.groupGenres(tempGenres);
      }
      this.genresSubscriber = this.genreService
        .getGenres()
        .subscribe((genres) => {
          const tempGenres = this.processGeneres(genres);
          this.saveState.saveInitialGenres(tempGenres);
          this.groupGenres(tempGenres);
        });
    }
  }

  groupGenres(tempGenres) {
    this.tonos = this.getGenresByLetter(tempGenres["Q"], "Q");
    this.genres["A"] = this.getGenresByLetter(tempGenres["A"], "A");
    this.genres["C"] = this.getGenresByLetter(tempGenres["C"], "C");
    this.genres["D"] = this.getGenresByLetter(tempGenres["D"], "D");
    this.genres["E"] = this.getGenresByLetter(tempGenres["E"], "E");
    this.genres["F"] = this.getGenresByLetter(tempGenres["F"], "F");
    this.genres["Q"] = this.getGenresByLetter(tempGenres["Q"], "Q");
    this.genres["J"] = this.getGenresByLetter(tempGenres["J"], "J");
    for (let type in this.genres) {
      for (let genre in this.genres[type]) {
        this.genres[type][genre].open = false;
      }
    }
    this.setTabCols();
  }

  processGeneres(genres) {
    const temp_genres = {
      A: [],
      C: [],
      D: [],
      E: [],
      F: [],
      J: [],
      Q: [],
    };
    genres.forEach((genre) => {
      const obj = {
        name: genre.Ctg,
        context: genre.Ctx,
        subCategory: [],
      };
      genre.SCtg.forEach((subCat) => {
        obj.subCategory.push({
          name: subCat.Ctg,
          contextName: "",
          context: subCat.SCtx,
        });
      });

      if (genre.Tp == "A") {
        temp_genres.A.push(obj);
      } else if (genre.Tp == "C") {
        temp_genres.C.push(obj);
      } else if (genre.Tp == "D") {
        temp_genres.D.push(obj);
      } else if (genre.Tp == "E") {
        temp_genres.E.push(obj);
      } else if (genre.Tp == "F") {
        temp_genres.F.push(obj);
      } else if (genre.Tp == "J") {
        obj["new"] = genre.IsNewRelease;
        obj["pref"] = genre.IsPreferred;
        temp_genres.J.push(obj);
      } else if (genre.Tp == "Q") {
        temp_genres.Q.push(obj);
      }
    });
    return temp_genres;
  }

  getGenresByLetter(_genres, letter): any[] {
    const lista = [];
    for (const key in _genres) {
      if (_genres.hasOwnProperty(key)) {
        const element = _genres[key];
        const subgenres = [];
        for (const key2 in element.subCategory) {
          if (element.subCategory.hasOwnProperty(key2)) {
            const subelement = element.subCategory[key2];
            subgenres.push({
              name: subelement.name,
              context: subelement.context,
              contextName: subelement.contextName,
            });
          }
        }

        let obj = {
          name: element.name,
          context: element.context,
          containerId: 0,
          contextName: element.contextName,
        };
        if (subgenres.length > 0) {
          obj["subgenres"] = subgenres;
        }
        if (letter == "J") {
          obj["new"] = element.new;
          obj["pref"] = element.pref;
        }

        lista.push(obj);
      }
    }
    return lista;
  }

  getNameByGenres(genre): String {
    return genre.name;
  }

  getProjects(): void {
    this.projectService.getProjects().subscribe((projects) => {
      this.projects = projects["Data"];
      this.dataService.updateProjects(this.projects);
      if (this.projects.length > 0) {
        if (this.url != undefined && this.url.includes("/projects/")) {
          let projectID = this.url.split("/")[2];
          if (projectID !== undefined) {
            let myProject;
            if (this.projects.length > 0) {
              this.projects.forEach(function (project) {
                if (project.ProjectsID == projectID) {
                  myProject = project;
                }
              });
              this.selectedProject = myProject;
              this.getSelectedProjectsTracks(this.selectedProject);
              this.dataService.updateSelectedProject(myProject);
            }
          }
        } else {
          let lastModified;
          if (this.selectedProject != undefined) {
            lastModified = this.selectedProject;
          } else {
            lastModified = this.projects[0];
          }
          this.selectedProject = lastModified;
          this.getSelectedProjectsTracks(this.selectedProject);
          this.dataService.updateSelectedProject(lastModified);
        }
      }
      this.loadingProjects = false;
    });
  }

  playSingle() {
    this.isProjectOptions = false;
    this.router.navigate([
      "./projects/" + this.selectedProject.ProjectsID + "/playSingle",
    ]);
  }

  playAll() {
    this.isProjectOptions = false;
    this.router.navigate([
      "./projects/" + this.selectedProject.ProjectsID + "/playAll",
    ]);
  }

  playShuffle() {
    this.isProjectOptions = false;
    this.router.navigate([
      "./projects/" + this.selectedProject.ProjectsID + "/playShuffle",
    ]);
  }

  showRelated(event, i, track) {
    if (event != undefined && event != null && event.ctrlKey) {
      event.stopPropagation();
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    this.wasDBL = true;
    event.stopPropagation();
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
    setTimeout(() => {
      this.wasDBL = false;
    }, 500);
    if (
      !("isRelated" in track) &&
      !("isSimilar" in track) &&
      !("isFromAlbum" in track)
    ) {
      let pos = i;
      this.showTrackRelatedOrSimilar[i] = !this.showTrackRelatedOrSimilar[i];
      if (this.showTrackRelatedOrSimilar[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (
            "isRelated" in this.tracks[j] ||
            "isSimilar" in this.tracks[j] ||
            "isFromAlbum" in this.tracks[j]
          ) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackRelatedOrSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackSimilar[j] = false;
          this.showAlbumTracks[j] = false;
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        pos -= decrement;
        this.loadingTracks = true;
        this.searchService.findMusicsRelated(track.id).subscribe((data) => {
          let musics = this.musicService.mountMusicList(
            data,
            this.tonos,
            true,
            false,
            false,
            false
          );
          musics = this.cleanActualMusic(track, musics);
          const start = pos + 1;
          this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
          if (this.searchMode == "Catalog") {
            this.insertSecondaryMusicsSearchCatalogs(track, musics);
          }
          if (this.searchMode == "Album") {
            this.insertSecondaryMusicsSearchAlbum(track, musics);
          }
          this.updateContainer();
          this.checkSelected();
          this.loadingTracks = false;
        });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (
            !("isRelated" in this.tracks[id]) &&
            !("isSimilar" in this.tracks[id]) &&
            !("isFromAlbum" in this.tracks[id])
          ) {
            break;
          } else {
            if ("isRelated" in this.tracks[id]) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  insertSecondaryMusicsSearchAlbum(track, musics) {
    let finalAlbum = null;
    let finalTrackIndex = null;

    for (let indexA = 0; indexA < this.albunsSearchResult.length; indexA++) {
      const album = this.albunsSearchResult[indexA];
      const albumTracks = album["finalTrackList"];
      for (let indexT = 0; indexT < albumTracks.length; indexT++) {
        const albumTrack = albumTracks[indexT];
        if (albumTrack.id == track.id) {
          finalAlbum = indexA;
          finalTrackIndex = indexT;
          break;
        }
      }
      if (finalAlbum != null) {
        break;
      }
    }

    for (let it = 0; it < musics.length; it++) {
      const music = musics[it];
      this.albunsSearchResult[finalAlbum]["finalTrackList"].splice(
        finalTrackIndex + it + 1,
        0,
        music
      );
    }
  }

  removeSecondaryMusicsSearchAlbum() {
    this.albunsSearchResult.forEach((album) => {
      for (
        let index = album["finalTrackList"].length - 1;
        index >= 0;
        index--
      ) {
        const musica = album["finalTrackList"][index];
        if (
          "isRelated" in musica ||
          "isSimilar" in musica ||
          "isFromAlbum" in musica
        ) {
          album["finalTrackList"].splice(index, 1);
        }
      }
    });
  }

  insertSecondaryMusicsSearchCatalogs(track, musics) {
    let finalCatalog = null;
    let finalAlbum = null;
    let finalTrackIndex = null;
    for (let indexC = 0; indexC < this.catalogsSearchResult.length; indexC++) {
      const catalog = this.catalogsSearchResult[indexC];
      const albuns = catalog["AlbumList"];
      for (let indexA = 0; indexA < albuns.length; indexA++) {
        const album = albuns[indexA];
        const albumTracks = album["finalTrackList"];
        for (let indexT = 0; indexT < albumTracks.length; indexT++) {
          const albumTrack = albumTracks[indexT];
          if (albumTrack.id == track.id) {
            finalCatalog = indexC;
            finalAlbum = indexA;
            finalTrackIndex = indexT;
            break;
          }
        }
        if (finalAlbum != null) {
          break;
        }
      }
      if (finalCatalog != null) {
        break;
      }
    }
    for (let it = 0; it < musics.length; it++) {
      const music = musics[it];
      this.catalogsSearchResult[finalCatalog]["AlbumList"][finalAlbum][
        "finalTrackList"
      ].splice(finalTrackIndex + it + 1, 0, music);
    }
  }

  removeSecondaryMusicsSearchCatalog() {
    this.catalogsSearchResult.forEach((catalog) => {
      catalog["AlbumList"].forEach((album) => {
        for (
          let index = album["finalTrackList"].length - 1;
          index >= 0;
          index--
        ) {
          const musica = album["finalTrackList"][index];
          if (
            "isRelated" in musica ||
            "isSimilar" in musica ||
            "isFromAlbum" in musica
          ) {
            album["finalTrackList"].splice(index, 1);
          }
        }
      });
    });
  }

  showSimilars(event, i, track) {
    event.stopPropagation();
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    if (
      !("isRelated" in track) &&
      !("isSimilar" in track) &&
      !("isFromAlbum" in track)
    ) {
      let pos = i;
      this.showTrackSimilar[i] = !this.showTrackSimilar[i];
      if (this.showTrackSimilar[i]) {
        let decrement = 0;
        for (let j = this.tracks.length - 1; j >= 0; j--) {
          if (
            "isRelated" in this.tracks[j] ||
            "isSimilar" in this.tracks[j] ||
            "isFromAlbum" in this.tracks[j]
          ) {
            this.tracks.splice(j, 1);
            if (j < pos) {
              decrement++;
            }
          }
          if (j != i) {
            this.showTrackSimilar[j] = false;
          }
          this.showTrackInfo[j] = false;
          this.showTrackRelatedOrSimilar[j] = false;
          this.showAlbumTracks[j] = false;
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        pos -= decrement;
        this.loadingTracks = true;
        this.searchService
          .findMusicsSimilars(track.id, track.titleId)
          .subscribe((data) => {
            let musics = this.musicService.mountMusicList(
              data,
              this.tonos,
              false,
              true,
              false,
              false
            );
            musics = this.cleanActualMusic(track, musics);
            const start = pos + 1;
            this.tracks.splice.apply(this.tracks, [start, 0].concat(musics));
            if (this.searchMode == "Catalog") {
              this.insertSecondaryMusicsSearchCatalogs(track, musics);
            }
            if (this.searchMode == "Album") {
              this.insertSecondaryMusicsSearchAlbum(track, musics);
            }
            this.updateContainer();
            this.checkSelected();
            this.loadingTracks = false;
          });
      } else {
        let idsToRemove = [];
        for (let id = pos + 1; id < this.tracks.length; id++) {
          if (
            !("isRelated" in this.tracks[id]) &&
            !("isSimilar" in this.tracks[id]) &&
            !("isFromAlbum" in this.tracks[id])
          ) {
            break;
          } else {
            if ("isSimilar" in this.tracks[id]) {
              this.showTrackInfo[id] = false;
              this.showTrackSimilar[id] = false;
              this.showAlbumTracks[id] = false;
              idsToRemove.push(id);
            }
          }
        }
        for (let j = idsToRemove.length - 1; j >= 0; j--) {
          this.tracks.splice(idsToRemove[j], 1);
        }
        if (this.searchMode == "Catalog") {
          this.removeSecondaryMusicsSearchCatalog();
        }
        if (this.searchMode == "Album") {
          this.removeSecondaryMusicsSearchAlbum();
        }
        this.updateContainer();
        this.checkSelected();
      }
    }
  }

  checkSelected() {
    this.tracks.forEach((track) => {
      track.isTrackSelected = false;
      this.playerHistory.selectedSongsSEARCH.forEach((selectedTrack) => {
        if (track.id == selectedTrack.id) {
          track.isTrackSelected = true;
        }
      });
    });
  }

  removeExtraSounds(page) {
    this.firstTimeSearchingTerm = false;
    this.setPage(parseInt(page, 10));
  }

  compare(string1, string2) {
    let str1 = string1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let str2 = string2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (str1.includes(str2)) {
      return true;
    } else {
      return false;
    }
  }

  newCompare(string1, string2, genre) {
    let str1 = string1.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let str2 = string2.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (genre.open) {
      let exists = false;
      for (let i = 0; i < genre.subgenres.length; i++) {
        let str3 = genre.subgenres[i].name.toLowerCase();
        if (str3.includes(str2)) {
          exists = true;
          break;
        }
      }
      if (exists) {
        return true;
      }
    }

    if (str1.includes(str2)) {
      return true;
    } else {
      return false;
    }
  }

  closeAllSubGenres(type) {
    for (let a = 0; a < this.genres[type].length; a++) {
      this.genres[type][a].open = false;
    }
  }

  onFocus() {
    const inputElem = <HTMLInputElement>this.pageInput.nativeElement;
    inputElem.select();
  }

  getReport(tipo) {
    this.projectService
      .getProjectTracks(this.selectedProject.ProjectsID)
      .subscribe((tks) => {
        const listMusica = this.musicService.mountMusicList(
          tks,
          this.tonos,
          false,
          false,
          false,
          true
        );
        let isAnyUsed = false;
        for (let i = 0; i < listMusica.length; i++) {
          if (
            listMusica[i]["percentageUsed"].trim() != "0:00" &&
            listMusica[i]["percentageUsed"].trim() != "00:00"
          ) {
            isAnyUsed = true;
          }
        }
        if (isAnyUsed) {
          let link =
            environment.baseUrl +
            "report/" +
            tipo +
            "/" +
            this.selectedProject.ProjectsID +
            "/?token=" +
            this.authService.user.token;
          let a = document.createElement("a");
          a.setAttribute("type", "hidden"); // make it hidden if needed
          a.href = link;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          alert(this.i18n.getTranslationsBD("titles-without-duration-used"));
        }
      });
  }

  checkIfDeleteChip(chip, i) {
    setTimeout(() => {
      const texto = $("#chip" + i).text();
      if (texto.length == 0) {
        this.deleteChip(chip);
      }
    }, 10);
  }

  checkIfDeleteMoreChip(chip, i) {
    setTimeout(() => {
      const texto = $("#secondChip" + i).text();
      if (texto.length == 0) {
        this.deleteMoreChip(chip);
      }
    }, 10);
  }

  checkIfDeleteSecondMoreChip(chip, i) {
    setTimeout(() => {
      const texto = $("#secondMoreChip" + i).text();
      if (texto.length == 0) {
        this.deleteSecondMoreChip(chip);
      }
    }, 10);
  }

  checkIfDeleteChipFromSearchBar() {
    const el = document.getElementById("row-one") as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.chips.length > 0) {
        const tempChip = this.chips[this.chips.length - 1];
        const text = tempChip.name + " ";
        this.deleteChip(tempChip);
        el.value = text;
      }
    }
  }

  checkIfDeleteMoreChipFromSearchBar() {
    const el = document.getElementById("row-two") as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.moreChips.length > 0) {
        const tempChip = this.moreChips[this.moreChips.length - 1];
        const text = tempChip.name + " ";
        this.deleteMoreChip(tempChip);
        el.value = text;
      }
    }
  }

  checkIfDeleteSecondChipFromSearchBar() {
    const el = document.getElementById("row-three") as HTMLInputElement;
    const value = el.value;
    if (value.length == 0) {
      if (this.secondMoreChips.length > 0) {
        const tempChip = this.secondMoreChips[this.secondMoreChips.length - 1];
        const text = tempChip.name + " ";
        this.deleteSecondMoreChip(tempChip);
        el.value = text;
      }
    }
  }

  changeValueInSecondaryInput(index) {
    const el = document.getElementById(
      "secondary-input" + index
    ) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  changeValueInSecondaryInputMore(index) {
    const el = document.getElementById(
      "secondary-input-more" + index
    ) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  changeValueInThirdInputMore(index) {
    const el = document.getElementById(
      "secondary-input-second-more" + index
    ) as HTMLInputElement;
    const value = el.value;
    if (value.length > 0) {
      el.style.width = "auto";
    } else {
      el.style.width = "5px";
    }
  }

  addChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById(
      "secondary-input" + i
    ) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";

    if (value.trim() != "") {
      this.addChip(value.replace(new RegExp("_", "g"), " "), i);
    }
  }

  addMoreChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById(
      "secondary-input-more" + i
    ) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";
    if (value.trim() != "") {
      this.addMoreChip(value, i);
    }
  }

  addSecondChipInAbsolutePosition(event, i) {
    event.stopPropagation();
    const el = document.getElementById(
      "secondary-input-second-more" + i
    ) as HTMLInputElement;
    const value = el.innerText;
    el.innerText = "";
    if (value.trim() != "") {
      this.addSecondMoreChip(value, i);
    }
  }

  changeProjectDDL(project) {
    if (this.router.url.includes("projects")) {
      this.setProject(project, "in");
    } else {
      this.setProject(project, "out");
    }
  }

  getRecentSearchs() {
    this.recentSearchs = this.playerHistory.getRecentSearchs();
  }

  setSearch(search) {
    if (search.length == 1) {
      if (search.hasOwnProperty("searchType")) {
        this.isAddRemoveLine = true;
        this.isAdd = false;
        this.isSecondAdd = false;
        this.chips = search[0].chips;
        this.searchDropdownItems.forEach((element) => {
          if (element["value"] == search[0].searchType) {
            this.searchDropdownItem = element;
            return;
          }
        });
      } else {
        this.isAddRemoveLine = false;
        this.isAdd = false;
        this.isSecondAdd = false;
        this.chips = search[0].chips;
      }
    } else if (search.length == 2) {
      this.isAddRemoveLine = true;
      this.isAdd = true;
      this.isSecondAdd = false;
      this.chips = search[0].chips;
      this.searchDropdownItems.forEach((element) => {
        if (element["value"] == search[0].searchType) {
          this.searchDropdownItem = element;
          return;
        }
      });

      this.moreChips = search[1].chips;
      this.moreSearchDropdownItemsRight.forEach((element) => {
        if (element["value"] == search[1].searchType) {
          this.moreSearchDropdownItemRight = element;
          return;
        }
      });
      this.moreSearchDropdownItemLeft = search[1].searchOperator;
    } else if (search.length == 3) {
      this.isAddRemoveLine = true;
      this.isAdd = true;
      this.isSecondAdd = true;
      this.chips = search[0].chips;
      this.searchDropdownItems.forEach((element) => {
        if (element["value"] == search[0].searchType) {
          this.searchDropdownItem = element;
          return;
        }
      });
      this.moreChips = search[1].chips;
      this.moreSearchDropdownItemsRight.forEach((element) => {
        if (element["value"] == search[1].searchType) {
          this.moreSearchDropdownItemRight = element;
          return;
        }
      });
      this.moreSearchDropdownItemLeft = search[1].searchOperator;
      this.secondMoreChips = search[2].chips;
      this.secondMoreSearchDropdownItemsRight.forEach((element) => {
        if (element["value"] == search[2].searchType) {
          this.secondMoreSearchDropdownItemRight = element;
          return;
        }
      });
      this.secondMoreSearchDropdownItemLeft = search[2].searchOperator;
    }
    this.search();
  }

  markAsFavourite(search) {
    this.playerHistory.addFavouriteSearch(search);
  }

  getFavouriteSearchs() {
    this.favouriteSearches = this.playerHistory.getFavouriteSearchs();
  }

  searchProjectsDDL(title) {
    const lowerCaseSearch = this.searchProjectName.toLowerCase();
    const projectTitle = title.toLowerCase();
    if (projectTitle.includes(lowerCaseSearch)) {
      return true;
    } else {
      return false;
    }
  }

  checkFreeze() {
    if ($("#freeze").hasClass("noHeight")) {
      return "";
    } else {
      return "paddingBottomFP";
    }
  }

  checkWidth() {
    // if($("body").height() > $(window).height()) {
    //   return 'calc(100vw - 20px)';
    // } else {
    return "100%";
    // }
  }

  checkIfSelected(genre) {
    let alreadySelected = false;

    this.chips.forEach((chip) => {
      if (chip.name == genre.name && chip.context == genre.context) {
        alreadySelected = true;
        return;
      }
    });
    if (alreadySelected) {
      let styles = {
        color: "#f16824",
        "font-weight": "bold",
      };
      return styles;
    } else {
      let styles = {
        color: "#676767",
        "font-weight": "regular",
      };
      return styles;
    }
  }

  updateChipValue(id, chip) {
    event.preventDefault();
    const texto = $("#" + id).text();
    chip.name = texto;
    if (chip.hasOwnProperty("context")) {
      delete chip.context;
      this.isSearchBlocked = false;
    }
  }

  checkIfSearch() {
    const minBPM = $("#min-BPM").val();
    const maxBPM = $("#max-BPM").val();
    const minDUR = $("#min-DUR").val();
    const maxDUR = $("#max-DUR").val();

    if (
      minBPM.trim() != "" ||
      maxBPM.trim() != "" ||
      minDUR.trim() != "" ||
      maxDUR.trim() != ""
    ) {
      this.search();
      this.closeTabs();
    }
  }

  showUserBubble($event, value) {
    $event.stopPropagation();
    if (!this.authService.isLoggedIn) {
      // $event.preventDefault();
      if (value == false) {
        const evtTarget = $event.target;
        if (evtTarget.classList.contains("speech-bubble-element")) {
          this.isUserRememberBubble = true;
        } else {
          this.isUserRememberBubble = value;
        }
      } else {
        this.isUserRememberBubble = value;
      }
      this.isPasswordRememberBubble = false;
    } else {
      $event.preventDefault();
      this.isUserRememberBubble = false;
      this.isPasswordRememberBubble = false;
    }
  }

  checkBooleansState() {
    let suggestionsArray =
      localStorage.getItem("user_suggestions") != null
        ? JSON.parse(localStorage.getItem("user_suggestions"))
        : [];
    if (this.model.username.trim() != "") {
      if (suggestionsArray.length > 0) {
        let targetSuggestion = null;
        for (let it = 0; it < suggestionsArray.length; it++) {
          const suggestion = suggestionsArray[it];
          if (suggestion.user == this.model.username) {
            targetSuggestion = suggestion;
            break;
          }
        }
        if (targetSuggestion == null) {
          this.rememberUser = false;
          this.rememberPass = false;
        } else {
          if (targetSuggestion.show) {
            this.rememberUser = true;
          } else {
            this.rememberUser = false;
          }
        }
      }
    } else {
      this.rememberUser = false;
    }
  }

  showPasswordBubble($event, value) {
    $event.stopPropagation();

    if (!this.authService.isLoggedIn) {
      $event.preventDefault();
      this.isUserRememberBubble = false;
      if (value == false) {
        const evtTarget = $event.target;
        if (evtTarget.classList.contains("speech-bubble-element")) {
          this.isPasswordRememberBubble = true;
        } else {
          this.isPasswordRememberBubble = value;
        }
      } else {
        this.isPasswordRememberBubble = value;
      }
    } else {
      $event.preventDefault();
      this.isUserRememberBubble = false;
      this.isPasswordRememberBubble = false;
    }
  }

  menuSearch($event) {
    this.addChip($event["srcElement"].value, null);
  }

  checkSearchDropdownItem(term) {
    switch (term) {
      case "Lyrics":
      case "Artist":
      case "MyComments":
      case "MyRatings":
        return true;
      default:
        return false;
    }
  }

  keyDowonFromFastSearch(event) {
    if (
      this.authService.isLoggedIn &&
      !this.router.url.includes("search") &&
      (this.router.url.includes("catalogos") ||
        this.router.url.includes("playlists") ||
        this.router.url.includes("projects") ||
        this.router.url.includes("sfx"))
    ) {
      if (event.key === "Enter") {
        event.stopPropagation();
        const rowMenuElement: HTMLInputElement = document.getElementById(
          "row-menu"
        ) as HTMLInputElement;
        if (rowMenuElement != null) {
          if (rowMenuElement.value !== "") {
            this.router.navigate(["search"]);
            this.search(true);
          }
        }
      }
    }
  }

  setZIndex(id) {
    if (
      $(".track-download-dropdown").is(":visible") ||
      $(".track-projects-dropdown").is(":visible") ||
      $(".track-actions-mobile").is(":visible")
    ) {
    } else {
      $(".track").css("z-index", "1");
      $("#" + id).css("z-index", "12");
    }
  }

  setZIndexForce(id, event, i) {
    if (event != undefined && event != null && event.ctrlKey) {
      this.selectTrack(null, i, this.tracks);
      return;
    }
    if (event != undefined && event != null && event.shiftKey) {
      this.checkTracksToSelect(i);
      return;
    }
    $(".track").css("z-index", "1");
    $("#" + id).css("z-index", "12");
  }

  manageResultsTopPosition() {
    if (
      window["wavesurferFile"] != undefined &&
      window["wavesurferFile"] != null
    ) {
      return "waveFileOpen";
    }
    if (this.dataService.getIfLinkIsSetted()) {
      return "searchByLink";
    }
    if (this.isAddRemoveLine && !this.isAdd && !this.isSecondAdd) {
      return "oneLineOpen";
    } else if (this.isAddRemoveLine && this.isAdd && !this.isSecondAdd) {
      return "twoLineOpen";
    } else if (this.isAddRemoveLine && this.isAdd && this.isSecondAdd) {
      return "threeLineOpen";
    }
    return "";
  }

  //////////////// TESTE NAVEGAÇÃO CHIPS ////////////////
  //1a LINHA
  chipsNavitagion(evt, side, from) {
    evt.stopPropagation();
    const value =
      from == "row-one"
        ? $("#row-one").val()
        : $("#secondary-input" + from).text();
    if (value.trim() != "") {
      return;
    }
    const focused = $(":focus");
    const id = focused.attr("id");
    if (side == "previous") {
      if (id == "row-one") {
        if (this.chips.length > 0) {
          $("#secondary-input" + (this.chips.length - 1)).focus();
        }
      } else if (focused.hasClass("secondary-search-input")) {
        const focusedIt = Number(id.replace("secondary-input", ""));
        const targetId = focusedIt - 1;
        if (targetId >= 0) {
          $("#secondary-input" + targetId).focus();
        }
      }
    } else {
      if (focused.hasClass("secondary-search-input")) {
        const focusedIt = Number(id.replace("secondary-input", ""));
        const targetId = focusedIt + 1;
        if (targetId < this.chips.length) {
          $("#secondary-input" + targetId).focus();
        } else {
          $("#row-one").focus();
        }
      }
    }
  }

  checkIfDeleteChipInAbsolutePosition(evt, it, id) {
    let val;
    if (id == "row-one") {
      val = $("#" + id).val();
    } else {
      val = $("#" + id).text();
    }
    if (val.trim() != "") {
      return;
    }
    if (it == 0) {
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it - 1];
    this.checkIfFileChip(tempChip);
    this.checkIfLinkChip(tempChip);
    const content = tempChip.name;
    if (it == this.chips.length) {
      this.chips.pop();
      $("#row-one").val(content);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: "secondary-input" + id, value: "" });
      });
    } else {
      this.chips.splice(it - 1, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({
            input: "secondary-input" + id,
            value: "",
          });
        });
        $("#secondary-input" + (it - 1)).text(content);
        this.setCursor("secondary-input" + (it - 1), content.length);
      }, 0);
    }
  }

  setCursor(id, pos) {
    let tag = document.getElementById(id);
    let setpos = document.createRange();
    let set = window.getSelection();
    setpos.setStart(tag.childNodes[0], pos);
    setpos.collapse(true);
    set.removeAllRanges();
    set.addRange(setpos);
    tag.focus();
  }

  checkIfPreDeleteChipInAbsolutePosition(evt, it) {
    const val = $("#secondary-input" + it).text();
    if (val.trim() != "") {
      return;
    }
    evt.preventDefault();
    const tempChip = this.chips[it];
    this.checkIfFileChip(tempChip);
    this.checkIfLinkChip(tempChip);
    const content = tempChip.name;
    if (it == this.chips.length - 1) {
      this.chips.pop();
      $("#row-one").val(content);
      this.setSelectionRange(document.getElementById("row-one"), 0, 0);
      this.secondaryInputs = [];
      this.chips.forEach((chip, id) => {
        this.secondaryInputs.push({ input: "secondary-input" + id, value: "" });
      });
    } else {
      this.chips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({
            input: "secondary-input" + id,
            value: "",
          });
        });
        $("#secondary-input" + it)
          .text(content)
          .focus();
      }, 0);
    }
  }

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
  }

  checkIfChangeChip($event, side, i) {
    let posI = this.getCaretPosition(document.getElementById("chip" + i));
    setTimeout(() => {
      let posF = this.getCaretPosition(document.getElementById("chip" + i));
      if (side == "previous") {
        if (posI == 0 && posF == 0) {
          $("#secondary-input" + i).focus();
        }
      } else {
        if (
          posI == $("#chip" + i).text().length &&
          posF == $("#chip" + i).text().length
        ) {
          let next = i + 1;
          if (document.getElementById("secondary-input" + next) != null) {
            $("#secondary-input" + next).focus();
          } else {
            $("#row-one").focus();
          }
        }
      }
    }, 10);
  }

  //2a LINHA
  chipsMoreNavitagion(evt, side, from) {
    evt.stopPropagation();
    const value =
      from == "row-two"
        ? $("#row-two").val()
        : $("#secondary-input-more" + from).text();
    if (value.trim() != "") {
      return;
    }
    const focused = $(":focus");
    const id = focused.attr("id");
    if (side == "previous") {
      if (id == "row-two") {
        if (this.moreChips.length > 0) {
          $("#secondary-input-more" + (this.moreChips.length - 1)).focus();
        }
      } else if (focused.hasClass("secondary-search-input-more")) {
        const focusedIt = Number(id.replace("secondary-input-more", ""));
        const targetId = focusedIt - 1;
        if (targetId >= 0) {
          $("#secondary-input-more" + targetId).focus();
        }
      }
    } else {
      if (focused.hasClass("secondary-search-input-more")) {
        const focusedIt = Number(id.replace("secondary-input-more", ""));
        const targetId = focusedIt + 1;
        if (targetId < this.moreChips.length) {
          $("#secondary-input-more" + targetId).focus();
        } else {
          $("#row-two").focus();
        }
      }
    }
  }

  checkIfDeleteMoreChipInAbsolutePosition(evt, it, id) {
    let val;
    if (id == "row-two") {
      val = $("#" + id).val();
    } else {
      val = $("#" + id).text();
    }
    if (val.trim() != "") {
      return;
    }
    if (it == 0) {
      return;
    }
    evt.preventDefault();
    const tempChip = this.moreChips[it - 1];
    const content = tempChip.name;
    if (it == this.moreChips.length) {
      this.moreChips.pop();
      $("#row-two").val(content);
      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({
          input: "secondary-input-more" + id,
          value: "",
        });
      });
    } else {
      this.moreChips.splice(it - 1, 1);
      setTimeout(() => {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({
            input: "secondary-input-more" + id,
            value: "",
          });
        });
        $("#secondary-input-more" + (it - 1)).text(content);
        this.setCursor("secondary-input-more" + (it - 1), content.length);
      }, 0);
    }
  }

  checkIfPreDeleteMoreChipInAbsolutePosition(evt, it) {
    const val = $("#secondary-input-more" + it).text();
    if (val.trim() != "") {
      return;
    }
    evt.preventDefault();
    const tempChip = this.moreChips[it];
    const content = tempChip.name;
    if (it == this.moreChips.length - 1) {
      this.moreChips.pop();
      $("#row-two").val(content);
      this.setSelectionRange(document.getElementById("row-two"), 0, 0);
      this.secondaryInputsMore = [];
      this.moreChips.forEach((chip, id) => {
        this.secondaryInputsMore.push({
          input: "secondary-input-more" + id,
          value: "",
        });
      });
    } else {
      this.moreChips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({
            input: "secondary-input-more" + id,
            value: "",
          });
        });
        $("#secondary-input-more" + it)
          .text(content)
          .focus();
      }, 0);
    }
  }

  checkIfChangeMoreChip($event, side, i) {
    let posI = this.getCaretPosition(document.getElementById("secondChip" + i));
    setTimeout(() => {
      let posF = this.getCaretPosition(
        document.getElementById("secondChip" + i)
      );
      if (side == "previous") {
        if (posI == 0 && posF == 0) {
          $("#secondary-input-more" + i).focus();
        }
      } else {
        if (
          posI == $("#secondChip" + i).text().length &&
          posF == $("#secondChip" + i).text().length
        ) {
          let next = i + 1;
          if (document.getElementById("secondary-input-more" + next) != null) {
            $("#secondary-input-more" + next).focus();
          } else {
            $("#row-two").focus();
          }
        }
      }
    }, 10);
  }

  //3a LINHA
  chipsSecondMoreNavitagion(evt, side, from) {
    evt.stopPropagation();
    const value =
      from == "row-three"
        ? $("#row-three").val()
        : $("#secondary-input-second-more" + from).text();
    if (value.trim() != "") {
      return;
    }
    const focused = $(":focus");
    const id = focused.attr("id");
    if (side == "previous") {
      if (id == "row-three") {
        if (this.secondMoreChips.length > 0) {
          $(
            "#secondary-input-second-more" + (this.secondMoreChips.length - 1)
          ).focus();
        }
      } else if (focused.hasClass("secondary-search-input-second-more")) {
        const focusedIt = Number(id.replace("secondary-input-second-more", ""));
        const targetId = focusedIt - 1;
        if (targetId >= 0) {
          $("#secondary-input-second-more" + targetId).focus();
        }
      }
    } else {
      if (focused.hasClass("secondary-search-input-second-more")) {
        const focusedIt = Number(id.replace("secondary-input-second-more", ""));
        const targetId = focusedIt + 1;
        if (targetId < this.secondMoreChips.length) {
          $("#secondary-input-second-more" + targetId).focus();
        } else {
          $("#row-three").focus();
        }
      }
    }
  }

  checkIfDeleteSecondMoreChipInAbsolutePosition(evt, it, id) {
    let val;
    if (id == "row-three") {
      val = $("#" + id).val();
    } else {
      val = $("#" + id).text();
    }
    if (val.trim() != "") {
      return;
    }
    if (it == 0) {
      return;
    }
    evt.preventDefault();
    const tempChip = this.secondMoreChips[it - 1];
    const content = tempChip.name;
    if (it == this.secondMoreChips.length) {
      this.secondMoreChips.pop();
      $("#row-three").val(content);
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({
          input: "secondary-input-second-more" + id,
          value: "",
        });
      });
    } else {
      this.secondMoreChips.splice(it - 1, 1);
      setTimeout(() => {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({
            input: "secondary-input-second-more" + id,
            value: "",
          });
        });
        $("#secondary-input-second-more" + (it - 1)).text(content);
        this.setCursor(
          "secondary-input-second-more" + (it - 1),
          content.length
        );
      }, 0);
    }
  }

  checkIfPreDeleteSecondMoreChipInAbsolutePosition(evt, it) {
    const val = $("#secondary-input-second-more" + it).text();
    if (val.trim() != "") {
      return;
    }
    evt.preventDefault();
    const tempChip = this.secondMoreChips[it];
    const content = tempChip.name;
    if (it == this.secondMoreChips.length - 1) {
      this.secondMoreChips.pop();
      $("#row-three").val(content);
      this.setSelectionRange(document.getElementById("row-three"), 0, 0);
      this.secondaryInputsSecondMore = [];
      this.secondMoreChips.forEach((chip, id) => {
        this.secondaryInputsSecondMore.push({
          input: "secondary-input-second-more" + id,
          value: "",
        });
      });
    } else {
      this.secondMoreChips.splice(it, 1);
      setTimeout(() => {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({
            input: "secondary-input-second-more" + id,
            value: "",
          });
        });
        $("#secondary-input-second-more" + it)
          .text(content)
          .focus();
      }, 0);
    }
  }

  checkIfChangeSecondMoreChip($event, side, i) {
    let posI = this.getCaretPosition(
      document.getElementById("secondMoreChip" + i)
    );
    setTimeout(() => {
      let posF = this.getCaretPosition(
        document.getElementById("secondMoreChip" + i)
      );
      if (side == "previous") {
        if (posI == 0 && posF == 0) {
          $("#secondary-input-second-more" + i).focus();
        }
      } else {
        if (
          posI == $("#secondMoreChip" + i).text().length &&
          posF == $("#secondMoreChip" + i).text().length
        ) {
          let next = i + 1;
          if (
            document.getElementById("secondary-input-second-more" + next) !=
            null
          ) {
            $("#secondary-input-second-more" + next).focus();
          } else {
            $("#row-three").focus();
          }
        }
      }
    }, 10);
  }

  checkHowManyChips(id, evt) {
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#" + id).val();
      let carretPos = this.getCaretPos(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {
        name: chip1Text.replace(new RegExp("_", "g"), " "),
        isChipPlus: true,
      };
      let chip2 = {
        name: chip2Text.replace(new RegExp("_", "g"), " "),
        isChipPlus: true,
      };
      if (chip1Text.trim() != "" && chip2Text.trim() != "") {
        if (id == "row-one") {
          this.chips.push(chip1);
          this.chips.push(chip2);
        } else if (id == "row-two") {
          this.moreChips.push(chip1);
          this.moreChips.push(chip2);
        } else if (id == "row-three") {
          this.secondMoreChips.push(chip1);
          this.secondMoreChips.push(chip2);
        }
      } else {
        if (chip1Text.trim() != "" && chip2Text.trim() == "") {
          if (id == "row-one") {
            this.chips.push(chip1);
          } else if (id == "row-two") {
            this.moreChips.push(chip1);
          } else if (id == "row-three") {
            this.secondMoreChips.push(chip1);
          }
        } else if (chip1Text.trim() == "" && chip2Text.trim() != "") {
          if (id == "row-one") {
            this.chips.push(chip2);
          } else if (id == "row-two") {
            this.moreChips.push(chip2);
          } else if (id == "row-three") {
            this.secondMoreChips.push(chip2);
          }
        }
      }
      $("#" + id).val("");
      if (id == "row-one") {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({
            input: "secondary-input" + id,
            value: "",
          });
        });
      } else if (id == "row-two") {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({
            input: "secondary-input-more" + id,
            value: "",
          });
        });
      } else if (id == "row-three") {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({
            input: "secondary-input-second-more" + id,
            value: "",
          });
        });
      }
      if (this.isLink(chip1Text) || this.isLink(chip2Text)) {
        this.search();
      }
    }, 0);
  }

  checkHowManyChipsAbsPos(id, i, evt, clas) {
    evt.stopPropagation();
    evt.preventDefault();
    setTimeout(() => {
      let text = $("#" + id).text();
      let carretPos = this.getCaretPosition(document.getElementById(id));
      let chip1Text = text.substring(0, carretPos);
      let chip2Text = text.substring(carretPos, text.length);
      let chip1 = {
        name: chip1Text.replace(new RegExp("_", "g"), " "),
        isChipPlus: true,
      };
      let chip2 = {
        name: chip2Text.replace(new RegExp("_", "g"), " "),
        isChipPlus: true,
      };
      if (chip1Text.trim() != "" && chip2Text.trim() != "") {
        if (clas == 0) {
          this.chips.splice(i, 0, chip1);
          this.chips.splice(i + 1, 0, chip2);
        } else if (clas == 1) {
          this.moreChips.splice(i, 0, chip1);
          this.moreChips.splice(i + 1, 0, chip2);
        } else if (clas == 2) {
          this.secondMoreChips.splice(i, 0, chip1);
          this.secondMoreChips.splice(i + 1, 0, chip2);
        }
      } else {
        if (chip1Text.trim() != "" && chip2Text.trim() == "") {
          if (clas == 0) {
            this.chips.splice(i, 0, chip1);
          } else if (clas == 1) {
            this.moreChips.splice(i, 0, chip1);
          } else if (clas == 2) {
            this.secondMoreChips.splice(i, 0, chip1);
          }
        } else if (chip1Text.trim() == "" && chip2Text.trim() != "") {
          if (clas == 0) {
            this.chips.splice(i, 0, chip2);
          } else if (clas == 1) {
            this.moreChips.splice(i, 0, chip2);
          } else if (clas == 2) {
            this.secondMoreChips.splice(i, 0, chip2);
          }
        }
      }
      $("#" + id).text("");
      if (clas == 0) {
        this.secondaryInputs = [];
        this.chips.forEach((chip, id) => {
          this.secondaryInputs.push({
            input: "secondary-input" + id,
            value: "",
          });
        });
      } else if (clas == 1) {
        this.secondaryInputsMore = [];
        this.moreChips.forEach((chip, id) => {
          this.secondaryInputsMore.push({
            input: "secondary-input-more" + id,
            value: "",
          });
        });
      } else if (clas == 2) {
        this.secondaryInputsSecondMore = [];
        this.secondMoreChips.forEach((chip, id) => {
          this.secondaryInputsSecondMore.push({
            input: "secondary-input-second-more" + id,
            value: "",
          });
        });
      }
      if (this.isLink(chip1Text) || this.isLink(chip2Text)) {
        this.search();
      }
    }, 0);
  }

  getCaretPos(oField) {
    if (oField.selectionStart || oField.selectionStart == "0") {
      return oField.selectionStart;
    } else {
      return 0;
    }
  }

  getCaretPosition(element) {
    let caretOffset = 0;
    let range = window.getSelection().getRangeAt(0);
    let preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    caretOffset = preCaretRange.toString().length;
    return caretOffset;
  }

  //AGROUPAMENTO SEARCH
  getCatalogImg(catalog) {
    return (
      environment.baseUrl +
      "image/catalog/?trackCatalog=" +
      catalog.CatalogImageName +
      "&token=" +
      this.authService.user.token +
      "&username=" +
      this.authService.user.username
    );
  }

  getAlbumImg(catalog, album) {
    return (
      environment.baseUrl +
      "image/album/" +
      catalog.CatalogImageName +
      "/" +
      album.AlbumReference +
      "/" +
      "?&token=" +
      this.authService.user.token +
      "&username=" +
      this.authService.user.username
    );
  }

  getAlbumImgSearchByAlbum(album) {
    //FALTA REF DO CATALOGO PARA IR BUSCAR A IMAGEM
    // return "http://staging.vimusica.com/api/image/album/Minim/MNM045/?&token="+ this.authService.user.token + '&username=' + this.authService.user.username;
    return (
      environment.baseUrl +
      "image/album/" +
      album.CatalogImageName +
      "/" +
      album.AlbumReference +
      "/" +
      "?token=" +
      this.authService.user.token +
      "&username=" +
      this.authService.user.username
    );
  }

  showAlbuns = [];
  toggleCatalog(k) {
    let timeout = 0;
    this.showMenuMobile = [];
    if (this.showAlbuns[k] == undefined || this.showAlbuns[k] == false) {
      this.showAlbuns.forEach((element, it) => {
        if (this.showAlbuns[it] && it != k) {
          $("#SEARCH_albuns_catalog" + it).slideToggle();
          setTimeout(() => {
            this.showAlbuns[it] = false;
            this.showTracks = [];
          }, 400);
          timeout = 450;
        }
      });
      this.showAlbuns[k] = true;
      setTimeout(() => {
        $("#SEARCH_albuns_catalog" + k).slideToggle();
        this.updateMusics();
      }, timeout);
    } else {
      $("#SEARCH_albuns_catalog" + k).slideToggle();
      setTimeout(() => {
        this.showAlbuns[k] = false;
        for (var key in this.showTracks) {
          if (key[0] == k) {
            this.showTracks[key] = false;
          }
        }
      }, 400);
    }
  }

  showTracks = [];
  toggleAlbum(k, j) {
    this.showMenuMobile = [];
    var myKey = k + "-" + j;
    let timeout = 0;
    if (
      this.showTracks[k + "-" + j] == undefined ||
      this.showTracks[k + "-" + j] == false
    ) {
      this.showTracks[k + "-" + j] = true;
      for (let key in this.showTracks) {
        if (this.showTracks[key] == true && key != myKey) {
          timeout = 450;
          this.closeAlbum(key);
        }
      }
      setTimeout(() => {
        $("#tracks_catalog" + k + "-" + j).slideToggle();
        this.updateMusics();
      }, timeout);
    } else {
      $("#tracks_catalog" + k + "-" + j).slideToggle();
      setTimeout(() => {
        this.showTracks[k + "-" + j] = false;
      }, 400);
    }
  }

  closeAlbum(key) {
    $("#tracks_catalog" + key).slideToggle();
    setTimeout(() => {
      this.showTracks[key] = false;
    }, 400);
  }

  getRealIndex(track) {
    let id = null;
    for (let it = 0; it < this.tracks.length; it++) {
      const tempTrack = this.tracks[it];
      if (
        "isRelated" in track ||
        "isSimilar" in track ||
        "isFromAlbum" in track
      ) {
        if (
          track.id == tempTrack.id &&
          ("isRelated" in tempTrack ||
            "isSimilar" in tempTrack ||
            "isFromAlbum" in tempTrack)
        ) {
          id = it;
          break;
        }
      } else {
        if (
          track.id == tempTrack.id &&
          !("isRelated" in tempTrack) &&
          !("isSimilar" in tempTrack) &&
          !("isFromAlbum" in tempTrack)
        ) {
          id = it;
          break;
        }
      }
    }
    return id;
  }

  checkClassForCheckAll() {
    if (this.tracks != null && this.tracks != undefined) {
      let anySelected = false;
      let allSelected = true;
      for (let it = 0; it < this.tracks.length; it++) {
        const track = this.tracks[it];
        if (
          !("isRelated" in track) &&
          !("isSimilar" in track) &&
          !("isFromAlbum" in track)
        ) {
          if (track.isTrackSelected) {
            anySelected = true;
          } else {
            allSelected = false;
          }
        }
      }
      if (allSelected && this.tracks.length > 0) {
        return "invertColor";
      } else if (anySelected && this.tracks.length > 0) {
        return "fullOpacity";
      } else {
        return "";
      }
    }
    return "";
  }

  navigateToAndCheckActualRoute(routerLink) {
    this.loadingTracks = false;
    if (this.router.url.includes(routerLink)) {
      this.desligaMusica(routerLink);
    } else {
      this.router.navigate([routerLink]);
    }
  }

  desligaMusica(routerLink, params = null) {
    if (window["globalWavesurfer"] != undefined) {
      if (window["globalWavesurfer"].isPlaying()) {
        window["globalWavesurfer"].destroy();
        this.anyPlaying = false;
        this.selectedTrack = null;
      }
    }
    if (params != null) {
      this.router.navigate([routerLink, params]);
    } else {
      this.router.navigate([routerLink]);
    }
  }

  allowDrop(ev) {
    ev.preventDefault();
    this.isDroppable = true;
  }

  drag(ev, i, track) {
    this.isDragActive = true;
    this.showDragLabel = [];
    this.showDragLabel[i] = true;
  }

  dragging(event) {
    event.preventDefault();
    this.isDroppable = false;
  }

  drop(ev, side) {
    ev.preventDefault();
    this.isDragActive = false;
    this.isDroppable = false;
    this.showDragLabel = [];
    if (side == "in") {
      this.addmultipleTracksToSelectedProject(ev);
    }
  }

  toggleOptions(id, evt) {
    evt.stopPropagation();
    $(".track-projects-dropdown").each(function (index) {
      if ($(this).attr("id") != id) {
        $(this).css("display", "none");
      }
    });
    $(".track-download-dropdown").each(function (index) {
      if ($(this).attr("id") != id) {
        $(this).css("display", "none");
      }
    });
    $("#" + id).toggle();
  }

  hideOptions() {
    $(".track-projects-dropdown").css("display", "none");
    $(".track-download-dropdown").css("display", "none");
  }

  checkIfFreezePlaying() {
    if (
      window["globalWavesurfer"] != undefined &&
      window["globalWavesurfer"].isPlaying()
    ) {
      return true;
    }
    return false;
  }

  checkTracksToSelect(i) {
    this.clearSelection();
    let last_index = this.dataService.getLastTrackSelectedSearch();
    let initial_index = -1;
    let initial_page = 1;
    if (last_index != null) {
      initial_index = last_index["id"];
      initial_page = last_index["page"];
    }
    let actual_index = i;
    let actual_page = this.pager.currentPage;

    if (actual_page == initial_page) {
      if (initial_index > actual_index) {
        for (let it = actual_index; it < initial_index; it++) {
          this.playerHistory.addSongToSelectedSEARCH(this.tracks[it]);
          const id = this.tracks[it].id;
          this.tracks.forEach((element) => {
            if (element.id == id) {
              element.isTrackSelected = true;
              this.dataService.setLastTrackSelectedSearch({
                id: it,
                page: actual_page,
              });
            }
          });
        }
      } else {
        for (let it = initial_index + 1; it <= actual_index; it++) {
          this.playerHistory.addSongToSelectedSEARCH(this.tracks[it]);
          const id = this.tracks[it].id;
          this.tracks.forEach((element) => {
            if (element.id == id) {
              element.isTrackSelected = true;
              this.dataService.setLastTrackSelectedSearch({
                id: it,
                page: actual_page,
              });
            }
          });
        }
      }
      setTimeout(() => {
        this.clearSelection();
      }, 0);
    } else {
      let searchObj = {};
      if (initial_index == -1) {
        initial_index = 0;
      }
      if (actual_page > initial_page) {
        searchObj = {
          IsSFX: false,
          StartIndex: initial_index,
          StartPage: initial_page,
          EndIndex: actual_index,
          EndPage: actual_page,
        };
      } else {
        searchObj = {
          IsSFX: false,
          StartIndex: actual_index,
          StartPage: actual_page,
          EndIndex: initial_index,
          EndPage: initial_page,
        };
      }
      this.searchService
        .getTracksToSelect(searchObj)
        .subscribe((metadataids) => {
          metadataids.forEach((metadataid) => {
            var final_obj = {
              Context1J: metadataid.Context1J,
              Context1K: metadataid.Context1K,
              track: metadataid.Track,
              name: metadataid.TrackTitle,
              duration: metadataid.TrackTime,
              description: metadataid.Description,
              id: metadataid.MetaDataId,
              Track: metadataid.Track,
              isTrackSelected: true,
            };
            this.playerHistory.addSongToSelectedSEARCH(final_obj);
          });
          this.checkSelected();
          setTimeout(() => {
            this.clearSelection();
          }, 0);
        });
    }
  }

  clearSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
  }

  userForbidden() {
    if (
	  this.authService.user.username.startsWith("pprr") ||
      this.authService.user.username == "contactorf" ||
      this.authService.user.username == "rtamaran" ||
      this.authService.user.username == "Radiofaro" ||
      this.authService.user.username == "Radiochiclana" 
    ) {
      return true;
    }
    return false;
  }
  
  fNoVocals_click()
  {
	  var e: HTMLInputElement=document.getElementById("txtnoVocals") as HTMLInputElement;
	  if (e.value=="") {
		  e.value="X"; 
	  }
	  else {
		  e.value="";
	  }
	  // Limpa o ultimo Search para obrigar a fazer nova pesquisa e ir ao servidor:
	  this.dataService.setLastSearchObj(null);
      this.search();
  }

	stoh(s) {
		let text="";
		for (let i = 0; i < s.length; i++) {
		  text += s.substring(i,1);
		}	 
	}
	 
	setCookie(cname, cvalue, exdays) {

	  let url=window.location.host;
	  url=url.replace(":","");
	  url=url.replace("/","");
	  cname=url+"_"+cname;

	  const d = new Date();
	  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	  let expires = "expires="+d.toUTCString();
	  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	 getCookie(cname) {

	  let url=window.location.host;
	  url=url.replace(":","");
	  url=url.replace("/","");
	  cname=url+"_"+cname;

	  let name = cname + "=";
	  let ca = document.cookie.split(';');
	  for(let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
		  c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		  return c.substring(name.length, c.length);
		}
	  }
	  return "";
	 }

}
